import { Box, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import logo from "src/assets/svg-icons/logo.svg";
import chickenAr from "src/assets/img/home/chicken-price-ar.png";
import chickenEN from "src/assets/img/home/chicken-price.png";

const WhatJazrSystem = () => {
  const { t, i18n } = useTranslation("home");

  return (
    <Box
      marginBottom={{ xs: "50px", lg: "280px" }}
      p={{ xs: "16px 16px 60px 16px", lg: "24px 24px 24px 24px" }}
    >
      <Grid
        container
        // bgcolor={"#F5F5F5"}
        marginBottom={{ xs: "50px", lg: "150px" }}
        marginTop={{ xs: "24px", lg: "300px" }}
        spacing={{ xs: 8, lg: 2 }}
      >
        <Grid
          item
          xs={12}
          md={6}
          order={{ xs: 2, lg: 1 }}
          display={"flex"}
          flexDirection={"column"}
        >
          <Typography
            data-aos="fade-up"
            color={"#000"}
            fontWeight={700}
            lineHeight={{ xs: "24px", md: "36px" }}
            mb={"15px"}
            fontSize={{ xs: "24px", md: "32px" }}
            sx={{ textAlign: { xs: "start", md: "start" } }}
          >
            {t("whatJazrSystem")}
          </Typography>
          <Typography
            data-aos="fade-up"
            color={"#6A6565"}
            fontSize={{ xs: "16px", md: "20px" }}
            fontWeight={500}
            lineHeight={{ xs: "24px", lg: "24px" }}
            maxWidth={{ xs: "100%", md: "80%", lg: "580px" }}
            sx={{ textAlign: { xs: "start", md: "start" } }}
          >
            {t("JazrSystemInnovativeWay")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          order={{ xs: 1, lg: 2 }}
        >
          <Box
            data-aos="fade-up"
            width={{ xs: "70%", md: "60%", lg: "361px" }}
            height={{ xs: "100%", lg: "163px" }}
          >
            <img src={logo} alt="" width={"100%"} height={"100%"} />
          </Box>
        </Grid>
      </Grid>
      <Stack textAlign={"center"} alignItems={"center"}>
        <Typography
          data-aos="fade-up"
          lineHeight={{ xs: "24px", md: "36px" }}
          mb={"15px"}
          fontSize={{ xs: "24px", md: "32px" }}
          fontWeight={600}
          maxWidth={700}
        >
          {t("doYouKnowPriceOfChickenLastTwoYears")}
        </Typography>
        <Typography data-aos="fade-up" maxWidth={"900px"} mb={5}>
          {t("discoverRiseInValueOfPurchasesEffortlessly")}
        </Typography>
        <Box data-aos="fade-up">
          <img width={"100%"} src={i18n.language === "en" ? chickenEN : chickenAr} alt="" />
        </Box>
      </Stack>
    </Box>
  );
};

export default WhatJazrSystem;
