import { Button, Stack, TablePagination, Typography } from "@mui/material";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import FilterIcon from "src/assets/svg-icons/aggregators/filter.svg";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import AddEditEventPopup from "./add-edit-event";
import EventTable from "./table";
import { useGetPurchasingEventsList } from "src/api/purchasing/events";

const Event = () => {
  const [open, setOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const { t } = useTranslation("purchasing");

  const { data, refetch, isFetching } = useGetPurchasingEventsList({
    page: pageNumber + 1,
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta.total);
    }
  }, [data]);

  return (
    <Stack spacing={3} maxWidth={{ xs: "100%", md: `calc(100vw - 316px)` }}>
      <Stack
        sx={{ flexDirection: { xs: "column", md: "row" } }}
        justifyContent={"space-between"}
        gap={2}
        alignItems={"center"}
      >
        <Typography
          fontWeight={600}
          lineHeight={"19.2px"}
          color={"#475467"}
          textTransform={"uppercase"}
        >
          {t("event")}
        </Typography>
        <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
          <Button
            color="tertiary"
            variant="outlined"
            startIcon={<img src={FilterIcon} alt="" />}
            // onClick={handleOpen}
          >
            {t("filter")}
          </Button>
          <Button
            sx={{
              border: "1px solid #98A2B3",
              height: "40px",
              fontWeight: 500,
              borderRadius: "4px",
              padding: "7px 10px",
              background: "#FCFCFD",
              fontSize: "16px",
            }}
            color="tertiary"
            variant="outlined"
            endIcon={<img src={DownloadIcon} alt="" />}
          >
            {t("download")}
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleOpen}
          >
            {t("addEvent")}
          </Button>
        </Stack>
      </Stack>
      <EventTable data={data?.data} refetch={refetch} isFetching={isFetching} />
      <TablePagination
        rowsPerPageOptions={[15]}
        component="div"
        count={total}
        rowsPerPage={15}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
      {open && (
        <AddEditEventPopup
          open={open}
          handleClose={handleClose}
          refetch={refetch}
        />
      )}
    </Stack>
  );
};

export default Event;
