import React, { useEffect, useState } from "react";
import { Button, Stack, TablePagination, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FilterIcon from "src/assets/svg-icons/aggregators/filter.svg";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import SalesTable from "./table";
import { useGetSalesList } from "src/api/settings/sales";
import FilterDrawer from "./filters";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Sales = () => {
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [branches, setBranches] = useState<number[]>([]);
  const [refNum, setRefNum] = useState("");
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("settings");
  const { t: generalT } = useTranslation("general");
  
  const { data, isFetching, refetch } = useGetSalesList({
    page: pageNumber + 1,
    ...(!!refNum && { reference_number: refNum }),
    ...(branches.length > 0 && { branches: branches }),
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (!open) {
      refetch();
    }
  }, [open, pageNumber]);

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta.total);
    }
  }, [data]);

  return (
    <Stack spacing={1.5}>
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography fontWeight={600} lineHeight={"19.2px"} color={"#475467"}>
          {t("salesList")}
        </Typography>

        <Stack direction={"row"} gap={2}>
          <Button
            color="tertiary"
            variant="outlined"
            size="small"
            endIcon={<img src={FilterIcon} height={"16px"} alt="" />}
            sx={{ height: "40px" }}
            onClick={handleOpen}
          >
            {generalT("filter")}
          </Button>
          <Button
            color="tertiary"
            variant="outlined"
            endIcon={<img src={DownloadIcon} alt="" />}
          >
            {generalT("download")}
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate("/add-sales")}
          >
            {t("addNewSalesJourney")}
          </Button>
        </Stack>
      </Stack>
      <SalesTable rows={data?.data} isLoading={isFetching} refetch={refetch} />
      <TablePagination
        sx={{ mt: 1 }}
        rowsPerPageOptions={[15]}
        component="div"
        count={total}
        rowsPerPage={15}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
      {/* Filter Drawer */}
      <FilterDrawer
        open={open}
        setBranches={setBranches}
        branches={branches}
        refNum={refNum}
        setRefNum={setRefNum}
        handleClose={handleClose}
      />
    </Stack>
  );
};

export default Sales;
