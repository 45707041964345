import React, { useEffect } from "react";
import Routes from "./routes";
import { useRecoilState } from "recoil";
import { notificationsState } from "./store/notifications";
import { Snackbar, Alert, Fab } from "@mui/material";
import { Notification } from "./store/notifications";
import { useTranslation } from "react-i18next";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import 'aos/dist/aos.css';
function App() {
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { i18n } = useTranslation();

  const handleClose = (index: number, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    let copyOfNotifications = [...notifications];
    copyOfNotifications.splice(index, 1);
    setNotifications(copyOfNotifications);
  };

  const openWhatsAppChat = () => {
    const url = `https://wa.me/966550515038`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    const metaDescription = document.querySelector('meta[name="description"]');
    if (i18n.language === "en") {
      metaDescription?.setAttribute(
        "content",
        "Jazr platform, Ai accountant for restaurant and Cafe"
      );
      document.title = "Jazr platform, Ai accountant for restaurant and Cafe";
    } else if (i18n.language === "ar") {
      metaDescription?.setAttribute(
        "content",
        "منصة جزر  محاسب Ai خاص للمطاعم والمقاهي"
      );
      document.title = "منصة جزر  محاسب Ai خاص للمطاعم والمقاهي";
    }
  }, [i18n.language]);

  return (
    <>
      <Routes />
      {notifications &&
        notifications.length > 0 &&
        notifications.map((notification: Notification, index: number) => (
          <Snackbar
            key={index}
            open={true}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={(e, reason) => handleClose(index, reason)}
          >
            <Alert
              onClose={() => handleClose(index)}
              severity={notification.type}
              sx={{ width: "100%" }}
            >
              {notification.message}
            </Alert>
          </Snackbar>
        ))}
      <Fab
        color="secondary"
        aria-label="add"
        sx={{
          backgroundColor: "#25D366",
          position: "fixed",
          bottom: { xs: "16px", md: "40px" },
          left: { xs: "16px", md: "40px" },
          ":hover": {
            backgroundColor: "#20B954",
          },
        }}
        onClick={openWhatsAppChat}
      >
        <WhatsAppIcon />
      </Fab>
    </>
  );
}

export default App;
