import { Box, Container, Fab } from "@mui/material";

import PublicMobilAppBar from "src/shared/components/public-mobile-appbar";
import Footer from "src/shared/components/footer";
import PublicAppBar from "src/shared/components/public-appbar";
import greenCercle from "src/assets/img/home/green-cercle.webp";
import orangeCercle from "src/assets/img/home/orange-cercle.webp";
import QuestionsWorks from "./questions";
import WorksJoinUs from "./works-join-us";
import TermsJoin from "./terms-join";
import Header from "./header";
import { useEffect } from "react";
import AOS from "aos";

const WorkWithUs = () => {
  useEffect(() => {
    AOS.init({
      duration: 500,
      offset: 100,
    });
  }, []);
  return (
    <div style={{ position: "relative", width: "100%", overflowX: "hidden" }}>
      <div style={{ position: "relative", width: "100%", overflowX: "hidden" }}>
        <Box
          position={"relative"}
          zIndex={2}
          display={{ xs: "none", md: "block" }}
        >
          <PublicAppBar />
        </Box>
        <Box display={{ xs: "block", md: "none" }}>
          <PublicMobilAppBar />
        </Box>
        <img
          src={orangeCercle}
          className="responsive-circle dd"
          style={{
            opacity: "0.5",
            position: "absolute",
            top: "120px",
            insetInlineStart: "-650px",
            width: "1400px",
            height: "1400px",
            objectFit: "contain",
          }}
        />
        <img
          src={greenCercle}
          className="responsive-circle"
          style={{
            opacity: "0.5",
            position: "absolute",
            top: "-620px",
            insetInlineEnd: "-650px",
            width: "1400px",
            height: "1400px",
            objectFit: "contain",
          }}
        />

        <Container
          sx={{
            maxWidth: "100%",
            minWidth: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "1320px",
            },
            position: "relative",
            zIndex: "20",
            marginX: "auto",
            padding: "0px",
          }}
        >
          <Header />
          <TermsJoin />
        </Container>
        <div style={{ position: "relative", width: "100%" }}>
          <img
            src={orangeCercle}
            className="responsive-circle dd"
            style={{
              opacity: "0.5",
              position: "absolute",
              top: "120px",
              insetInlineStart: "-650px",
              width: "1400px",
              height: "1400px",
              objectFit: "contain",
            }}
          />
          <img
            src={greenCercle}
            className="responsive-circle"
            style={{
              opacity: "0.34",
              position: "absolute",
              top: "-620px",
              insetInlineEnd: "-650px",
              width: "1400px",
              height: "1400px",
              objectFit: "contain",
            }}
          />
          <Container
            sx={{
              maxWidth: "100%",
              minWidth: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "1320px",
              },
              position: "relative",
              zIndex: "20",
              marginX: "auto",
              padding: "0px",
            }}
          >
            <WorksJoinUs />
          </Container>
        </div>
        <Container
          sx={{
            maxWidth: "100%",
            minWidth: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "1320px",
            },
            position: "relative",
            zIndex: "20",
            marginX: "auto",
            padding: "0px",
          }}
        >
          <QuestionsWorks />
        </Container>
        <Footer />
      </div>
    </div>
  );
};

export default WorkWithUs;
