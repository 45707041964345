import { Box, Stack, Typography } from "@mui/material";
import SettingsTabs from "./tabs";
import settingIcon from "src/assets/svg-icons/side-bar/settings.svg";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const { t  } = useTranslation("settings");

  return (
    <Stack gap={3} p={{ xs: 2, sm: "8px 32px 32px 32px" }}>
      <Stack direction={"row"} spacing={"12px"} alignItems={"center"} color={"#101828"}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"60px"}
          height={"60px"}
          bgcolor={"#D0D5DD"}
          borderRadius={"16px"}
        >
          <img src={settingIcon} width={"32px"} alt="" />
        </Box>
        <Typography fontSize={"32px"} lineHeight={"38.4px"}>
        {t("settings")}
        </Typography>
      </Stack>
      {/* divider */}
      <Box bgcolor={"#F2F4F7"} width={"100%"} height={"1px"} />
      <SettingsTabs />
    </Stack>
  );
};

export default Settings;
