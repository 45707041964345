import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Months from "./monthly";

interface TabProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PricesTabs = () => {
  const [value, setValue] = useState(0);
  const isSmallScreen = useMediaQuery("(max-width:700px)");
  const { t } = useTranslation("prices");
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
      <Box
        data-aos="fade-up"
        bgcolor={"#F9FAFB"}
        p={0.5}
        border={"1px solid  #EAECF0"}
        borderRadius={"10px"}
        width={"fit-content"}
        display={"flex"}
        justifyContent={"center"}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ style: { display: "none" } }}
          sx={{
            minHeight: { xs: "100%", md: "35px" },
            height: { xs: "100%", md: "35px" },
          }}
        >
          <Tab
            disableRipple
            sx={{
              color: "#667085",
              height: "33px",
              minHeight: "33px",
              "&.Mui-selected": {
                bgcolor: "#FF7F29",
                color: "#FFFFFF",
                m: "1px",
                borderRadius: "8px",
              },
            }}
            label={t("monthly")}
            {...a11yProps(0)}
          />
          <Tab
            disableRipple
            sx={{
              color: "#667085",
              height: "33px",
              minHeight: "33px",
              "&.Mui-selected": {
                bgcolor: "#FF7F29",
                color: "#FFFFFF",
                m: "1px",
                borderRadius: "8px",
              },
            }}
            label={t("yearlyPercentage")}
            {...a11yProps(1)}
            disabled
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Months />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Months />
      </CustomTabPanel>
    </Box>
  );
};

export default PricesTabs;
