import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrors,
  UseFormGetValues,
  UseFormWatch,
} from "react-hook-form";
import { FormData } from "../../constants";
import InfoBox from "src/shared/components/info-box";
import { memo, useEffect } from "react";
import YoutubeViewerBox from "../../youtube-viewer-box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/utils";

const MakeBalance = ({
  balanceFields,
  control,
  errors,
  getValues,
  journeyData,
  watch,
  balanceAmountMatch,
  setBalanceAmountMatch,
  balanceQntyMatch,
  setBalanceQntyMatch,
}: {
  balanceFields: FieldArrayWithId<FormData, "balance", "id">[];
  control: Control<FormData, any>;
  errors: FieldErrors<FormData>;
  getValues: UseFormGetValues<FormData>;
  journeyData: any;
  watch: UseFormWatch<FormData>;
  balanceAmountMatch: boolean;
  setBalanceAmountMatch: any;
  balanceQntyMatch: boolean;
  setBalanceQntyMatch: Function;
}) => {
  const theme = useTheme();
  const matchesSMUp = useMediaQuery(theme.breakpoints.up("sm"));
  const { t, i18n } = useTranslation("addSales");
  const { t: generalT } = useTranslation("general");

  const accumulatedBalance = watch("balance")?.reduce(
    (acc: { amount: number; quantity: number }, payment: any) => {
      acc.amount += +payment.amount;
      acc.quantity += +payment.quantity;
      return acc;
    },
    { amount: 0, quantity: 0 }
  );

  const netSalesWithTax =
    watch("journey_type") === "foodics"
      ? journeyData?.sale?.gross_sales
      : +watch("net_sales_with_tax");

  const totalQuantity =
    watch("journey_type") === "foodics"
      ? journeyData?.payments.reduce(
          (sum: number, payment: any) => sum + payment.quantity,
          0
        )
      : +watch("total_order_quantity");

  const handleKeyDown = (event:React.KeyboardEvent<HTMLInputElement>) => {
    // Prevents entering negative sign or other invalid characters
    if (event.key === "-" || event.key === "e") {
      event.preventDefault(); // Disables negative sign and scientific notation
    }
  };

  useEffect(() => {
    if (accumulatedBalance?.amount.toFixed(2) === netSalesWithTax.toFixed(2)) {
      setBalanceAmountMatch(true);
    }
  }, [accumulatedBalance]);

  useEffect(() => {
    if (accumulatedBalance?.quantity === totalQuantity) {
      setBalanceQntyMatch(true);
    }
  }, [accumulatedBalance]);

  const isOneDayFoodicsJourney =
    journeyData && journeyData.start_date === journeyData.end_date;

  return (
    <Box>
      <Typography fontSize={"26px"} fontWeight={600}>
        {t("nowYouCanMakeBalance")}
      </Typography>
      <Typography color={"textSecondary"} mb={4}>{t("checkFollowing")}</Typography>

      {/* <YoutubeViewerBox videoId="MP2TMZN9okI" /> */}

      {!isOneDayFoodicsJourney && watch("journey_type") === "foodics" && (
        <Box mb={2}>
          <InfoBox text={t("EditBalanceOnly")} />
        </Box>
      )}

      <Box borderRadius={"8px"} overflow={"auto"} maxWidth={"100%"}>
        <Grid container spacing={2} bgcolor={"#F9FAFB"} p={1.5}>
          <Grid item xs={4}>
            <Typography flexBasis={"50%"}>
              {generalT("paymentMethod")}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography flexBasis={"50%"}>{generalT("quantity")}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography flexBasis={"50%"}>
              {t("paymentAmount")} ({generalT("sar")})
            </Typography>
          </Grid>
        </Grid>
        <Box
          borderRadius={"0 0 0 8px"}
          borderRight={"1px solid #EAECF0"}
          borderLeft={"1px solid #EAECF0"}
        >
          {balanceFields.map((field: any, index: number) => (
            <Grid
              key={field.id}
              container
              spacing={0}
              borderBottom={"1px solid #EAECF0"}
              p={1.5}
            >
              <Grid item xs={4}>
                <Typography flexBasis={"50%"}>{field.name}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name={`balance.${index}.quantity`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      disabled={!isOneDayFoodicsJourney}
                      autoFocus={index === 0}
                      sx={{
                        "& fieldset": {
                          border: "none",
                        },
                        "& .Mui-focused": {
                          border: "1px solid #EAECF0",
                          borderRadius: "7px",
                        },
                      }}
                      variant="outlined"
                      {...field}
                      error={
                        errors.balance && !!errors.balance[index]?.quantity
                      }
                      helperText={
                        errors.balance &&
                        errors.balance[index]?.quantity?.message
                      }
                      fullWidth
                      size="small"
                      InputProps={{
                        inputProps: { min: 0 },
                        readOnly:
                          !isOneDayFoodicsJourney &&
                          watch("journey_type") === "foodics",
                      }}
                      onKeyDown={handleKeyDown}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name={`balance.${index}.amount`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      variant="outlined"
                      sx={{
                        "& fieldset": {
                          border: "none",
                        },
                        "& .Mui-focused": {
                          border: "1px solid #EAECF0",
                          borderRadius: "7px",
                        },
                      }}
                      {...field}
                      error={errors.balance && !!errors.balance[index]?.amount}
                      helperText={
                        errors.balance && errors.balance[index]?.amount?.message
                      }
                      fullWidth
                      size="small"
                      InputProps={{
                        inputProps: { min: 0 },
                        startAdornment: (
                          <InputAdornment position="start">
                            {matchesSMUp ? generalT("sar") : ""}
                          </InputAdornment>
                        ),
                        readOnly:
                          !isOneDayFoodicsJourney &&
                          watch("journey_type") === "foodics",
                      }}
                      onKeyDown={handleKeyDown}
                    />
                  )}
                />
              </Grid>
            </Grid>
          ))}
          <Grid
            container
            spacing={3}
            borderBottom={"1px solid #EAECF0"}
            p={1.5}
          >
            <Grid item xs={4}>
              <Typography fontWeight={700}>{t("totalPayments")}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography color={balanceQntyMatch ? "black" : "error"}>
                {formatNumber(accumulatedBalance?.quantity)}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography color={balanceAmountMatch ? "black" : "error"}>
                {matchesSMUp ? generalT("sar") : ""}{" "}
                {formatNumber(+accumulatedBalance?.amount.toFixed(2))}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            borderBottom={"1px solid #EAECF0"}
            p={1.5}
          >
            <Grid item xs={4}>
              <Typography fontWeight={700}>
                {generalT("netSalesWithTax")}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>{formatNumber(totalQuantity)}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {matchesSMUp ? generalT("sar") : ""}{" "}
                {formatNumber(+netSalesWithTax?.toFixed(2))}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {!balanceAmountMatch && (
        <Box mt={3}>
          <InfoBox variant="error" text={t("totalPaymentsMethodsMust")} />
        </Box>
      )}
      {!balanceQntyMatch && (
        <Box mt={3}>
          <InfoBox variant="error" text={t("totalPaymentsQntyMust")} />
        </Box>
      )}
    </Box>
  );
};

export default MakeBalance;
