import { Box, Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Aggregators from "src/assets/img/works/man.webp";
import followerImg from "src/assets/svg-icons/home/follower.svg";

const WorksJoinUs = () => {
  const { t, i18n } = useTranslation("workWithUs");

  const handleClick = () => {
    const url =
      "https://docs.google.com/forms/d/1xm2E0f6VDjNhpZnHesW1zh0INej1p6yXSkCMg5mzHMk/viewform?edit_requested=true";

    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div
      data-aos="fade-up"
      data-aos-delay="50"
      style={{ position: "relative", marginTop: "250px", marginBottom: "60px" }}
    >
      <div className="background-jorks-join-us"></div>

      <Grid
        data-aos="fade-up"
        data-aos-delay="50"
        container
        p={{ xs: "20px 16px 20px 16px", lg: "20px 24px 20px 24px" }}
        position={"relative"}
        spacing={{ xs: 3, lg: 2 }}
      >
        <Grid
          data-aos="fade-up"
          data-aos-delay="250"
          item
          xs={12}
          lg={6}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          order={{ xs: 2, lg: 1 }}
          position={"relative"}
          zIndex={1}
          p={{
            xs: "20px 16px 20px 16px",
            lg: "20px 32px 20px 32px !important",
          }}
        >
          <Typography
            color={"#383838"}
            fontSize={{ xs: "25px", md: "32px" }}
            fontWeight={{ xs: 800, md: 800 }}
            lineHeight={{ xs: "150%", md: "150%" }}
            mb={"15px"}
            mt={{ xs: "15px", lg: "0px" }}
            sx={{ textAlign: { xs: "center", lg: "start" } }}
          >
            {t("registerNowAndBecomeOneOfUs")}
          </Typography>

          <div style={{ width: "100%", marginTop: "20px", display: "flex" }}>
            <Button
              variant="contained"
              onClick={handleClick}
              sx={{
                marginLeft: { xs: "auto", lg: "0" },
                marginRight: { xs: "auto", lg: "0" },
                minWidth: "170px",
              }}
            >
              {t("registerNow")}
            </Button>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          order={{ xs: 1, lg: 2 }}
          position={"relative"}
          zIndex={1}
        >
          <Box position={"relative"} width={{ xs: "100%", lg: "fit-content" }}>
            <Box
              width={{ xs: "75%", lg: "100%" }}
              height={{ xs: "auto", lg: "auto" }}
              marginTop={{ xs: "-200px", lg: "-250px" }}
              marginBottom={{ xs: "-24px", lg: "-24px" }}
              position={"relative"}
              marginX={"auto"}
              zIndex={2}
            >
              <img
                src={Aggregators}
                alt=""
                style={{ objectFit: "contain" }}
                width={"100%"}
                height={"100%"}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        position={"absolute"}
        bottom={-60}
        left={-52}
        display={{ xs: "none", md: "block" }}
        zIndex={-1}
      >
        <img
          src={followerImg}
          alt=""
          style={{
            width: "120px",
            height: "120px",
            transform: i18n.language === "en" ? "scaleX(-1)" : "none",
          }}
        />
      </Box>
    </div>
  );
};

export default WorksJoinUs;
