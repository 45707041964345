import { useQuery } from "@tanstack/react-query";
import apiClient from "./apiClient";
import {
  IBranchesResponse,
  ICitiesResponse,
  IPaymentsResponse,
} from "src/types/generic";

export const useGetCities = () =>
  useQuery<any, any, ICitiesResponse>({
    queryKey: ["get-cities"],
    queryFn: async () => {
      return apiClient.get(`/api/cities`);
    },
    gcTime: 0,
  });

export const useGetBranches = () =>
  useQuery<any, any, IBranchesResponse>({
    queryKey: ["get-branches"],
    queryFn: async () => {
      return apiClient.get(`/api/branches`);
    },
    gcTime: 0,
  });

export const useGetPaymentMethods = (params?: { branches?: number[]; page?: number }) =>
  useQuery<any, any, IPaymentsResponse>({
    queryKey: ["get-payments-methods", params?.page, params?.branches],
    queryFn: async () => {
      return apiClient.get(`/api/paymentMethods`, { params: params || {} }); // If no params, it returns all payments
    },
    gcTime: 0,
  });

export const useGetJourneys = (params: {
  branch_id: number;
  enabled: boolean;
}) =>
  useQuery<any, any, any>({
    queryKey: ["get-journeys", params.branch_id],
    queryFn: async () => {
      return apiClient.get(`/api/journeys`, {
        params: params?.branch_id ? { branch_id: params?.branch_id } : {},
      }); // If no params, it returns all journeys
    },
    enabled: params?.enabled,
    gcTime: 0,
  });
