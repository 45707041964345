import { Box, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import followerImg from "src/assets/svg-icons/home/follower.svg";

const JazarSystemDifference = () => {
  const { t, i18n } = useTranslation("home");

  return (
    <Stack
     // bgcolor={"#F5F5F5"}
      spacing={{ xs: 3, lg: "60px" }}
      paddingBottom={{xs:"60px",lg: "200px"}}
      paddingX={{ xs: "20px" }}

      bgcolor={{xs:"#FF7F290D",md:"transparent"}}
      paddingTop={{xs:"60px",lg: "20px"}}

    >
      <Box display={"flex"} marginTop={5} justifyContent={"center"}>
        <Typography
         data-aos="fade-up"
         data-aos-offest="500"
        
          fontSize={{ xs: "20px", lg: "32px" }}
          fontWeight={{ xs: 800, lg: 800 }}
          lineHeight={{ xs: "24px", lg: "48px" }}
          color={"#000"}
          align="center"
        >
          {t("differenceTraditionalWayAndJazar")}
        </Typography>
      </Box>
      <Box
        position={"relative"}
        sx={{ mb: { xs: "", lg: "-80px !important" } }}
        display={"flex"}
        width="100%" // Ensure full width of the parent container
        paddingX={{lg:"40px"}}
        data-aos="fade-up"
        data-aos-offest="200"
        data-aos-duration="1000"
      >
        <Box
          width="100%" 
          position="relative"
          paddingTop="56.25%" 
          overflow="hidden"
          borderRadius="16px" 
          zIndex={2}
         
        >
          <iframe
            src="https://www.youtube.com/embed/jeUIaCKYcSY?si=hhis3YC-Apo6hlm8"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              position: "absolute", // Position absolutely to fill the Box
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              borderRadius: '16px', // Optional: add border radius
            }}
          ></iframe>
        </Box>

        <Box
          position={"absolute"}
          bottom={-20}
          left={-12}
          display={{ xs: "none", md: "block" }}
          zIndex={1}
        >
          <img src={followerImg} alt="" />
        </Box>
      </Box>
    </Stack>
  );
};

export default JazarSystemDifference;
