import { ReactComponent as DashboardIcon } from "src/assets/svg-icons/side-bar/dashboard.svg";
import { ReactComponent as AggregatorsIcon } from "src/assets/svg-icons/side-bar/aggregators.svg";
import { ReactComponent as ReportsIcon } from "src/assets/svg-icons/side-bar/reports.svg";
import { ReactComponent as OperationIcon } from "src/assets/svg-icons/side-bar/operation.svg";
import { ReactComponent as SettingsIcon } from "src/assets/svg-icons/side-bar/settings.svg";

export const sideBarItems = [
  {
    label: "dashboard",
    icon: <DashboardIcon />,
    path: "/dashboard",
  },
  // {
  //   label: "aggregators",
  //   icon: <AggregatorsIcon />,
  //   path: "/aggregators",
  // },
  {
    label: "purchasing",
    icon: <OperationIcon />,
    path: "/purchasing",
  },
  {
    label: "operationCost",
    icon: <OperationIcon />,
    path: "/operation",
  },
  {
    label: "3rdEyeReport",
    icon: <ReportsIcon />,
    path: "/reports",
  },
  {
    label: "settings",
    icon: <SettingsIcon />,
    path: "/settings",
  },
];
