import { Box, Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import WorkWithUsImg from "src/assets/img/works/workwithus.jpg";
import followerImg from "src/assets/svg-icons/home/follower.svg";

const Header = () => {
  const { t, i18n } = useTranslation("workWithUs");

  const handleClick = () => {
    const url =
      "https://docs.google.com/forms/d/1xm2E0f6VDjNhpZnHesW1zh0INej1p6yXSkCMg5mzHMk/viewform?edit_requested=true";

    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Grid
      container
      // bgcolor={"#FFF7F7"}
      p={{ xs: "80px 16px 80px 16px", lg: "140px 16px 80px 16px" }}
      spacing={{ xs: 3, lg: 5 }}
    >
      <Grid
        data-aos="fade-left"
        data-aos-delay="300"
        data-aos-offset="0"
        item
        xs={12}
        lg={6}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        order={{ xs: 2, lg: 1 }}
      >
        <Typography
          color={"#383838"}
          fontSize={{ xs: "20px", md: "28px" }}
          fontWeight={{ xs: 800, md: 800 }}
          lineHeight={{ xs: "150%", md: "150%" }}
          mb={"15px"}
          sx={{ textAlign: { xs: "center", lg: "start" } }}
        >
          {t("workWithUsAsDataEntryAccountant")}
        </Typography>
        <Typography
          color={"#656565"}
          fontSize={{ xs: "16px", md: "20px" }}
          fontWeight={500}
          lineHeight={{ xs: "150%", md: "150%" }}
          sx={{ textAlign: { xs: "start", lg: "start" } }}
        >
          <Typography fontSize={"20px"} fontWeight={700}>
            {t("tasksInExchange")}
          </Typography>
          <ul style={{ paddingInlineStart: "24px" }}>
            <li>
              <Typography fontSize={"20px"} fontWeight={500}>
                {t("enteringInvoicesDaily")}
              </Typography>
            </li>
            <li>
              <Typography fontSize={"20px"} fontWeight={500}>
                {t("enteringClientReportedEvents")}
              </Typography>
            </li>
            <li>
              <Typography fontSize={"20px"} fontWeight={500}>
                {t("processingInvoicesPerMonth")}
              </Typography>
            </li>
            <li>
              <Typography fontSize={"20px"} fontWeight={500}>
                {t("performingReconciliations")}
              </Typography>
            </li>
            <li>
              <Typography fontSize={"20px"} fontWeight={500}>
                {t("workWithUsAsServiceCompany")}
              </Typography>
            </li>
            <li>
              <Typography fontSize={"20px"} fontWeight={500}>
                {t("priorityForEmployment")}
              </Typography>
            </li>
            <li>
              <Typography fontSize={"20px"} fontWeight={500}>
                {t("weekendsOff")}
              </Typography>
            </li>
          </ul>
        </Typography>
        <div style={{ width: "100%", display: "flex", paddingTop: "24px" }}>
          <Button
            variant="contained"
            onClick={handleClick}
            size="large"
            sx={{
              marginLeft: { xs: "auto", lg: "0" },
              marginRight: { xs: "auto", lg: "0" },
              minWidth: "230px",
            }}
          >
            {t("registerNow")}
          </Button>
        </div>
      </Grid>
      <Grid item xs={12} lg={6} order={{ xs: 1, lg: 2 }}>
        <Box
          data-aos="fade-right"
          data-aos-delay="300"
          position={"relative"}
          width={{ xs: "100%", lg: "fit-content" }}
        >
          <Box
            // width={{ xs: "100%", lg: "715px" }}
            height={{ xs: "100%", lg: "550px" }}
            position={"relative"}
            zIndex={2}
            borderRadius={"39px"}
            overflow={"hidden"}
          >
            <img
              src={WorkWithUsImg}
              alt=""
              style={{ objectFit: "contain" }}
              width={"100%"}
              height={"100%"}
            />
          </Box>
          <Box
            position={"absolute"}
            bottom={-15}
            right={-52}
            display={{ xs: "none", md: "block" }}
            zIndex={1}
          >
            <img
              src={followerImg}
              alt=""
              style={{
                transform: i18n.language === "en" ? "scaleX(-1)" : "none",
              }}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Header;
