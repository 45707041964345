import { Box, Button, Grid, Link, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import phoneIcon from "src/assets/svg-icons/home/phoneOrange.svg";
import EmailIcon from "src/assets/svg-icons/home/emailOrange.svg";
import whatsappIcon from "src/assets/svg-icons/home/whatsapp.svg";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
const Header = () => {
  const { t } = useTranslation("home");
  const navigate = useNavigate();

  return (
    <Stack
      p={{ xs: "16px 16px 60px 16px", lg: "80px 0 250px 0" }}
      spacing={{ xs: 3, md: "32px" }}
      alignItems={"center"}
    >
      <Typography
        maxWidth={{ xs: "100%", md: "80%", lg: "981px" }}
        fontSize={{ xs: "20px", md: "32px" }}
        fontWeight={{ xs: 700, md: 800 }}
        lineHeight={{ xs: "150%", md: "150%" }}
        color={"#1E1E1E"}
        align="center"
        paddingBottom={"48px"}
      >
        {t("contactUs")}
      </Typography>
      <Grid container spacing={{ xs: 5, lg: 0 }}>
        <Grid
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-offset="0"
          item
          xs={12}
          lg={6}
          alignItems={"center"}
          borderRight={{ lg: "2px solid #00000020" }}
        >
          <Typography
            maxWidth={{}}
            fontSize={{ xs: "18px", md: "18px" }}
            fontWeight={{ xs: 700, md: 800 }}
            lineHeight={{ xs: "100%", md: "100%" }}
            color={"#1E1E1E"}
            paddingBottom={"20px"}
          >
            {t("directContactUs")}
          </Typography>
          <Stack spacing={3}>
            <Stack direction={"row"} spacing={"12px"}>
              <img src={phoneIcon} alt="" width={"30px"} />
              <Box>
                <Typography fontSize={"14px"} color={"#292D32"}>
                  {t("haveAQuestion")}
                </Typography>
                <Typography
                  fontSize={"16px"}
                  fontWeight={700}
                  color={"#292D32"}
                >
                  00966550515038
                </Typography>
              </Box>
            </Stack>

            <Stack direction={"row"} spacing={"12px"}>
              <img src={EmailIcon} alt="" width={"30px"} />
              <Box>
                <Typography fontSize={"14px"} color={"#292D32"}>
                  {t("contactUsWith")}
                </Typography>
                <Typography
                  fontSize={"16px"}
                  fontWeight={700}
                  color={"#292D32"}
                >
                  Jazr@jazr.io
                </Typography>
              </Box>
            </Stack>
            <Stack direction={"row"} spacing={"12px"}>
              <img src={whatsappIcon} alt="" width={"30px"} />
              <Box>
                <Typography fontSize={"14px"} color={"#292D32"}>
                  {t("")}
                </Typography>
                <Typography
                  fontSize={"16px"}
                  fontWeight={700}
                  color={"#292D32"}
                >
                  00966550515038
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Grid>

        <Grid
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-offset="0"
          item
          xs={12}
          lg={6}
          alignItems={"center"}
        >
          <Typography
            maxWidth={{}}
            fontSize={{ xs: "18px", md: "18px" }}
            fontWeight={{ xs: 700, md: 800 }}
            lineHeight={{ xs: "100%", md: "100%" }}
            color={"#1E1E1E"}
            align="center"
          >
            {t("socialMediaAccounts")}
          </Typography>
          <Stack
            spacing={3}
            marginTop={"24px"}
            direction={"row"}
            justifyContent={"center"}
          >
            <Link
              target="_blank"
              href={"https://www.linkedin.com/in/jazr-jazr-co-36a903268/"}
            >
              <LinkedInIcon fontSize="large" style={{ color: "#FF7F29" }} />
            </Link>
            <Link
              target="_blank"
              href={"https://x.com/jazrKSA?t=aL1Kmnvw_QWwUadXVwiGTw&s=08"}
            >
              <TwitterIcon fontSize="large" style={{ color: "#FF7F29" }} />
            </Link>
            <Link
              target="_blank"
              href={
                "https://www.instagram.com/jazrksa?igsh=MXJleHozcHJpNzB5OQ=="
              }
            >
              <InstagramIcon fontSize="large" style={{ color: "#FF7F29" }} />
            </Link>
            <Link
              target="_blank"
              href={"https://api.whatsapp.com/send?phone=966550515038"}
            >
              <WhatsAppIcon fontSize="large" style={{ color: "#FF7F29" }} />
            </Link>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Header;
