import { Box, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Card from "./card";

const WhyJazrSystem = () => {
  const { t } = useTranslation("home");

  return (
    <Stack
      //     bgcolor={"#FFF7F7"}
      paddingTop={{ lg: "150px !important" }}
      paddingBottom={{ lg: "100px !important" }}
      position={"relative"}
      id={"whyJazrSystem"}
      p={{ xs: "16px 16px 16px 16px" }}
    >
      <Box display={"flex"} justifyContent={"center"} mb={"50px"}>
        <Typography
          data-aos="fade-up"
          fontSize={{ xs: "20px", lg: "32px" }}
          fontWeight={800}
          lineHeight={{ xs: "24px", lg: "36px" }}
          color={"#000"}
          align="center"
        >
          {t("whyJazrSystem")}
        </Typography>
      </Box>

      <Grid container spacing={3} mb={3}>
        <Grid
          item
          xs={12}
          md={4}
          lg={3}
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <Card
            title="aggregatorsAmbiguity"
            subtitle="aggregatorsAmbiguityDescription"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={3}
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <Card
            title="deliveryOfferFeasibility"
            subtitle="deliveryOfferFeasibilityDescription"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={3}
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <Card
            title="oneJourneyToNetProfit"
            subtitle="oneJourneyToNetProfitDescription"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={3}
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <Card title="excelProblems" subtitle="excelProblemsDescription" />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={3}
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <Card title="saveAccountantSalary" subtitle="saveAccountantSalaryDescription" />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={3}
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <Card title="operatingCostsInAnInnovativeWay" subtitle="operatingCostsInAnInnovativeWayDescription" />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={3}
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <Card title="saveHugeCosts" subtitle="saveHugeCostsDescription" />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={3}
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <Card title="reliabilityReferenceEase" subtitle="reliabilityReferenceEaseDescription" />
        </Grid>
        {/* old */}
        {/* <Grid item xs={12} md={3}>
          <Card title="deliveryOffersTrap" subtitle="subscribeDeliveryOffers" />
        </Grid> */}
        {/* <Grid item xs={12} md={4} lg={3} data-aos="fade-up">
          <Card title="oneTripNetProfit" subtitle="oneJourneyToNetProfit" />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={3}
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <Card title="excelProblems" subtitle="jazarEliminateExcel" />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={3}
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <Card
            title="saveAccountantSalary"
            subtitle="noNeedSmartAccountantNeedDataEntry"
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          lg={3}
          data-aos="fade-up"
          data-aos-delay="250"
        >
          <Card title="operatingCosts" subtitle="allYourOperationalCosts" />
        </Grid>

        <Grid item xs={12} md={4} lg={3} data-aos="fade-up">
          <Card
            title="massiveCostSavings"
            subtitle="netProfitWithHighAccuracy"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={3}
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <Card title="ReliabilityReference" subtitle="ReportsAndGraphical" />
        </Grid> */}
      </Grid>
    </Stack>
  );
};

export default WhyJazrSystem;
