import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CardIcon from "src/assets/svg-icons/home/card.svg";

interface CardProps {
  title: string;
  subtitle: string;
}
const Card = (props: CardProps) => {
  const { t } = useTranslation("home");
  const navigate = useNavigate();

  const { title, subtitle } = props;
  return (
    <Box
      borderRadius={"18px"}
      bgcolor={"#FFF"}
      p={{ xs: 2, sm: "24px" }}
      height={"100%"}
      border={"1px solid rgba(0, 0, 0, 0.08)"}
      display={"flex"}
      position={"relative"}
      flexDirection={"column"}
      onClick={() => navigate("/sign-up")}
      sx={{
        cursor: "pointer",
        "&:hover": {
          background: "linear-gradient(49deg, #FF9954 8%, #FFF 316.14%)",
          boxShadow: "18px 9px 55px 3px #F3F3F3",
          "& *": {
            color: "#FFF !important", // Changes all text to white
          },
          "& .specific-svg": {
            fill: "#FFF !important", // Specific one changes to red
          },
          "& .specific-orange": {
            fill: "#FF7F29 !important", // Specific one changes to red
          },
          "& > .hide-on-hover": {
            display: "block !important",
          },
        },
      }}
    >
      <Box
        width={"48px"}
        height={"48px"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        border={"1px solid rgba(0, 0, 0, 0.03)"}
        bgcolor={"#F9F9FF"}
        borderRadius={"24px"}
      >
        <svg
          style={{ width: "24px", height: "24px", objectFit: "contain" }}
          width="23"
          height="24"
          viewBox="0 0 23 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="fi_3022315" clip-path="url(#clip0_3217_37915)">
            <g id="Group">
              <path
                className="specific-orange"
                id="Vector"
                d="M16.8295 12.8323C13.9845 12.8323 11.6699 15.1469 11.6699 17.9919C11.6699 20.8369 13.9845 23.1515 16.8295 23.1515C19.6984 23.1515 22.0325 20.8369 22.0325 17.9919C22.0325 15.1469 19.6984 12.8323 16.8295 12.8323ZM18.4131 17.3677L16.6788 19.4488C16.5544 19.598 16.3709 19.6828 16.1792 19.6828C16.1543 19.6828 16.1293 19.6814 16.1041 19.6785C15.8861 19.6532 15.6956 19.5196 15.5974 19.3233L14.9037 17.9359C14.7431 17.6146 14.8733 17.2239 15.1945 17.0633C15.5158 16.9026 15.9065 17.0329 16.0671 17.3542L16.3161 17.8522L17.4138 16.5349C17.6438 16.259 18.0539 16.2218 18.3298 16.4516C18.6058 16.6816 18.6431 17.0917 18.4131 17.3677Z"
                fill="#00BF58"
              />
              <path
                className="specific-orange"
                id="Vector_2"
                d="M16.5494 4.65722L13.9105 1.92831C13.9093 1.92701 13.908 1.92567 13.9067 1.92437C13.2991 1.30651 12.4537 0.952148 11.5873 0.952148H3.21527C2.13943 0.952148 1.26416 1.82742 1.26416 2.90326V19.7695C1.26416 20.8454 2.13943 21.7206 3.21527 21.7206H11.5569C10.8093 20.6665 10.3693 19.3797 10.3693 17.9919C10.3693 14.4296 13.2674 11.5315 16.8297 11.5315C17.0492 11.5315 17.2661 11.5425 17.4801 11.5637V6.93469C17.4801 6.07754 17.1495 5.26887 16.5494 4.65722ZM8.15809 15.4771H4.68944C4.33027 15.4771 4.03907 15.1859 4.03907 14.8267C4.03907 14.4675 4.33027 14.1763 4.68944 14.1763H8.15809C8.51727 14.1763 8.80846 14.4675 8.80846 14.8267C8.80846 15.1859 8.51727 15.4771 8.15809 15.4771ZM8.15809 12.0084H4.68944C4.33027 12.0084 4.03907 11.7173 4.03907 11.3581C4.03907 10.9989 4.33027 10.7077 4.68944 10.7077H8.15809C8.51727 10.7077 8.80846 10.9989 8.80846 11.3581C8.80846 11.7173 8.51727 12.0084 8.15809 12.0084ZM8.15809 8.5398H4.68944C4.33027 8.5398 4.03907 8.24861 4.03907 7.88943C4.03907 7.53026 4.33027 7.23906 4.68944 7.23906H8.15809C8.51727 7.23906 8.80846 7.53026 8.80846 7.88943C8.80846 8.24861 8.51727 8.5398 8.15809 8.5398ZM12.3205 12.0084H11.6267C11.2676 12.0084 10.9764 11.7173 10.9764 11.3581C10.9764 10.9989 11.2676 10.7077 11.6267 10.7077H12.3205C12.6796 10.7077 12.9708 10.9989 12.9708 11.3581C12.9708 11.7173 12.6796 12.0084 12.3205 12.0084ZM12.3205 8.5398H11.6267C11.2676 8.5398 10.9764 8.24861 10.9764 7.88943C10.9764 7.53026 11.2676 7.23906 11.6267 7.23906H12.3205C12.6796 7.23906 12.9708 7.53026 12.9708 7.88943C12.9708 8.24861 12.6796 8.5398 12.3205 8.5398Z"
                fill="#00BF58"
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_3217_37915">
              <rect
                width="22.1993"
                height="22.1993"
                fill="white"
                transform="translate(0.548828 0.952148)"
              />
            </clipPath>
          </defs>
        </svg>
      </Box>
      <Typography
        fontSize={"18px"}
        fontWeight={700}
        lineHeight={"28px"}
        marginTop={"8px"}
        marginBottom={"8px"}
        color={"#2B3674"}
      >
        {t(title)}
      </Typography>
      <Typography
        fontSize={"15px"}
        fontWeight={500}
        lineHeight={"22.5px"}
        color={"#4E4E4E"}
        marginBottom={"12px"}
        zIndex={4}
        position={"relative"}
      >
        {t(subtitle)}
      </Typography>

      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        marginTop={"auto"}
        sx={{ cursor: "pointer" }}
        zIndex={4}
        position={"relative"}
      >
        <Typography
          fontSize={"16px"}
          fontWeight={700}
          lineHeight={"16px"}
          color={"#FF7F29"}
        >
          {t("tryNow")}
        </Typography>

        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="specific-svg"
            d="M9.56945 19.5007C9.37945 19.5007 9.18945 19.4307 9.03945 19.2807L2.96945 13.2107C2.67945 12.9207 2.67945 12.4407 2.96945 12.1507L9.03945 6.08066C9.32945 5.79066 9.80945 5.79066 10.0995 6.08066C10.3895 6.37066 10.3895 6.85066 10.0995 7.14066L4.55945 12.6807L10.0995 18.2207C10.3895 18.5107 10.3895 18.9907 10.0995 19.2807C9.95945 19.4307 9.75945 19.5007 9.56945 19.5007Z"
            fill="#FF7F29"
          />
          <path
            className="specific-svg"
            d="M20.4999 13.4307H3.66992C3.25992 13.4307 2.91992 13.0907 2.91992 12.6807C2.91992 12.2707 3.25992 11.9307 3.66992 11.9307H20.4999C20.9099 11.9307 21.2499 12.2707 21.2499 12.6807C21.2499 13.0907 20.9099 13.4307 20.4999 13.4307Z"
            fill="#FF7F29"
          />
        </svg>
      </Box>

      <svg
        className="hide-on-hover"
        style={{
          display: "none ",
          position: "absolute",
          left: "-180px",
          top: "-30px",
          width: "150%",
        }}
        width="238"
        height="356"
        viewBox="0 0 238 356"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="100.75"
          cy="100.5"
          r="100.5"
          fill="white"
          fill-opacity="0.08"
        />
        <circle cx="134.25" cy="253" r="103" fill="white" fill-opacity="0.08" />
      </svg>
    </Box>
  );
};

export default Card;
