import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  useAddEventMutation,
  useUpdateEventMutation,
} from "src/api/purchasing/events";
import FilePondUploader from "src/shared/components/file-pond-uploader";
import { useGetBranches, useGetPaymentMethods } from "src/api/generic";
import { useGetSuppliersList } from "src/api/purchasing/suppliers";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";

interface IProps {
  open: boolean;
  handleClose: () => void;
  eventToEdit?: any;
  refetch: Function;
}

const AddEditEventPopup = (props: IProps) => {
  const { open, handleClose, eventToEdit, refetch } = props;
  const { t } = useTranslation("purchasing");
  const { t: generalT } = useTranslation("general");
  const [files, setFiles] = useState<any>([]);
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  const schema = yup.object().shape({
    mode: yup
      .string()
      .required(t("required"))
      .oneOf(["add", "edit"] as const),
    branch_id: yup.number().required(t("required")),
    type: yup
      .string()
      .required(t("required"))
      .oneOf(["advanced", "supplier", "payment"] as const),
    amount: yup.string().required(t("required")),
    operation: yup.string().when("type", {
      is: (type: string) => type === "supplier" || type === "payment",
      then: (value) =>
        value.required(t("required")).oneOf(["plus", "minus"] as const),
    }),
    note: yup.string(),
    payment_method_id: yup.string().when("type", {
      is: (type: string) => type === "advanced" || type === "payment",
      then: (value) => value.required(t("required")),
    }),
    supplier_id: yup.string().when("type", {
      is: (type: string) => type === "advanced" || type === "supplier",
      then: (value) => value.required(t("required")),
    }),
    date: yup.string().required(t("required")),
  });

  interface FormInputs extends yup.InferType<typeof schema> {}

  // APIS
  const { mutate, isPending, status, error } = useAddEventMutation();
  const {
    mutate: mutateUpdate,
    status: statusUpdate,
    error: errorUpdate,
    isPending: isPendingUpdate,
  } = useUpdateEventMutation();
  const { data: paymentsData } = useGetPaymentMethods();
  const { data: suppliersData } = useGetSuppliersList({});
  const { data: branchesData } = useGetBranches();

  //React hook form
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
    reset,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: { mode: "add" },
  });

  const mode = getValues("mode");

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    console.log({ data });
    const dataCopy: any = { ...data };
    if (!!dataCopy.mode) {
      delete dataCopy.mode;
    }
    if (dataCopy.note === "") {
      delete dataCopy.note;
    }
    if (mode === "edit") {
      delete dataCopy.payment_method_id;
    }
    if (watch("type") === "payment") {
      delete dataCopy.supplier_id;
    }
    if (watch("type") === "supplier") {
      delete dataCopy.payment_method_id;
    }
    const dataToSend: any = {
      ...dataCopy,
      amount: +dataCopy.amount,
      ...(dataCopy.supplier_id && {
        supplier_id: +dataCopy.supplier_id,
      }),
      ...(dataCopy.note && { note: dataCopy.note }),
      operation: watch("type") === "advanced" ? "minus" : dataCopy.operation,
    };

    const formData = new FormData();
    // append data
    for (const key in dataToSend) {
      formData.append(key, dataToSend[key]);
    }
    // append files
    if (files.length > 0) {
      files.forEach((file: any, index: number) => {
        formData.append(`files[${index}]`, file);
      });
    }
    if (mode === "add") {
      mutate(formData);
    } else {
      mutateUpdate({ ...dataToSend, id: eventToEdit.id });
    }
  };

  const onClose = () => {
    handleClose();
    reset();
  };

  // reset form with data to edit
  useEffect(() => {
    if (!!eventToEdit) {
      console.log({ eventToEdit });
      reset({
        mode: "edit",
        type: eventToEdit.type,
        branch_id: eventToEdit.branch?.id,
        amount: eventToEdit.amount,
        payment_method_id: eventToEdit.paymentMethod?.id,
        note: eventToEdit.note || "",
        supplier_id: eventToEdit.supplier?.id,
        operation: eventToEdit.operation,
        date: eventToEdit.date,
      });
    }
  }, [eventToEdit, open]);

  //Add success and error handling
  useEffect(() => {
    if (status === "success") {
      refetch();
      onClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  //Update success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      refetch();
      onClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors?.[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { sx: "100%", lg: "537px" },
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"} lineHeight={"24px"}>
            {mode === "add" ? t("addEvent") : t("editEvent")}
          </Typography>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl
            fullWidth
            size="small"
            error={!!errors.type}
            disabled={mode === "edit"}
          >
            <InputLabel id="demo-simple-select-label">
              {t("eventType")}
            </InputLabel>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <Select {...field} label={t("eventType")}>
                  <MenuItem value="advanced">
                    {t("paymentAdvanceForSupplier")}
                  </MenuItem>
                  <MenuItem value="supplier">{t("eventOfSupplier")}</MenuItem>
                  <MenuItem value="payment">{t("eventOfPayment")}</MenuItem>
                </Select>
              )}
            />
            <FormHelperText id="my-helper-text">
              {errors.type?.message}
            </FormHelperText>
          </FormControl>
          {watch("type") && (
            <>
              {/* select Branch */}
              <FormControl
                fullWidth
                size="small"
                error={!!errors.branch_id}
                sx={{ mt: 1 }}
                disabled={mode === "edit"}
              >
                <InputLabel id="demo-simple-select-label">
                  {t("selectBranches")}
                </InputLabel>
                <Controller
                  name="branch_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      key={field.value}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label={t("selectBranches")}
                    >
                      {branchesData?.data?.map(({ id, name }) => {
                        return (
                          <MenuItem key={id} value={id}>
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
                <FormHelperText id="my-helper-text">
                  {errors.branch_id?.message}
                </FormHelperText>
              </FormControl>
              {/* payment method */}
              {mode === "add" && watch("type") !== "supplier" && (
                <FormControl
                  fullWidth
                  size="small"
                  error={!!errors.payment_method_id}
                  sx={{ mt: 1 }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t("paymentMethod")}
                  </InputLabel>
                  <Controller
                    name="payment_method_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        key={field.value}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={t("paymentMethod")}
                      >
                        {paymentsData?.data
                          ?.filter((pay) => pay.status)
                          .map(({ id, name }) => {
                            return (
                              <MenuItem key={id} value={`${id}`}>
                                {name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    )}
                  />
                  <FormHelperText id="my-helper-text">
                    {errors.payment_method_id?.message}
                  </FormHelperText>
                </FormControl>
              )}
              {/* select Supplier */}
              {watch("type") !== "payment" && (
                <FormControl
                  fullWidth
                  size="small"
                  error={!!errors.supplier_id}
                  sx={{ mt: 1 }}
                  disabled={mode === "edit"}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t("selectSuppler")}
                  </InputLabel>
                  <Controller
                    name="supplier_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        key={field.value}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={t("selectSuppler")}
                      >
                        {suppliersData?.data.map((supplier: any) => {
                          return (
                            <MenuItem key={supplier.id} value={supplier.id}>
                              {supplier.name}
                            </MenuItem>
                          );
                        })}
                        {suppliersData?.data?.length === 0 ? (
                          <Typography align="center" color={"textSecondary"}>
                            {generalT("noData")}
                          </Typography>
                        ) : null}
                      </Select>
                    )}
                  />
                  <FormHelperText id="my-helper-text">
                    {errors.supplier_id?.message}
                  </FormHelperText>
                </FormControl>
              )}

              <Stack spacing={1.5} mt={1}>
                {watch("type") !== "advanced" && (
                  <Typography
                    fontSize={"14px"}
                    lineHeight={"14px"}
                    color="#101828"
                  >
                    {t("plusOrMinus")}
                  </Typography>
                )}

                <Stack
                  direction={"row"}
                  width={"100%"}
                  alignItems={"flex-start"}
                  justifyContent={"space-between"}
                >
                  {watch("type") !== "advanced" && (
                    <FormControl
                      fullWidth
                      size="small"
                      error={!!errors.operation}
                      sx={{
                        width: "fit-content",
                        ml: { xs: "auto", sm: "unset" },
                      }}
                    >
                      <Controller
                        name="operation"
                        control={control}
                        render={({ field }) => (
                          <RadioGroup
                            {...field}
                            key={field.value}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="plus"
                              control={
                                <Radio
                                  size="small"
                                  icon={<CircleOutlinedIcon />}
                                  checkedIcon={<CheckCircleIcon />}
                                />
                              }
                              label={`+ ${t("plus")}`}
                              sx={{ color: "#98A2B3" }}
                              disabled={mode === "edit"}
                            />
                            <FormControlLabel
                              value="minus"
                              control={
                                <Radio
                                  size="small"
                                  icon={<CircleOutlinedIcon />}
                                  checkedIcon={<CheckCircleIcon />}
                                />
                              }
                              label={`- ${t("minus")}`}
                              sx={{ color: "#98A2B3" }}
                              disabled={mode === "edit"}
                            />
                          </RadioGroup>
                        )}
                      />
                      <FormHelperText id="my-helper-text">
                        {errors.operation?.message}
                      </FormHelperText>
                    </FormControl>
                  )}

                  <Box
                    sx={{ width: watch("type") !== "advanced" ? 220 : "100%" }}
                  >
                    {/* Amount */}
                    <Controller
                      name="amount"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          type="number"
                          fullWidth
                          label={t("amount")}
                          size="small"
                          {...field}
                          error={!!errors.amount}
                          helperText={errors.amount?.message}
                          disabled={mode === "edit"}
                        />
                      )}
                    />
                  </Box>
                </Stack>

                {/* note */}
                <Controller
                  name="note"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t("notesOptional")}
                      fullWidth
                      size="small"
                      multiline
                      rows={4}
                      maxRows={4}
                      {...field}
                      error={!!errors.note}
                      helperText={errors.note?.message}
                    />
                  )}
                />
                {/* date */}
                <Controller
                  name="date"
                  control={control}
                  disabled={mode === "edit"}
                  render={({ field }) => (
                    <TextField
                      type="date"
                      label={`${t("transactionDateRepeat")}`}
                      fullWidth
                      size="small"
                      {...field}
                      error={!!errors.date}
                      helperText={errors.date?.message}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
                {/* files */}
                {mode === "add" && (
                  <>
                    <Stack
                      direction={"row"}
                      spacing={"6px"}
                      alignItems={"center"}
                      marginBottom={2}
                    >
                      <FiberManualRecordIcon style={{ fontSize: "10px" }} />
                      <Typography
                        fontSize={"16px"}
                        lineHeight={"19px"}
                        color="#101828"
                      >
                        {t("uploadAttachment")}
                      </Typography>
                    </Stack>
                    <Box width={"100%"} mt={0}>
                      <FilePondUploader
                        onUpload={(e) => setFiles(e)}
                        maxFiles={3}
                        maxFileSize={3}
                        acceptedFileTypes={["image/*", "application/pdf"]}
                      />
                    </Box>
                  </>
                )}
              </Stack>
            </>
          )}
          <Stack spacing={2} direction={"row"} mt={3}>
            <Button
              variant="outlined"
              color="tertiary"
              fullWidth
              onClick={handleClose}
            >
              {t("cancel")}
            </Button>
            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              loading={isPending || isPendingUpdate}
            >
              {mode === "add" ? t("next") : t("next")}
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
};

export default AddEditEventPopup;
