import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DrawerIcon from "src/assets/svg-icons/drawer.svg";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import englandIcon from "src/assets/svg-icons/flags/england.svg";
import saudiIcon from "src/assets/svg-icons/flags/saudi.svg";
import arrowDownIcon from "src/assets/svg-icons/home/down-arrow.svg";
import changeLanguageIcon from "src/assets/svg-icons/home/change-language.svg";
import { setLocalStoredData } from "src/utils/_localStorage";
import { ConstLocalStorage } from "src/constants/_localStorage";
import logo from "src/assets/svg-icons/logo.svg";

export const AppBarItems = [
  // {
  //   label: "whyJazrSystem",
  //   path: "",
  // },
  // {
  //   label: "products",
  //   path: "",
  // },

  // {
  //   label: "prices",
  //   path: "",
  // },

  // {
  //   label: "aboutUs",
  //   path: "",
  // },
  // {
  //   label: "contactUs",
  //   path: "",
  // },
  // {
  //   label: "whyJazrSystem",
  //   path: "/#whyJazrSystem",
  // },
  // {
  //   label: "products",
  //   path: "/#products",
  // },
  {
    label: "prices",
    path: "/prices",
  },
  {
    label: "contactUs",
    path: "/contact-us",
  },
  {
    label: "joinUsAsAnAccountant",
    path: "/work-with-us",
  },
];

const PublicMobilAppBar = () => {
  const { t, i18n } = useTranslation("home");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorE2, setAnchorE2] = useState<null | HTMLElement>(null);
  const openMenuE2 = Boolean(anchorE2);

  const handleClickE2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE2(event.currentTarget);
  };

  const handleCloseE2 = () => {
    setAnchorE2(null);
  };
  const handleLanguageChange = (language: "en" | "ar") => {
    i18n.changeLanguage(language);
    setLocalStoredData(ConstLocalStorage.language, language);
    setAnchorEl(null);
    window.location.reload();
  };

  return (
    <Grid
      zIndex={100}
      position={"relative"}
      display={"flex"}
      justifyContent={"space-between"}
      spacing={{ xs: 3, lg: 2 }}
    >
      <Grid xs={6} padding={1}>
        <Box sx={{ cursor: "pointer" }} onClick={() => navigate("/")}>
          <img src={logo} alt="" />
        </Box>
      </Grid>
      <Grid xs={6}>
        <Box p={"20px 16px"}>
          <IconButton sx={{ p: "0" }} onClick={handleOpen}>
            <img width={32} height={32} src={DrawerIcon} alt="" />
          </IconButton>

          <Drawer anchor={"left"} open={open} onClose={handleClose}>
            <Stack
              maxWidth={"252px"}
              minWidth={"252px"}
              py={3}
              borderRight={"1px solid #D0D5DD"}
              height={"100vh"}
              maxHeight={"100vh"}
              position={"sticky"}
              top={"0"}
            >
              <Box
                px={1}
                mb={2}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"end"}
              >
                <Box sx={{ cursor: "pointer" }} onClick={() => navigate("/")}>
                  <img src={logo} width={90} alt="" />
                </Box>

                <Button variant="text" onClick={handleClickE2} size="small">
                  <Stack direction={"row"} spacing={1}>
                    <img src={arrowDownIcon} alt="" />
                    <Typography
                      fontSize={"14px"}
                      fontWeight={500}
                      color={"#334155"}
                      lineHeight={"14px"}
                    >
                      {i18n.language === "en" ? "En" : "Ar"}
                    </Typography>
                    <img src={changeLanguageIcon} alt="" />
                  </Stack>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorE2}
                  open={openMenuE2}
                  onClose={handleCloseE2}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => handleLanguageChange("en")}
                    sx={{ gap: 1 }}
                  >
                    <img src={englandIcon} alt="" />
                    <Typography
                      color={"#1D2939"}
                      fontSize={"16px"}
                      textTransform={"capitalize"}
                    >
                      English
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleLanguageChange("ar")}
                    sx={{ gap: 1 }}
                  >
                    <img src={saudiIcon} alt="" />
                    <Typography
                      color={"#1D2939"}
                      fontSize={"16px"}
                      textTransform={"capitalize"}
                    >
                      العربية
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
              <Stack spacing={1}>
                {AppBarItems.map((item, index) => (
                  <Box
                  // p={1}
                  // display={"flex"}
                  // alignItems={"center"}
                  // sx={{
                  //   cursor: "pointer",
                  //   "&:hover": { backgroundColor: "#FFF8F4" },
                  // }}
                  >
                    {true ? (
                      <Link
                        key={index}
                        to={item.path}
                        // width={"100%"}
                        sx={{
                          display: "block",
                          color: "#344054",
                          textDecoration: "none",
                          // background: "red",
                          p: 1,
                          "&:hover": { backgroundColor: "#FFF8F4" },
                        }}
                        fontSize={"16px"}
                        fontWeight={600}
                        lineHeight={"24px"}
                        component={RouterLink}
                      >
                        {t(item.label)}
                        {item.label === "prices" && (
                          <Typography
                            component={"span"}
                            fontSize={"16px"}
                            color={"#65696E"}
                            lineHeight={"24px"}
                          >
                            {t("freeTrial")}
                          </Typography>
                        )}
                      </Link>
                    ) : (
                      <Link
                        to=""
                        sx={{ color: "#344054", textDecoration: "none" }}
                        fontSize={"16px"}
                        fontWeight={600}
                        lineHeight={"24px"}
                        component={RouterLink}
                      >
                        {t(item.label)}
                      </Link>
                    )}
                  </Box>
                ))}

                <Stack direction={"row"} spacing={1} p={0.5}>
                  <Button
                    variant="outlined"
                    fullWidth={i18n.language === "en" ? true : false}
                    color="tertiary"
                  >
                    {t("signUp")}
                  </Button>
                  <Button
                    fullWidth={i18n.language === "en" ? true : false}
                    variant="contained"
                  >
                    {" "}
                    {t("signIn")}
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Drawer>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PublicMobilAppBar;
