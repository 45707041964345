import { Button, Stack, TablePagination, Typography } from "@mui/material";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import FilterIcon from "src/assets/svg-icons/aggregators/filter.svg";
import { useEffect, useState } from "react";
import FilterDrawer from "./filters";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import AddEditPurchasingPopup from "./add-edit-purchasing";
import PurchasingTable from "./table";
import { useGetPurchasingList } from "src/api/purchasing/purchasing";
import { useNavigate } from "react-router-dom";

const Purchasing = () => {
  const { t } = useTranslation("purchasing");
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const [openAddPurchasingPopup, setOpenAddPurchasingPopup] = useState(false);
  const [refNum, setRefNum] = useState("");
  const [invoiceNum, setInvoiceNum] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [type, setType] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);

  // APIS
  const { data, refetch, isFetching } = useGetPurchasingList({
    page: pageNumber + 1,
    ...(!!refNum && { reference_number: refNum }),
    ...(!!invoiceNum && { invoice_number: invoiceNum }),
    ...(!!paymentId && { payment_method_id: paymentId }),
    ...(!!supplierId && { supplier_id: supplierId }),
    ...(!!dueDate && { date: dueDate }),
  });

  useEffect(() => {
    if (!openFilter) {
      refetch();
    }
  }, [openFilter]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta.total);
    }
  }, [data]);

  const handleAddPurchasingPopup = () => {
    setOpenAddPurchasingPopup(true);
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleClose = () => {
    setOpenFilter(false);
    setOpenAddPurchasingPopup(false);
  };

  const handleNavigate = () => {
    navigate("/purchasing/import");
  };

  return (
    <Stack spacing={3} maxWidth={{ xs: "100%", md: `calc(100vw - 316px)` }}>
      <Stack
        sx={{ flexDirection: { xs: "column", md: "row" } }}
        justifyContent={"space-between"}
        gap={2}
        alignItems={"center"}
      >
        <Typography
          fontWeight={600}
          lineHeight={"19.2px"}
          color={"#475467"}
          textTransform={"uppercase"}
        >
          {t("purchasing")}
        </Typography>
        <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
          <Button
            color="tertiary"
            variant="outlined"
            startIcon={<img src={FilterIcon} alt="" />}
            onClick={handleOpenFilter}
          >
            {t("filter")}
          </Button>
          <Button
            sx={{
              border: "1px solid #98A2B3",
              height: "40px",
              fontWeight: 500,
              borderRadius: "4px",
              padding: "7px 10px",
              background: "#FCFCFD",
              fontSize: "16px",
            }}
            color="tertiary"
            variant="outlined"
            endIcon={<img src={DownloadIcon} alt="" />}
          >
            {t("download")}
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddPurchasingPopup}
          >
            {t("addPurchasing")}
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleNavigate}
          >
            {t("importPurchasing")}
          </Button>
        </Stack>
        <FilterDrawer
          open={openFilter}
          refNum={refNum}
          setRefNum={setRefNum}
          invoiceNum={invoiceNum}
          setInvoiceNum={setInvoiceNum}
          paymentId={paymentId}
          setPaymentId={setPaymentId}
          supplierId={supplierId}
          setSupplierId={setSupplierId}
          dueDate={dueDate}
          setDueDate={setDueDate}
          handleClose={handleClose}
        />
      </Stack>
      <PurchasingTable
        data={data?.data}
        refetch={refetch}
        isFetching={isFetching}
      />
      <TablePagination
        rowsPerPageOptions={[15]}
        component="div"
        count={total}
        rowsPerPage={15}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
      <AddEditPurchasingPopup
        open={openAddPurchasingPopup}
        handleClose={handleClose}
        refetch={refetch}
      />
    </Stack>
  );
};

export default Purchasing;
