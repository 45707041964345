import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableActions from "./table-actions";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import { useGetBranches } from "src/api/generic";
import { Box, Switch, TablePagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useUpdateBranchMutation } from "src/api/settings/branches";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useTranslation } from "react-i18next";



const BranchTable = () => {
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const { t } = useTranslation("general");
  const tableHeadText = [
    t("branchName"),
    t("vat"),
    t("branchLocation"),
    t("activeInactive"),
    t("actions"),
  ];

  // APIS
  const { data, isLoading, refetch } = useGetBranches();
  const { mutate, status, error } = useUpdateBranchMutation();

  const emptyDataArr = data?.data.length === 0;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta?.total as number);
    }
  }, [data]);

  //Update branch success and error handling
  useEffect(() => {
    if (status === "success") {
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: t("updatedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Box>
      <TableContainer component={Paper} variant="outlined">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeadText.map((item) => (
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    lineHeight: "18px",
                    color: "#475467",
                    bgcolor: "#F9FAFB",
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoadingSkeleton rowsLength={8} cellsLength={8} />
          ) : (
            <TableBody>
              {data?.data.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.vat}</TableCell>
                  <TableCell align="center">{row.city.name}</TableCell>
                  <TableCell align="center">
                    {/* <TableSwitch /> */}
                    <Switch
                      defaultChecked={row.status}
                      onChange={(e) => {
                        mutate({ id: row.id, status: e.target.checked });
                      }}
                    />
                    {/* <CustomSwitch
                      // checked={row.status}
                      defaultChecked={row.status}
                      onChange={(e) => {
                        mutate({ id: row.id, status: e.target.checked });
                      }}
                    /> */}
                  </TableCell>
                  <TableCell align="center">
                    <TableActions row={row} refetch={refetch} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
      <TablePagination
        sx={{ mt: 1 }}
        rowsPerPageOptions={[15]}
        component="div"
        count={total}
        rowsPerPage={15}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
    </Box>
  );
};
export default BranchTable;
