import {
  Typography,
  Box,
  Divider,
  Stack,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  InputAdornment,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import ManualIcon from "src/assets/svg-icons/manual.svg";
import FoodicsIcon from "src/assets/svg-icons/foodics.svg";
import BoxSelect from "src/shared/components/box-select";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormWatch,
} from "react-hook-form";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { COGSTypes } from "src/types/generic";
import { FormData } from "../../constants";
import YoutubeViewerBox from "../../youtube-viewer-box";
import { useTranslation } from "react-i18next";
import { ICOGS } from "src/types/add-sales";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { formatNumber } from "src/utils";

const CalculateCOGS = ({
  errors,
  control,
  watch,
  COGSType,
  setCOGSType,
  journeyData,
  journeyTaxDivider,
  lastCogs,
}: {
  errors: FieldErrors<FormData>;
  control: Control<FormData, any>;
  watch: UseFormWatch<FormData>;
  COGSType: COGSTypes;
  setCOGSType: Function;
  journeyData: any;
  journeyTaxDivider: number;
  lastCogs?: ICOGS;
}) => {
  const netSales =
    watch("journey_type") === "manual"
      ? +watch("net_sales_with_tax") / journeyTaxDivider
      : journeyData?.sale?.net_sales;
  const { t } = useTranslation("addSales");
  const { t: generalT } = useTranslation("general");

  return (
    <div>
      <Typography fontSize={"26px"} fontWeight={600}>
        {t("letFindCostSales")}
      </Typography>
      <Typography color={"textSecondary"} mb={4}>
        {t("fillTheRequiredInfo")}
      </Typography>

      {/* <YoutubeViewerBox videoId="djvKcUwSmqw" /> */}

      {watch("journey_type") === "foodics" && (
        <BoxSelect
          label={t("howAddCogs")}
          options={[
            {
              label: t("manually"),
              value: "manual",
              icon: <img src={ManualIcon} alt="" />,
            },
            {
              label: t("foodics"),
              value: "foodics",
              icon: <img src={FoodicsIcon} alt="" />,
              disabled: watch("journey_type") === "manual",
            },
          ]}
          value={COGSType}
          onChange={(value) => setCOGSType(value)}
        />
      )}
      {COGSType === "foodics" ? (
        <>
          <Divider variant="fullWidth" sx={{ mt: 3, mb: 6 }} />
          <Typography
            color={"#475467"}
            fontSize={"18px"}
            textAlign={"center"}
            width={"100%"}
          >
            {t("dataWillBe")}
          </Typography>
        </>
      ) : (
        <Box mt={"13px"}>
          {!!lastCogs ? (
            <Stack
              p={2}
              direction={"row"}
              gap={"10px"}
              bgcolor={"#FFF3EA"}
              borderRadius={"4px"}
              mb={1.5}
            >
              <InfoOutlinedIcon color={"primary"} />
              <Typography>
                {t("lastAddedSales")} {lastCogs?.start_date} {generalT("to")}{" "}
                {lastCogs?.end_date} (COGS : {lastCogs?.percentage}%)
              </Typography>
            </Stack>
          ) : null}

          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={{ xs: 1, sm: 1, md: 3 }}
            flexWrap={"wrap"}
            width={"100%"}
            mb={1.5}
          >
            <Typography color={"textSecondary"}>{t("typeValue")}</Typography>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.has_vat}
              sx={{ width: "fit-content", ml: { xs: "auto", sm: "unset" } }}
              disabled={journeyData?.cogs.type === "formula"}
            >
              <Controller
                name="COGS_type"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="percentage"
                      control={
                        <Radio
                          icon={<CircleOutlinedIcon />}
                          checkedIcon={<CheckCircleIcon />}
                        />
                      }
                      label={t("percentage")}
                      sx={{ color: "#98A2B3" }}
                    />
                    <FormControlLabel
                      value="amount"
                      control={
                        <Radio
                          icon={<CircleOutlinedIcon />}
                          checkedIcon={<CheckCircleIcon />}
                        />
                      }
                      label={generalT("amount")}
                      sx={{ color: "#98A2B3" }}
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Controller
              name="COGS_value"
              control={control}
              render={({ field }) => (
                <TextField
                  sx={{ mb: 3, maxWidth: { xs: "200px", sm: "313px" } }}
                  required
                  label={t("cogsValue")}
                  {...field}
                  error={!!errors.COGS_value}
                  helperText={errors.COGS_value?.message}
                  disabled={journeyData?.cogs.type === "formula"}
                  fullWidth
                  size="small"
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {watch("COGS_type") === "percentage" ? "%" : "SAR"}
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    inputProps: {
                      max: watch("COGS_type") === "percentage" ? "100" : "",
                    },
                  }}
                />
              )}
            />
            <Typography color={"#667085"}>
              {watch("COGS_type") === "percentage"
                ? generalT("amount")
                : t("percentage")}
              :{" "}
              <Typography component={"span"} fontWeight={800}>
                {watch("COGS_value") !== undefined
                  ? watch("COGS_type") === "percentage"
                    ? formatNumber(
                        +((watch("COGS_value") / 100) * netSales).toFixed(2)
                      )
                    : ((watch("COGS_value") / netSales) * 100).toFixed(2)
                  : "0"}{" "}
                {watch("COGS_type") === "percentage" ? generalT("sar") : "%"}
              </Typography>
            </Typography>
          </Stack>
          <Accordion variant="elevation" elevation={1}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography color={"textSecondary"}>
                {t("stillNotCountInventory")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontSize={"14px"}>
                {t("doNotWorryAddClosestCOGS")}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </div>
  );
};

export default CalculateCOGS;
