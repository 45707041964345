import { LoadingButton } from "@mui/lab";
import { Stack, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IProps {
  name: string;
  onDownload: any;
  isLoading: boolean;
}

const ReportBox = ({ name, onDownload, isLoading }: IProps) => {
  const { t } = useTranslation("reports");

  return (
    <Stack
      bgcolor={"#F2F4F7"}
      borderRadius={"8px"}
      overflow={"hidden"}
      position={"relative"}
    >
      <Box
        position={"absolute"}
        top={0}
        right={0}
        bgcolor={"#fff"}
        height={"40px"}
        width={"40px"}
        boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
      />
      <Box minHeight={"100px"} padding={"70px 5px 50px 5px"}>
        <Typography fontSize={"18px"} fontWeight={600} align="center">
          {name}
        </Typography>
      </Box>
      <Box p={"9px 19px"} bgcolor={"#EAECF0"}>
        <LoadingButton
          onClick={onDownload}
          loading={isLoading}
          sx={{ bgcolor: "#F2F4F7" }}
          fullWidth
          variant="outlined"
          color="tertiary"
        >
          {t("download")}
        </LoadingButton>
      </Box>
    </Stack>
  );
};

export default ReportBox;
