export const questions = [
  {
    question: "question1",
    answer: "answer1",
  },
  {
    question: "question2",
    answer: "answer2",
  },
  {
    question: "question3",
    answer: "answer3",
  },
  {
    question: "question4",
    answer: "answer4",
  },
  {
    question: "question5",
    answer: "answer5",
  },
  {
    question: "question6",
    answer: "answer6",
  },
  {
    question: "question7",
    answer: "answer7",
  },
  {
    question: "question8",
    answer: "answer8",
  },
  {
    question: "question9",
    answer: "answer9",
  },
  {
    question: "question10",
    answer: "answer10",
  },
  {
    question: "question11",
    answer: "answer11",
  },
];
