import { useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import FilterIcon from "src/assets/svg-icons/aggregators/filter.svg";
import AddEditTransactionPopup from "../add-edit-transaction-popup";
import { useTranslation } from "react-i18next";

const Header = ({ refetch }: { refetch: Function }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("operationCost");
  const { t: generalT } = useTranslation("general");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: moment().startOf("month").format("yyyy-MM-DD"),
    endDate: moment().endOf("month").format("yyyy-MM-DD"),
  });
  const [endDateFocused, setEndDateFocused] = useState(false);

  const isOutsideRange = (day: moment.Moment) => {
    // Calculate the difference in days between the start and end dates
    const startDate = dateRange.startDate ? moment(dateRange.startDate) : null;
    const endDate = day ? moment(day) : null;
    const range = startDate && endDate ? endDate.diff(startDate, "days") : null;

    // Return true if the range exceeds 30 days, false otherwise
    return endDateFocused && range !== null && (range < 0 || range > 30);
  };

  return (
    <Stack direction={"row"} gap={2}>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        size="small"
        sx={{ height: "40px" }}
        onClick={handleOpen}
      >
        {t("addNewCost")}
      </Button>
      {/* <Box sx={{ minWidth: 207 }}>
        <DateRangeInput
          startDate={dateRange.startDate ? moment(dateRange.startDate) : null} // moment.Moment | null;
          startDateId={`your_unique_start_date_id`} // moment.Moment | null;
          endDate={dateRange.endDate ? moment(dateRange.endDate) : null} // moment.Moment | null;
          endDateId={`your_unique_end_date_id`} // string;
          onDatesChange={(arg: {
            startDate: moment.Moment | null;
            endDate: moment.Moment | null;
          }) => {
            setDateRange({
              startDate: arg.startDate?.format("yyyy-MM-DD") as string,
              endDate: arg.endDate?.format("yyyy-MM-DD") as string,
            });
          }}
          isOutsideRange={isOutsideRange}
          onFocusChange={(focused: "startDate" | "endDate" | null) => {
            setEndDateFocused(focused === "endDate");
          }}
        />
      </Box> */}
      <Button
        color="tertiary"
        variant="outlined"
        size="small"
        endIcon={<img src={FilterIcon} height={"16px"} alt="" />}
        sx={{ height: "40px" }}
      >
        {generalT("filter")}
      </Button>
      <Box sx={{ minWidth: 114 }}>
        <Button
          color="tertiary"
          variant="outlined"
          endIcon={<img src={DownloadIcon} alt="" />}
        >
          {generalT("download")}
        </Button>
      </Box>
      {open && (
        <AddEditTransactionPopup
          open={open}
          handleClose={handleClose}
          refetch={refetch}
        />
      )}
    </Stack>
  );
};

export default Header;
