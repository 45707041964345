import { Box, Container, Fab } from "@mui/material";
import Header from "./header";
import WhyJazrSystem from "./why-jazar-system";
import PublicMobilAppBar from "src/shared/components/public-mobile-appbar";
import WhatJazrSystem from "./what-jazar-system";
import JazarSystemPros from "./jazar-system-pros";
import JazarSystemDifference from "./jazar-system-difference";
import WhatUserNeed from "./what-user-need";
import Footer from "src/shared/components/footer";
import PublicAppBar from "src/shared/components/public-appbar";
import greenCercle from "src/assets/img/home/green-cercle.webp";
import orangeCercle from "src/assets/img/home/orange-cercle.webp";
import AOS from 'aos';
import { useEffect } from "react";

const HomePage = () => {
  useEffect(() => {
    AOS.init({
      duration: 500,
      offset:100,
     

    });
  }, []);
  return (
    <div style={{position:"relative",width:"100%",overflowX:"hidden"}}>
          <div style={{position:"relative",width:"100%",overflowX:"hidden"}}>
      <Box position={"relative"} zIndex={2} display={{ xs: "none", md: "block" }}>
        <PublicAppBar />
      </Box>
      <Box display={{ xs: "block", md: "none" }}>
        <PublicMobilAppBar />
      </Box>
      <img src={orangeCercle} className="responsive-circle dd" style={{opacity:"0.75",position: "absolute",top:"320px",insetInlineStart:"-650px" , width: "1400px", height: "1400px",objectFit:"contain"}}/>
      <img src={greenCercle} className="responsive-circle"  style={{opacity:"0.75",position: "absolute",top:"-620px",insetInlineEnd:"-650px" , width: "1400px", height: "1400px",objectFit:"contain"}}/>
     
      <Container
    
      sx={{
        maxWidth: '100%', 
        minWidth: {
          xs: '100%',  
          sm: '100%',  
          md: '100%', 
          lg: '1320px',
         
        },
        position:"relative",
        zIndex:"20",
        marginX: 'auto', 
        padding:"0px"
      }}>
      { <Header /> }
      <WhatJazrSystem />
      <JazarSystemPros />
      </Container>
      <div style={{position:"relative",width:"100%"}}>
      <img src={orangeCercle} className="responsive-circle dd"  style={{opacity:"0.75",position: "absolute",top:"320px",insetInlineStart:"-650px" , width: "1400px", height: "1400px",objectFit:"contain"}}/>
      <img src={greenCercle} className="responsive-circle" style={{opacity:"0.75",position: "absolute",top:"-620px",insetInlineEnd:"-650px" , width: "1400px", height: "1400px",objectFit:"contain"}}/>
      <Container
    
    sx={{
      maxWidth: '100%', 
      minWidth: {
        xs: '100%',  
        sm: '100%',  
        md: '100%', 
        lg: '1320px',
       
      },
      position:"relative",
      zIndex:"20",
      marginX: 'auto', 
       padding:"0px !important",
    }}>
      <JazarSystemDifference />
       <WhatUserNeed /> 
      
      <WhyJazrSystem />
      </Container>
      </div>
      <Footer />
      </div>
    </div>
  );
};

export default HomePage;
