import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Card from "./card";

const TermsJoin = () => {
  const { t } = useTranslation("workWithUs");

  const handleClick = () => {
    const url =
      "https://docs.google.com/forms/d/1xm2E0f6VDjNhpZnHesW1zh0INej1p6yXSkCMg5mzHMk/viewform?edit_requested=true";

    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Stack
      paddingTop={{ lg: "150px !important" }}
      paddingBottom={{ lg: "100px !important" }}
      position={"relative"}
      id={"whyJazrSystem"}
      p={{ xs: "16px 16px 16px 16px" }}
    >
      <Box display={"flex"} justifyContent={"center"} mb={"50px"}>
        <Typography
          data-aos="fade-up"
          data-aos-delay="100"
          fontSize={{ xs: "20px", lg: "28px" }}
          fontWeight={800}
          lineHeight={{ xs: "150%", lg: "150%" }}
          color={"#000"}
          align="center"
        >
          {t("conditions")}
        </Typography>
      </Box>

      <Grid container spacing={2} mb={3}>
        <Grid item xs={12} md={4} lg={2.4} data-aos="fade-up">
          <Card
            title={t("accountingKnowledge")}
            subtitle={t("basicAccountingPrinciples")}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={2.4}
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <Card
            title={t("englishLanguage")}
            subtitle={t("understandingInvoiceDetails")}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={2.4}
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <Card
            title={t("attentionToDetail")}
            subtitle={t("focusOnDataEntry")}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          lg={2.4}
          data-aos="fade-up"
          data-aos-delay="250"
        >
          <Card
            title={t("accuracyInWork")}
            subtitle={t("accuracyInDataEntry")}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          lg={2.4}
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <Card
            title={t("onTimeMonthlySubmission")}
            subtitle={t("noDelaysInEntries")}
          />
        </Grid>
      </Grid>
      <Box
        mt={4}
        data-aos="fade-up"
        data-aos-delay="50"
        style={{
          width: "100%",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={handleClick}
          sx={{
            minWidth: "230px",
          }}
          size="large"
        >
          {t("registerNow")}
        </Button>
      </Box>
    </Stack>
  );
};

export default TermsJoin;
