import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import MyProfile from "./pages/my- profile";

const resources = {
  en: {
    // translation: require("./locales/en/auth.json"),
    auth: require("./locales/en/auth.json"),
    dashboard: require("./locales/en/dashboard.json"),
    aggregators: require("./locales/en/aggregators.json"),
    general: require("./locales/en/general.json"),
    operationCost: require("./locales/en/operation-cost.json"),
    settings: require("./locales/en/settings.json"),
    addSales: require("./locales/en/add-sales.json"),
    profile: require("./locales/en/profile.json"),
    home: require("./locales/en/home.json"),
    prices: require("./locales/en/prices.json"),
    reports: require("./locales/en/reports.json"),
    purchasing: require("./locales/en/purchasing.json"),
    workWithUs: require("./locales/en/work-with-us.json"),
  },
  ar: {
    // translation: require("./locales/ar/auth.json"),
    auth: require("./locales/ar/auth.json"),
    dashboard: require("./locales/ar/dashboard.json"),
    aggregators: require("./locales/ar/aggregators.json"),
    general: require("./locales/ar/general.json"),
    operationCost: require("./locales/ar/operation-cost.json"),
    settings: require("./locales/ar/settings.json"),
    addSales: require("./locales/ar/add-sales.json"),
    profile: require("./locales/ar/profile.json"),
    home: require("./locales/ar/home.json"),
    prices: require("./locales/ar/prices.json"),
    reports: require("./locales/ar/reports.json"),
    purchasing: require("./locales/ar/purchasing.json"),
    workWithUs: require("./locales/ar/work-with-us.json"),
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
