import { useEffect, useState } from "react";
import { Box, Button, Stack, Link, IconButton } from "@mui/material";
import logo from "src/assets/svg-icons/logo.svg";
import CompanyDetails from "./steps/company-details";
import CalculateSales from "./steps/calculate-sales";
import CalculateCOGS from "./steps/calculate-cogs";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import BankFee from "./steps/bank-fee";
import AddAggregators from "./steps/add-aggregators";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AggregatorDetails from "./steps/aggregator-details";
import MakeBalance from "./steps/make-balance";
import Summary from "./steps/summary";
import { COGSTypes, FormModeTypes, JourneyTypes } from "src/types/generic";
import { defaultLottieOptions, initSteps, schema } from "./constants";
import { BorderLinearProgress } from "./border-linear-progress";
import { FormData } from "./constants";
import {
  useAddCompanyMutation,
  useCreateJourneyMutation,
  useCreateOrdersSalesMutation,
  useGetAllJourneysDateRange,
  useGetCompany,
  useGetDraftJourney,
  useGetJourneyById,
  useGetLastCogs,
  useUpdateCompanyMutation,
  useUpdateJourneyMutation,
} from "src/api/add-sales";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { IPayment } from "src/types/add-sales";
import { Link as RouterLink } from "react-router-dom";
import { useGetBranches, useGetPaymentMethods } from "src/api/generic";
import Lottie from "react-lottie";
import { useGetAggregatorsList } from "src/api/aggreagators";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { useTranslation } from "react-i18next";

let defaultSteps = initSteps;

const AddAEditSalesPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [mode, setMode] = useState<FormModeTypes>(!id ? "add" : "edit");
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  let [searchParams, setSearchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState(defaultSteps);
  const [COGSType, setCOGSType] = useState<COGSTypes>("manual");
  const [isOnboarding, setIsOnboarding] = useState<boolean>(false);
  const [isCompanyAdded, setIsCompanyAdded] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [balanceAmountMatch, setBalanceAmountMatch] = useState(true);
  const [balanceQntyMatch, setBalanceQntyMatch] = useState(true);
  const [branchId, setBranchId] = useState("");
  const [selectedBranchVat, setSelectedBranchVat] = useState(0);
  const [filesChanged, setFilesChanged] = useState(true);
  // const [journeyTaxPercentage, setJourneyTaxPercentage] = useState<number>(0);
  // const [journeyTaxDivider, setJourneyTaxDivider] = useState<number>(0);
  const { t, i18n } = useTranslation("addSales");
  const { t: generalT } = useTranslation("general");
  // React hook form
  const {
    formState: { errors },
    trigger,
    getValues,
    setValue,
    reset,
    watch,
    control,
  } = useForm<FormData>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      journey_type: "foodics",
      COGS_type: "percentage",
      has_jazr_calculations: "no",
      are_payments_new_agg: "yes",
      net_sales_with_tax: "",
      total_order_quantity: "",
    },
  });
  const {
    fields,
    append,
    remove,
    replace: replaceAggregators,
  } = useFieldArray({
    name: "aggregators",
    control,
  });
  const {
    fields: balanceFields,
    append: balanceAppend,
    replace: replaceBalance,
    remove: balanceRemove,
  } = useFieldArray({
    name: "balance",
    control,
  });
  const {
    fields: newPaymentsAggFields,
    append: appendNewPaymentsAgg,
    replace: replaceNewPaymentsAgg,
    remove: removeNewPaymentsAgg,
  } = useFieldArray({
    name: "new_payments_aggregators",
    control,
  });

  // APIS
  const {
    data: companyData,
    status: getCompanyStatus,
    isLoading,
    isFetching: isFetchingCompany,
  } = useGetCompany();
  const {
    mutate: mutateUpdateCompany,
    status: updateCompanyStatus,
    isPending: updateCompanyIsPending,
    data: updateCompanyResponse,
    error: updateCompanyError,
  } = useUpdateCompanyMutation(
    companyData?.data?.company?.id || searchParams.get("journeyId") || ""
  );
  const {
    mutate: mutateCreateOrders,
    isPending: isPendingCreateOrders,
    status: createOrdersStatus,
    error: createOrdersError,
  } = useCreateOrdersSalesMutation();
  const {
    data: journeyData,
    refetch: refetchJourney,
    isFetching,
    isLoading: isLoadingJourneyData,
    status: getJourneyStatus,
  } = useGetJourneyById({
    journeyId: !!id ? id : searchParams.get("journeyId") || "",
    enabled: !!id,
  });
  const {
    mutate: mutateCreateJourney,
    status: createJourneyStatus,
    isPending: isPendingCreateJourney,
    error: createJourneyError,
  } = useCreateJourneyMutation();
  const {
    mutate: mutateUpdateJourney,
    status: updateJourneyStatus,
    isPending: isPendingUpdateJourney,
    error: updateJourneyError,
  } = useUpdateJourneyMutation();
  const { data: branchesData, isLoading: isLoadingBranches } = useGetBranches();
  const { data: aggregatorsData } = useGetAggregatorsList({
    enabled: mode === "add",
  });

  const { data: paymentsData } = useGetPaymentMethods();

  const { data: draftJourneyData, status: getDraftJourneyStatus } =
    useGetDraftJourney({
      branch_id: branchId,
      enabled: !!branchId && mode === "add",
    });

  const { data: journeysDateRange, status: getJourneysDateRangeStatus } =
    useGetAllJourneysDateRange({
      branch_id: branchId,
      enabled: !!branchId && mode === "add" && !isOnboarding,
    });

  const { data: lastCogsData, status: getLastCogsStatus } = useGetLastCogs({
    branch_id: branchId,
    enabled: !!branchId && mode === "add" && !isOnboarding,
  });

  // Constants
  const journeyType = watch("journey_type");
  const aggregatorsStartStep = isOnboarding ? 5 : 4;
  // useEffect(() => {
  //   const taxPercentage =
  //     watch("journey_type") === "manual" && mode === "add"
  //       ? branchVat / 100 || Number(watch("vat")) / 100
  //       : journeyData?.data.vat / 100; //end
  //   const taxDivider = 1 + taxPercentage;
  //   setJourneyTaxPercentage(taxPercentage);
  //   setJourneyTaxDivider(taxDivider);
  // }, [journeyData, watch("journey_type"), branchVat, getJourneyStatus]);
  const journeyTaxPercentage =
    watch("journey_type") === "manual" && mode === "add"
      ? Number(watch("vat")) >= 0
        ? Number(watch("vat")) / 100
        : selectedBranchVat / 100
      : journeyData?.data.vat / 100; //end
  const journeyTaxDivider = 1 + journeyTaxPercentage;
  const netSales =
    journeyType === "manual"
      ? +watch("net_sales_with_tax") / journeyTaxDivider
      : journeyData?.data?.sale?.net_sales; //end

  // Validations
  const validate = async () => {
    let stepFields = steps[currentStep].fields;
    if (currentStep === 0 && isCompanyAdded) {
      // Just choose branch if you have company added
      stepFields = ["branch_id"];
    }
    if (currentStep === 2 && COGSType === "foodics") {
      stepFields = [];
    }
    if (currentStep === 1 && watch("journey_type") === "manual") {
      stepFields = ["net_sales_with_tax", "total_order_quantity", "dateRange"];
    }
    if (
      currentStep === 1 &&
      watch("journey_type") === "foodics" &&
      journeyData?.data?.new_payment_methods?.length > 0 &&
      mode === "add" &&
      !isOnboarding
    ) {
      stepFields = [
        ...stepFields,
        "are_payments_new_agg",
        "new_payments_aggregators",
      ];
    }
    // Agg details step : validate step aggregator not all of them
    if (stepFields[0] === "aggregators") {
      const aggIndex = currentStep - aggregatorsStartStep;
      console.log({ aggIndex });
      stepFields = [`aggregators.${aggIndex}`];
    }
    const validStep = await trigger(stepFields as any[], { shouldFocus: true });
    return validStep;
  };

  // Go to next step
  const goNext = async () => {
    const valid = await validate();
    if (!valid) return;
    // Steps before Last step
    if (currentStep < steps.length - 1) {
      // Branch (1st) step
      if (currentStep === 0) {
        const dataToSend = {
          company: {
            name: getValues("company_name"),
          },
          branch: {
            name: getValues("branch_name"),
            city_id: +getValues("city_id"),
            has_vat: getValues("has_vat") === "yes" ? true : false,
            vat:
              getValues("has_vat") === "yes"
                ? !!getValues("vat")
                  ? Number(getValues("vat")) / 100
                  : 0
                : 0,
          },
        };
        if (isCompanyAdded) {
          setCurrentStep((prev) => prev + 1);
        } else {
          // if (addCompanyMode === "add") {
          //   mutateAddCompany(dataToSend);
          // } else {
          mutateUpdateCompany(dataToSend);
          // }
        }
      } else if (
        // Sales step
        (currentStep === 1 && journeyType === "foodics" && !filesChanged) ||
        (currentStep === 1 && journeyType === "foodics" && mode === "edit")
      ) {
        setCurrentStep((prev) => prev + 1);
      } else if (
        currentStep === 1 &&
        journeyType === "foodics" &&
        filesChanged
      ) {
        // Sales step
        mutateCreateOrders({
          journey_id: Number(searchParams.get("journeyId")) || 0,
        });
      } else if (
        // Sales step
        currentStep === 1 &&
        journeyType === "manual" &&
        mode === "add" &&
        !isOnboarding &&
        (moment(watch("dateRange").start_date).isAfter(
          moment(journeysDateRange?.end_date).add(1, "day")
        ) ||
          moment(watch("dateRange").end_date).isBefore(
            moment(journeysDateRange?.start_date).subtract(1, "day")
          ))
      ) {
        setNotifications([
          ...notifications,
          {
            type: "error",
            message: `${t("yourLastTimeRange")} ${
              journeysDateRange?.start_date
            } ${t("to")} ${journeysDateRange?.end_date}.  ${t(
              "pleaseEnsure"
            )} ${moment(journeysDateRange?.end_date)
              .add(1, "day")
              .format("YYYY-MM-DD")}.}.`,
          },
        ]);
        return;
      } else if (currentStep === steps.length - 2) {
        // Balance step
        const accumulatedBalance = getValues("balance").reduce(
          (acc: { amount: number; quantity: number }, payment: any) => {
            acc.amount += +payment.amount; // Use += for accumulation
            acc.quantity += +payment.quantity; // Use += for accumulation
            return acc;
          },
          { amount: 0, quantity: 0 }
        );
        const netSalesWithTax =
          watch("journey_type") === "manual"
            ? +watch("net_sales_with_tax")
            : journeyData?.data.sale.gross_sales;
        const totalQnty =
          watch("journey_type") === "manual"
            ? +watch("total_order_quantity")
            : journeyData?.data.payments.reduce(
                (sum: number, payment: any) => sum + payment.quantity,
                0
              );
        //validate balance total amount
        if (
          accumulatedBalance.amount.toFixed(2) !== netSalesWithTax.toFixed(2)
        ) {
          setNotifications([
            ...notifications,
            {
              type: "error",
              message: t("totalPaymentsMethodsMust"),
            },
          ]);
          setBalanceAmountMatch(false);
          return;
        }
        //validate balance total qnty
        if (accumulatedBalance.quantity.toFixed(2) !== totalQnty.toFixed(2)) {
          setNotifications([
            ...notifications,
            {
              type: "error",
              message: t("totalPaymentsQntyMust"),
            },
          ]);
          setBalanceQntyMatch(false);
          return;
        }
        setCurrentStep((prev) => prev + 1);
      } else {
        // If none of above steps happen, just go next
        setCurrentStep((prev) => prev + 1);
      }
    }
    // Last step
    if (currentStep === steps.length - 1) {
      handleSubmitJourney();
    }
  };

  // Go to previous step
  const goBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  // Add and Delete aggregators
  const addAnotherAggregator = async () => {
    const valid = await validate();
    if (!valid) return;
    append({
      aggregator_name: "",
      aggregator_fee: "",
      payments: [],
      delivery_type: "order",
      delivery_cost: "0",
      net_sales_with_tax: "",
      order_quantity: "",
    });
    setCurrentStep((prev) => prev + 1);
  };
  const deleteAggregator = async () => {
    remove(currentStep - 5);
    setCurrentStep((prev) => prev - 1);
  };

  // Submit journey
  const handleSubmitJourney = () => {
    const dataToSend = {
      ...(mode === "add" && {
        journey: {
          ...(journeyType === "foodics" && {
            id: Number(searchParams.get("journeyId")),
          }),
          type: journeyType,
          branch_id:
            Number(watch("branch_id")) ||
            journeyData?.data?.branch ||
            companyData?.data?.company?.branches[0]?.id,
          ...(journeyType === "manual" && {
            start_date: watch("dateRange.start_date"),
            end_date: watch("dateRange.end_date"),
          }),
        },
      }),
      ...(journeyType === "foodics" && {
        tax_type: watch("has_jazr_calculations") === "yes" ? "jazr" : "foodics",
      }),
      ...(journeyType === "manual" && {
        sale: {
          quantity: Number(watch("total_order_quantity")),
          gross_sales: Number(watch("net_sales_with_tax")),
          discount: Number(watch("discount")) || 0,
          charge: Number(watch("charge_with_tax")) || 0,
          tips: Number(watch("tips")) || 0,
        },
      }),
      cogs: {
        type: COGSType, // manual | foodics
        ...(COGSType === "manual" && {
          is_percentage: getValues("COGS_type") === "percentage",
          amount:
            getValues("COGS_type") === "percentage"
              ? +((getValues("COGS_value") / 100) * netSales).toFixed(7)
              : getValues("COGS_value"),
        }),
      },
      bankFee: {
        bank_fee: +(+getValues("bank_fee") / journeyTaxDivider).toFixed(7) || 0,
      },
      aggregators: [
        ...(getValues("aggregators")?.length > 0
          ? getValues("aggregators").map((aggregator) => {
              return {
                ...(!aggregator.aggregator_id && {
                  name: aggregator.aggregator_name, // name in case of new agg
                }),
                ...(!!aggregator.aggregator_id && {
                  id: aggregator.aggregator_id, // id in case of existing agg
                }),
                aggregator_fee: +aggregator.aggregator_fee,
                ...(aggregator?.payments && //old: journeyType === "foodics" && isOnboarding
                  aggregator?.payments?.length > 0 && {
                    payment_methods: aggregator?.payments?.map((str) =>
                      Number(str)
                    ),
                  }),
                aggregator_sale: {
                  ...(!!aggregator.aggregator_sale_id && {
                    id: aggregator.aggregator_sale_id, // id in case of existing agg
                  }),
                  delivery_type:
                    aggregator.delivery_type === "order"
                      ? "by_order"
                      : "by_amount", // types: by_order | by_amount
                  delivery_fee:
                    aggregator.delivery_type === "order"
                      ? Number(aggregator.delivery_cost || 0)
                      : Number(aggregator.delivery_cost || 0),
                  ...(journeyType === "manual" && {
                    sales: Number(aggregator.net_sales_with_tax),
                    quantity: Number(aggregator.order_quantity),
                  }),
                },
              };
            })
          : []),
        // ...(getValues("new_payments_aggregators")?.length > 0
        //   ? getValues("new_payments_aggregators")?.map((aggregator) => {
        //       return {
        //         name: aggregator.aggregator_name,
        //         aggregator_fee: Number(aggregator.aggregator_fee),
        //         payment_methods: aggregator?.payments?.map((str) =>
        //           Number(str)
        //         ),
        //       };
        //     })
        //   : []),
      ],
      payments: getValues("balance").map((payment, index) => {
        return {
          ...((journeyType === "foodics" ||
            (journeyType === "manual" && !isOnboarding)) && {
            id: Number(payment.id),
          }),
          ...(journeyType === "manual" &&
            isOnboarding &&
            mode === "add" && {
              is_branch: payment.is_branch === true,
            }),
          quantity: Number(payment.quantity),
          total: Number(payment.amount),
          ...(!!payment.payment_method_id && {
            payment_method_id: payment.payment_method_id,
          }),
          name: payment.name,
        };
      }),
    };
    if (mode === "add") {
      mutateCreateJourney(dataToSend);
    } else {
      mutateUpdateJourney({ journey_id: id, ...dataToSend });
    }
  };

  useEffect(() => {
    const selectedBranch = branchesData?.data.find(
      (branch) => branch.id === +watch("branch_id")
    );
    setSelectedBranchVat(Number(selectedBranch?.vat));
  }, [watch("branch_id")]);

  // Add agg list steps if it's manual add sales
  useEffect(() => {
    console.log("re render");
    if (!isOnboarding && mode === "add") {
      if (journeyType === "manual") {
        // Add agg list steps if it's manual add sales
        replaceAggregators([]);
        aggregatorsData?.data.map((agg: any) => {
          console.log("happens");
          if (agg.status) {
            // append({
            //   aggregator_name: agg.name,
            //   aggregator_id: agg.id,
            //   aggregator_fee: agg.aggregator_fee,
            //   payments: agg.related_payment_methods.map(
            //     (pay: any) => pay.payment_method?.id
            //   ),
            //   payments_Names: agg.related_payment_methods.map(
            //     (pay: any) => pay.payment_method?.name
            //   ),
            //   delivery_type: "order",
            //   delivery_cost: "0",
            //   net_sales_with_tax: "",
            //   order_quantity: "",
            // });
          }
        });
      } else {
        replaceAggregators([]);
        // setSteps(defaultSteps);
      }
    }
  }, [journeyType]);

  // Aggregators fields change
  useEffect(() => {
    if (fields.length > 0) {
      const newSteps = fields.map((field, index) => {
        return {
          id: `aggregators_${index}`,
          name: `Aggregators ${index}`,
          fields: ["aggregators"],
        };
      });
      const newArr = [...defaultSteps];
      newArr.splice(aggregatorsStartStep, 0, ...newSteps);
      setSteps(newArr);
    } else {
      setSteps(defaultSteps);
    }
  }, [fields]);

  // Get company success and error handling
  useEffect(() => {
    if (getCompanyStatus === "success") {
      if (companyData?.data?.company?.is_onboarding) {
        setIsOnboarding(true);
        setValue("onboarding", "yes");
        defaultSteps = initSteps;
        setSteps(initSteps);
      } else {
        // remove has_aggregators step if it's add sales not onboarding
        const filteredSteps = defaultSteps.filter(
          (step) => step.id !== "has_aggregators"
        );
        defaultSteps = filteredSteps;
        setSteps(filteredSteps);
      }
      if (companyData?.data?.company?.branches?.length > 0) {
        setIsCompanyAdded(true);
        // reset({
        //   ...getValues(),
        //   company_name: companyData?.data?.company?.name,
        //   branch_name: companyData?.data?.company?.branches[0]?.name,
        //   city_id: companyData?.data?.company?.branches[0]?.city?.id,
        //   has_vat: companyData?.data?.company?.branches[0]?.has_vat
        //     ? "yes"
        //     : "no",
        // });
        // setSearchParams({
        //   journeyId:
        //     companyData?.data?.company?.branches?.[0]?.journeys?.[0]?.id,
        // });
      }
      if (companyData?.data?.company?.branches?.[0]?.id) {
        setBranchId(companyData?.data?.company?.branches[0]?.id);
      }
    }
  }, [getCompanyStatus, isFetchingCompany]);

  // Get draft journey success and error handling
  useEffect(() => {
    if (getDraftJourneyStatus === "success" && mode === "add") {
      setSearchParams({
        journeyId: `${draftJourneyData.data.id}`,
      });
    }
  }, [getDraftJourneyStatus]);

  // Create order (sales step) success and error handling
  useEffect(() => {
    if (createOrdersStatus === "success") {
      setLoading(true);
      setFilesChanged(false);
      const delayedExecution = setTimeout(() => {
        refetchJourney();
        setLoading(false);
      }, 20000);

      return () => clearTimeout(delayedExecution);
    } else if (createOrdersStatus === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            createOrdersError?.data.errors[0].value ||
            generalT("somethingWrong"),
        },
      ]);
    }
  }, [createOrdersStatus]);

  // Balance fields Change
  useEffect(() => {
    if (journeyType === "foodics" && mode === "add") {
      replaceBalance([]);
      if (journeyData?.data.payments?.length > 0) {
        journeyData?.data.payments.forEach((payment: IPayment) => {
          balanceAppend({
            amount: `${payment.total}`,
            quantity: `${payment.quantity}`,
            id: `${payment.id}`,
            name: payment.paymentMethod.name,
            payment_method_id: `${payment.paymentMethod.id}`,
          });
        });
      }

      // Add current aggregators linked to this foodics files journey in add sales
      if (journeyData?.data.current_aggregators.length > 0) {
        // replaceAggregators([]);
        journeyData?.data.current_aggregators.map((agg: any) => {
          if (agg.status) {
            //old: agg.status && fields.length === 0
            // append({
            //   aggregator_name: agg.name,
            //   aggregator_id: agg.id,
            //   aggregator_fee: agg.aggregator_fee,
            //   payments: agg.related_payment_methods.map(
            //     (item: any) => `${item.payment_method.id}`
            //   ),
            //   payments_Names: agg.related_payment_methods.map(
            //     (item: any) => item.payment_method.name
            //   ),
            //   delivery_type: "order",
            //   delivery_cost: "0",
            //   net_sales_with_tax: "",
            //   order_quantity: "",
            // });
          }
        });
      }
    }
  }, [journeyData, isFetching, getJourneyStatus, watch("journey_type")]);

  useEffect(() => {
    console.log("happenssddddd");
    // Check new payments in add sale for foodics files
    if (mode === "add") {
      if (journeyData?.data?.new_payment_methods?.length > 0 && !isOnboarding) {
        setNotifications([
          ...notifications,
          {
            type: "warning",
            message: t("foundPayments"),
          },
        ]);
        appendNewPaymentsAgg({
          aggregator_name: "",
          aggregator_fee: "",
          payments: [],
        });
      } else if (currentStep === 1) {
        setCurrentStep((prev) => prev + 1);
        console.log("happenss");
      }
    }
    // Reset the flow with journey data to edit it
    if (mode == "edit" && journeyData) {
      reset({
        journey_type: journeyData.data.type,
        dateRange: {
          start_date: journeyData.data.start_date,
          end_date: journeyData.data.end_date,
        },
        branch_id: journeyData.data.branch,
        COGS_type:
          journeyData.data.cogs.is_percentage &&
          journeyData.data.cogs.type !== "formula"
            ? "percentage"
            : "amount",
        COGS_value:
          journeyData.data.cogs.is_percentage &&
          journeyData.data.cogs.type !== "formula"
            ? journeyData.data.cogs.percentage
            : journeyData.data.cogs.amount,
        bank_fee: journeyData.data.bankFee.total,
        net_sales_with_tax:
          journeyData.data.type === "manual"
            ? journeyData.data.sale.gross_sales
            : "",
        total_order_quantity:
          journeyData.data.type === "manual"
            ? journeyData.data.sale.quantity
            : "",
        discount:
          journeyData.data.type === "manual"
            ? journeyData.data.sale.discount
            : "",
        charge_with_tax:
          journeyData.data.type === "manual"
            ? journeyData.data.sale.charge
            : "",
        tips:
          journeyData.data.type === "manual" ? journeyData.data.sale.tips : "",
        has_jazr_calculations:
          journeyData.data.sale.tax_type === "jazr" ? "yes" : "no",
      });

      setCOGSType(journeyData.data.cogs.type);

      // set aggregators to edit it
      journeyData?.data.aggregator_sales.forEach((agg: any) => {
        // append({
        //   aggregator_name: agg.aggregator.name,
        //   aggregator_id: agg.aggregator.id,
        //   aggregator_sale_id: agg.id,
        //   aggregator_fee: agg.aggregator.aggregator_fee,
        //   payments: agg.aggregator.related_payment_methods.map(
        //     (item: any) => `${item.payment_method?.id}`
        //   ),
        //   payments_Names: agg.aggregator.related_payment_methods.map(
        //     (item: any) => item.payment_method?.name
        //   ),
        //   delivery_type: agg.delivery_type === "by_order" ? "order" : "amount",
        //   delivery_cost:
        //     agg.delivery_type === "by_order"
        //       ? agg.delivery_fee_per_order
        //       : agg.delivery_fee,
        //   net_sales_with_tax: agg.sales,
        //   order_quantity: agg.quantity,
        // });
      });

      // set payments to edit it
      if (mode === "edit") {
        journeyData.data.payments.forEach((payment: any) => {
          balanceAppend({
            amount: payment.total as string,
            quantity: payment.quantity as string,
            // agg_id: field.id,
            id: payment.id as string,
            name: payment.paymentMethod.name,
            payment_method_id: payment.paymentMethod.id,
          });
        });
      }
    }
  }, [journeyData]);

  useEffect(() => {
    // Remove new payments aggs fields if files change
    if (filesChanged) {
      replaceNewPaymentsAgg([]);
    }
  }, [filesChanged]);

  useEffect(() => {
    let balanceAppended = false;
    let manualTotalAmount = 0;
    let manualTotalQuantity = 0;
    // Manual onboarding balance
    if (
      journeyType === "manual" &&
      isOnboarding &&
      currentStep === steps.length - 2
    ) {
      if (getValues("has_aggregators") === "no") {
        replaceBalance([]);
      }
      if (
        balanceFields[balanceFields.length - 1]?.agg_id === "branch_balance"
      ) {
        balanceRemove([balanceFields.length - 1]);
      }

      if (fields.length > 0) {
        fields.forEach((field: any, index: number) => {
          const exists = balanceFields.some((item) => item.agg_id === field.id);
          if (!balanceAppended) {
            manualTotalAmount += Number(
              watch("aggregators")[index].net_sales_with_tax
            );
            manualTotalQuantity += Number(
              watch("aggregators")[index].order_quantity
            );
            if (!exists) {
              balanceAppend({
                amount: watch("aggregators")[index]
                  .net_sales_with_tax as string,
                quantity: watch("aggregators")[index].order_quantity as string,
                agg_id: field.id,
                id: field.id,
                name: watch("aggregators")[index].aggregator_name,
              });
              balanceAppended = true;
            }
          }
        });
      }
      console.log(
        { manualTotalAmount, manualTotalQuantity },
        getValues("net_sales_with_tax")
      );
      // const branchBalanceExists = balanceFields.some(
      //   (item) => item.agg_id === "branch_balance"
      // );
      // if (!branchBalanceExists) {
      if (+getValues("net_sales_with_tax") - manualTotalAmount > 0) {
        balanceAppend({
          amount: `${+getValues("net_sales_with_tax") - manualTotalAmount}`,
          quantity: `${
            Number(getValues("total_order_quantity")) - manualTotalQuantity
          }`,
          agg_id: `branch_balance`,
          name:
            getValues("branch_name") ||
            branchesData?.data.find((branch) => branch.id === +branchId)?.name,
          is_branch: true,
        });
      }

      // }
    }
  }, [fields, currentStep]);

  // Set balance with all payments in manual add sale and distribution aggs
  useEffect(() => {
    console.log("distribution manual aggs balance in add", {
      journeyType,
      mode,
      paymentsData,
      isOnboarding,
    });
    if (
      paymentsData &&
      !isOnboarding &&
      journeyType === "manual" &&
      mode === "add" &&
      currentStep === steps.length - 2
      // && fields.length === 0
    ) {
      replaceBalance([]);
      // const netSalesWithTaxValue = "";
      paymentsData.data
        .filter((pay) => pay.status)
        .map((payment, index) => {
          let isPaymentFromAgg = false;
          let aggIndex = 0;
          getValues("aggregators").forEach((agg, index) => {
            if (`${agg.payments?.[0]}` === `${payment.id}`) {
              isPaymentFromAgg = true;
              aggIndex = index;
            }
          });
          console.log({ isPaymentFromAgg });
          balanceAppend({
            amount: isPaymentFromAgg
              ? (getValues("aggregators")[aggIndex]
                  .net_sales_with_tax as string)
              : "0",
            quantity: isPaymentFromAgg
              ? (getValues("aggregators")[aggIndex].order_quantity as string)
              : "0",
            agg_id: ``,
            name: payment.name,
            payment_method_id: `${payment.id}`,
          });
        });
    }
  }, [
    paymentsData,
    isOnboarding,
    journeyType,
    mode,
    watch("journey_type"),
    watch("net_sales_with_tax"),
    watch("total_order_quantity"),
    currentStep,
    // watch("aggregators"),
    // fields,
    // getValues("aggregators")
  ]);

  // Add new payments aggs to aggs steps
  useEffect(() => {
    console.log("new payments aggs change");
    if (currentStep === 2 && journeyType === "foodics") {
      const foodicsFilesAggsLength =
        journeyData?.data.current_aggregators.length;
      if (fields.length > foodicsFilesAggsLength) {
        // means that they already added to aggs
        const fieldsCopy = [...fields];
        // Remove the last x added items
        const newArr = fieldsCopy.slice(
          0,
          -(fields.length - foodicsFilesAggsLength)
        );
        replaceAggregators(newArr);
      }
      // add new payments aggs if yes
      if (watch("are_payments_new_agg") === "yes") {
        if (newPaymentsAggFields.length > 0) {
          watch("new_payments_aggregators").map((agg) => {
            // append({
            //   aggregator_name: agg.aggregator_name as string,
            //   aggregator_id: "",
            //   aggregator_fee: agg.aggregator_fee as string,
            //   payments: agg.payments,
            //   delivery_type: "order",
            //   delivery_cost: "0",
            //   net_sales_with_tax: "",
            //   order_quantity: "",
            //   payments_Names: journeyData?.data?.new_payment_methods
            //     .filter((payment: any) =>
            //       agg.payments?.includes(`${payment.id}`)
            //     )
            //     .map((item: any) => `${item.name}`),
            // });
          });
        }
      }
    }
  }, [currentStep]);

  console.log(
    { fields },
    watch("aggregators"),
    watch("new_payments_aggregators")
  );

  // Update company success and error handling
  useEffect(() => {
    if (updateCompanyStatus === "success") {
      setBranchId(updateCompanyResponse?.data?.branchId);
      setCurrentStep((prev) => prev + 1);
    } else if (updateCompanyStatus === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            updateCompanyError?.data.errors[0].value ||
            generalT("somethingWrong"),
        },
      ]);
    }
  }, [updateCompanyStatus]);

  // Create journey success and error handling
  useEffect(() => {
    if (createJourneyStatus === "success") {
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: t("dataIsAdded"),
        },
      ]);
      navigate("/dashboard");
    } else if (createJourneyStatus === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            (createJourneyError?.data?.errors &&
              createJourneyError?.data?.errors[0]?.value) ||
            generalT("somethingWrong"),
        },
      ]);
    }
  }, [createJourneyStatus]);

  // Update journey success and error handling
  useEffect(() => {
    if (updateJourneyStatus === "success") {
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
      navigate("/settings");
    } else if (updateJourneyStatus === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            (updateJourneyError?.data?.errors &&
              updateJourneyError?.data?.errors[0]?.value) ||
            generalT("somethingWrong"),
        },
      ]);
    }
  }, [updateJourneyStatus]);

  console.log(
    {
      fields,
      steps,
      errors,
      isOnboarding,
      id,
      journeyData,
      selectedBranchVat,
      journeyTaxPercentage,
      journeyType,
    },
    getValues()
  );

  return (
    <Box>
      <Box px={{ xs: 3, md: 4 }} pt={4} pb={{ xs: 1, md: 0 }}>
        <img src={logo} alt="" />
        <IconButton
          sx={{ position: "absolute", top: "30px", right: "30px" }}
          onClick={() => {
            if (isOnboarding) {
              navigate("/dashboard");
            } else {
              navigate("/settings");
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box display={"flex"} justifyContent={"center"} pb={"150px"}>
        {isLoadingBranches || isLoading ? (
          <Box display={"flex"} alignItems={"center"} height={"60vh"}>
            <Lottie options={defaultLottieOptions} height={400} width={400} />
          </Box>
        ) : (
          <Box maxWidth={"736px"} width={"100%"} px={{ xs: 3, md: 0 }}>
            {currentStep === 0 && (
              <CompanyDetails
                control={control}
                errors={errors}
                branches={branchesData?.data || []}
                mode={mode}
                watch={watch}
              />
            )}
            <Box sx={{ display: currentStep === 1 ? "block" : "none" }}>
              <CalculateSales
                mode={mode}
                control={control}
                errors={errors}
                watch={watch}
                setValue={setValue}
                journeyData={journeyData?.data}
                newPaymentsAggFields={newPaymentsAggFields}
                appendNewPaymentsAgg={appendNewPaymentsAgg}
                removeNewPaymentsAgg={removeNewPaymentsAgg}
                newPayments={journeyData?.data?.new_payment_methods}
                journeyTaxPercentage={journeyTaxPercentage}
                journeyTaxDivider={journeyTaxDivider}
                journeysDateRange={journeysDateRange}
                setFilesChanged={setFilesChanged}
                filesChanged={filesChanged}
              />
            </Box>
            {currentStep === 2 && (
              <CalculateCOGS
                control={control}
                errors={errors}
                watch={watch}
                COGSType={COGSType}
                setCOGSType={setCOGSType}
                journeyData={journeyData?.data}
                journeyTaxDivider={journeyTaxDivider}
                lastCogs={lastCogsData?.data}
              />
            )}
            {currentStep === 3 && (
              <BankFee
                control={control}
                errors={errors}
                watch={watch}
                journeyTaxPercentage={journeyTaxPercentage}
                journeyTaxDivider={journeyTaxDivider}
              />
            )}
            {/* {currentStep === 4 && isOnboarding && (
              <AddAggregators
                control={control}
                errors={errors}
                watch={watch}
                append={append}
                replaceAggregators={replaceAggregators}
              />
            )}
            {((isOnboarding &&
              steps.length > 7 &&
              currentStep >= 5 &&
              currentStep < steps.length - 2) ||
              (!isOnboarding &&
                steps.length > 6 &&
                currentStep >= 4 &&
                currentStep < steps.length - 2)) && (
              <AggregatorDetails
                control={control}
                errors={errors}
                watch={watch}
                index={currentStep - aggregatorsStartStep}
                journeyData={journeyData?.data}
                isOnboarding={isOnboarding}
                journeyTaxPercentage={journeyTaxPercentage}
              />
            )} */}
            {currentStep === steps.length - 2 && (
              <MakeBalance
                balanceFields={balanceFields}
                control={control}
                errors={errors}
                getValues={getValues}
                journeyData={journeyData?.data}
                watch={watch}
                balanceAmountMatch={balanceAmountMatch}
                setBalanceAmountMatch={setBalanceAmountMatch}
                balanceQntyMatch={balanceQntyMatch}
                setBalanceQntyMatch={setBalanceQntyMatch}
              />
            )}
            {currentStep === steps.length - 1 && (
              <Summary
                journeyData={journeyData?.data}
                watch={watch}
                COGSType={COGSType}
                control={control}
                mode={mode}
                journeyTaxPercentage={journeyTaxPercentage}
              />
            )}
          </Box>
        )}
      </Box>
      {/* progress bar */}
      <Box
        bgcolor={"white"}
        zIndex={2}
        width={"100%"}
        position={"fixed"}
        sx={{ bottom: "0" }}
        boxShadow={"0 -8px 50px -5px rgba(50, 50, 50, 0.1)"}
      >
        <BorderLinearProgress
          variant="determinate"
          value={((currentStep + 1) / steps.length) * 100}
        />
        <Stack
          direction={"row"}
          justifyContent={"space-around"}
          m={3}
          gap={"10px"}
        >
          {currentStep > 0 ? (
            <Button onClick={goBack} variant="outlined" color="tertiary">
              {generalT("back")}
            </Button>
          ) : (
            <Box />
          )}

          <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
            {/* {isOnboarding &&
              currentStep > 4 &&
              steps.length > 7 &&
              currentStep < steps.length - 2 && (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={deleteAggregator}
                >
                  {generalT("delete")} {generalT("aggregator")}
                </Button>
              )}
            {isOnboarding &&
              currentStep > 4 &&
              currentStep === steps.length - 3 && (
                <Button
                  variant="outlined"
                  color="tertiary"
                  onClick={addAnotherAggregator}
                >
                  {t("addAnotherAgg")}
                </Button>
              )} */}
            {isOnboarding && currentStep === 1 && (
              <Link
                color={"#1D2939"}
                component={RouterLink}
                underline="always"
                to="/dashboard"
                fontWeight={500}
                sx={{ mx: 1 }}
              >
                {t("skip")}
              </Link>
            )}
            <LoadingButton
              variant="contained"
              endIcon={
                <ArrowForwardIosIcon
                  sx={{
                    height: "15px",
                    transform: i18n.language === "ar" ? "scaleX(-1)" : "",
                  }}
                />
              }
              sx={{ display: "flex" }}
              onClick={goNext}
              loading={
                updateCompanyIsPending ||
                isPendingCreateOrders ||
                loading ||
                isPendingCreateJourney ||
                isPendingUpdateJourney ||
                isLoadingJourneyData
              }
            >
              {currentStep < steps.length - 1 ? t("next") : t("finish")}
            </LoadingButton>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default AddAEditSalesPage;
