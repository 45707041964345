import { Typography, Box, Divider, TextField, Stack } from "@mui/material";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormWatch,
} from "react-hook-form";
import { FormData } from "../../constants";
import YoutubeViewerBox from "../../youtube-viewer-box";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/utils";

const BankFee = ({
  control,
  errors,
  watch,
  journeyTaxPercentage,
  journeyTaxDivider,
}: {
  control: Control<FormData, any>;
  errors: FieldErrors<FormData>;
  watch: UseFormWatch<FormData>;
  journeyTaxPercentage: number;
  journeyTaxDivider: number;
}) => {
  const { t } = useTranslation("addSales");
  const { t: generalT } = useTranslation("general");
  return (
    <>
      <Typography fontSize={"26px"} fontWeight={600}>
        {t("addBankFee")}
      </Typography>
      <Typography color={"textSecondary"} mb={4}>{t("letPutBankFee")}</Typography>

      {/* <YoutubeViewerBox videoId="neQPWbOEDx4" /> */}

      <form>
        <Controller
          name="bank_fee"
          control={control}
          render={({ field }) => (
            <TextField
              label={generalT("bankFee")}
              {...field}
              error={!!errors.bank_fee}
              helperText={errors.bank_fee?.message}
              fullWidth
              size="small"
              type="number"
              sx={{ mb: 3 }}
            />
          )}
        />
      </form>
      <Box>
        <Box height={"7px"} bgcolor={"#D0D5DD"} />
        <Stack p={2} gap={1.5} bgcolor={"#F9FAFB"}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography fontSize={"14px"} color={"#344054"}>
              {t("bankFeeTotal")}
            </Typography>
            <Typography fontSize={"14px"} color={"#344054"}>
              {watch("bank_fee")
                ? formatNumber(
                    +(+watch("bank_fee") / journeyTaxDivider).toFixed(2)
                  )
                : "--"}{" "}
              {generalT("sar")}
            </Typography>
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography fontSize={"14px"} color={"#344054"}>
              {t("bankFeeTax")}
            </Typography>
            <Typography fontSize={"14px"} color={"#344054"}>
              {watch("bank_fee")
                ? formatNumber(
                    +(
                      (+watch("bank_fee") / journeyTaxDivider) *
                      journeyTaxPercentage
                    ).toFixed(2)
                  )
                : "--"}{" "}
              {generalT("sar")}
            </Typography>
          </Stack>
          <Divider variant="fullWidth" />
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography color={"#344054"}>{t("bankFeeIncludeTax")}</Typography>
            <Typography color={"#344054"}>
              {watch("bank_fee") ?  formatNumber(+watch("bank_fee")) : "--"} {generalT("sar")}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default BankFee;
