import {
  Box,
  Button,
  Container,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import logo from "src/assets/svg-icons/logo.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { setLocalStoredData } from "src/utils/_localStorage";
import { ConstLocalStorage } from "src/constants/_localStorage";
import englandIcon from "src/assets/svg-icons/flags/england.svg";
import saudiIcon from "src/assets/svg-icons/flags/saudi.svg";
import arrowDownIcon from "src/assets/svg-icons/home/down-arrow.svg";
import changeLanguageIcon from "src/assets/svg-icons/home/change-language.svg";

export const AppBarRoutes = [
  {
    label: "prices",
    path: "/prices",
  },
  {
    label: "contactUs",
    path: "/contact-us",
  },
  {
    label: "joinUsAsAnAccountant",
    path: "/work-with-us",
  },
];

const homeSectionsRoutes = [
  {
    label: "whyJazrSystem",
    path: "/#whyJazrSystem",
  },
  {
    label: "products",
    path: "/#products",
  },
  // {
  //   label: "aboutUs",
  //   path: "#aboutUs",
  // },
];

const PublicAppBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation("home");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLanguageChange = (language: "en" | "ar") => {
    i18n.changeLanguage(language);
    setLocalStoredData(ConstLocalStorage.language, language);
    setAnchorEl(null);
    window.location.reload();
  };

  return (
    <Container
      sx={{
        maxWidth: "100%",
        minWidth: {
          xs: "100%",
          sm: "100%",
          md: "100%",
          lg: "1320px",
        },
        marginX: "auto",
      }}
    >
      <Stack
        p={{ xs: 2, sm: "32px 20px 20px 32px" }}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        //  bgcolor={"#FFF7F7"}
      >
        <Stack spacing={"37px"} direction={"row"} alignItems={"center"}>
          <Box sx={{ cursor: "pointer" }} onClick={() => navigate("/")}>
            <img src={logo} alt="" style={{ marginTop: "-15px" }} />
          </Box>
          <Stack spacing={2} direction={"row"}>
            {location.pathname === "/" &&
              homeSectionsRoutes.map((item, index) => {
                return (
                  <a
                    style={{
                      color: "#344054",
                      textDecoration: "none",
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: "24px",
                      fontFamily: "Figtree",
                    }}
                    href={item.path}
                  >
                    {t(item.label)}
                  </a>
                );
              })}
            {AppBarRoutes.map((item, index) => {
              return (
                <Link
                  key={index}
                  to={item.path}
                  sx={{
                    color: "#344054",
                    textDecoration: "none",
                    fontFamily: "Figtree",
                  }}
                  fontSize={"16px"}
                  fontWeight={600}
                  lineHeight={"24px"}
                  component={RouterLink}
                >
                  {t(item.label)}
                  {item.label === "prices" && (
                    <Typography
                      component={"span"}
                      fontSize={"16px"}
                      color={"#65696E"}
                      lineHeight={"24px"}
                    >
                      {t("freeTrial")}
                    </Typography>
                  )}
                </Link>
              );
            })}

            {/* {AppBarRoutes.map((item, index) => (
              <Box key={index}>
                {index === 2 || index === 3 ? (
                  <Link
                    key={index}
                    to={item.path}
                    sx={{
                      color: "#344054",
                      textDecoration: "none",
                      fontFamily: "Figtree",
                    }}
                    fontSize={"16px"}
                    fontWeight={600}
                    lineHeight={"24px"}
                    component={RouterLink}
                  >
                    {t(item.label)}
                    <Typography
                      component={"span"}
                      fontSize={"16px"}
                      color={"#65696E"}
                      lineHeight={"24px"}
                    >
                      {t("freeTrial")}
                    </Typography>
                  </Link>
                ) : (
                  // <Link
                  //   to={"#tester"}
                  //   sx={{ color: "#344054", textDecoration: "none" }}
                  //   fontSize={"16px"}
                  //   fontWeight={600}
                  //   lineHeight={"24px"}
                  //   component={RouterLink}
                  // >
                  //   {t(item.label)}
                  // </Link>
                  <a
                    style={{
                      color: "#344054",
                      textDecoration: "none",
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: "24px",
                      fontFamily: "Figtree",
                    }}
                    href={item.path}
                  >
                    {t(item.label)}
                  </a>
                )}
              </Box>
            ))} */}
          </Stack>
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <Button variant="text" onClick={handleClick} size="small">
            <Stack direction={"row"} spacing={1}>
              <img src={arrowDownIcon} alt="" />
              <Typography
                fontSize={"14px"}
                fontWeight={500}
                color={"#334155"}
                lineHeight={"14px"}
              >
                {i18n.language === "en" ? "En" : "Ar"}
              </Typography>
              <img src={changeLanguageIcon} alt="" />
            </Stack>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => handleLanguageChange("en")}
              sx={{ gap: 1 }}
            >
              <img src={englandIcon} alt="" />
              <Typography
                color={"#1D2939"}
                fontSize={"16px"}
                textTransform={"capitalize"}
              >
                English
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => handleLanguageChange("ar")}
              sx={{ gap: 1 }}
            >
              <img src={saudiIcon} alt="" />
              <Typography
                color={"#1D2939"}
                fontSize={"16px"}
                textTransform={"capitalize"}
              >
                العربية
              </Typography>
            </MenuItem>
          </Menu>
          <Button
            variant="outlined"
            color="tertiary"
            onClick={() => navigate("/sign-up")}
          >
            {t("signUp")}
          </Button>
          <Button variant="contained" onClick={() => navigate("/login")}>
            {" "}
            {t("signIn")}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

export default PublicAppBar;
