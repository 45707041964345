import { Box, Grid, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Dashboard from "src/assets/img/home/homeFirst.png";
import Settings from "src/assets/img/home/scoundimg.png";
import followerImg from "src/assets/svg-icons/home/follower.svg";
import addCogsEn from "src/assets/img/home/add-cogs.png";
import addCogsAr from "src/assets/img/home/add-cogs-ar.png";

const JazarSystemPros = () => {
  const { t, i18n } = useTranslation("home");
  const [selected, setSelected] = useState(0);

  const addCogsImg = i18n.language === "en" ? addCogsEn : addCogsAr;
  return (
    <Stack
      //  bgcolor={"#FFF7F7"}
      p={{ lg: "24px" }}
      marginBottom={{ xs: "100px", lg: "280px" }}
      marginTop={{ xs: "60px", lg: 20 }}
      id={"products"}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        mb={{ xs: "24px", lg: "107px" }}
      >
        <Typography
          data-aos="fade-up"
          fontSize={{ xs: "24px", md: "32px" }}
          fontWeight={{ xs: 700, lg: 800 }}
          lineHeight={{ xs: "35px", md: "48px" }}
          color={"#000"}
          align="center"
        >
          {t("mostFeatures")}
        </Typography>
      </Box>

      {/* <Grid display={{ sm: "block", lg: "none" }} mb={{ xs: 3, lg: "16px" }}>
        <Grid
          item
          xs={12}
          p={{ xs: "60px 16px 60px 16px", lg: "24px 24px 24px 24px" }}
          bgcolor={"#FF7F290D"}
        >
          <Box position={"relative"}>
            <Box
              width={{ xs: "100%", lg: "604px" }}
              height={{ xs: "100%", lg: "440px" }}
              zIndex={2}
              position="relative"
            >
              <img src={Dashboard} alt="" width={"100%"} height={"100%"} />
            </Box>
            <Box
              position={"absolute"}
              bottom={-22}
              left={-23}
              display={{ xs: "none", md: "block" }}
              zIndex={1}
            >
              <img
                src={followerImg}
                alt=""
                style={{
                  transform: i18n.language === "en" ? "scaleX(-1)" : "none",
                }}
              />
            </Box>
          </Box>
          <Typography
            color={"#000"}
            fontSize={{ xs: "24px", md: "32px" }}
            fontWeight={{ xs: 800, lg: 800 }}
            lineHeight={{ xs: "130%", md: "48px" }}
            marginTop={{ xs: "40px" }}
            mb={"16px"}
          >
            {t("clickReceiveData")}
          </Typography>
          <Typography
            color={"#333"}
            fontSize={{ xs: "16px", md: "20px" }}
            fontWeight={500}
            lineHeight={{ xs: "24px", md: "34px" }}
            maxWidth={{ xs: "100%", lg: "580px" }}
          >
            {t("seeNetProfits")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          mt={{ xs: 5, md: "" }}
          p={{ xs: "16px 16px 16px 16px", lg: "24px 24px 24px 24px" }}
        >
          <Box position={"relative"}>
            <Box
              width={{ xs: "100%", lg: "604px" }}
              height={{ xs: "100%", lg: "440px" }}
              zIndex={2}
              position="relative"
            >
              <img src={Settings} alt="" width={"100%"} height={"100%"} />
            </Box>
            <Box
              position={"absolute"}
              bottom={-22}
              left={-23}
              display={{ xs: "none", md: "block" }}
              zIndex={1}
              border={"1px rgba(0, 0, 0, 0.14)"}
            >
              <img
                src={followerImg}
                alt=""
                style={{
                  transform: i18n.language === "en" ? "scaleX(-1)" : "none",
                }}
              />
            </Box>
          </Box>
          <Typography
            color={"#000"}
            fontSize={{ xs: "24px", md: "32px" }}
            fontWeight={{ xs: 700, lg: 800 }}
            lineHeight={{ xs: "150%", md: "48px" }}
            marginTop={{ xs: "40px" }}
            mb={"16px"}
          >
            {t("understandSituationAtWork")}
          </Typography>
          <Typography
            color={"#333"}
            fontSize={{ xs: "16px", md: "20px" }}
            fontWeight={500}
            lineHeight={{ xs: "24px", md: "34px" }}
            maxWidth={{ xs: "100%", lg: "580px" }}
          >
            {t("knowYouUseEquation")}
          </Typography>
        </Grid>
      </Grid> */}

      <Grid
        display={{ xs: "flex", lg: "flex" }}
        container
        spacing={2}
        direction={{ xs: "column-reverse", lg: "row" }}
      >
        <Grid
          item
          xs={12}
          lg={6}
          paddingX={{ lg: 5 }}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          data-aos="fade-left"
          px={2}
        >
          <Box
            sx={{ opacity: selected === 0 ? 1 : 0.4, cursor: "pointer" }}
            onClick={() => {
              setSelected(0);
            }}
            borderBottom="1px solid #00000014"
            paddingBottom={5}
            marginBottom={5}
          >
            <Typography
              color={"#000"}
              fontSize={{ xs: "24px", md: "24px" }}
              fontWeight={{ xs: 700, lg: 800 }}
              lineHeight={{ xs: "24px", md: "24px" }}
              mb={"15px"}
              maxWidth={{ xs: "100%", lg: "617px" }}
              sx={{ textAlign: { xs: "center", md: "start" } }}
            >
              {t("clickReceiveData")}
            </Typography>
            <Typography
              color={"#333"}
              fontSize={{ xs: "16px", md: "16px" }}
              fontWeight={500}
              lineHeight={{ xs: "24px", md: "24px" }}
              maxWidth={{ xs: "100%", lg: "611px" }}
              sx={{ textAlign: { xs: "center", md: "start" } }}
            >
              {t("seeNetProfits")}
            </Typography>
          </Box>
          <Box
            onClick={() => {
              setSelected(1);
            }}
            sx={{ opacity: selected === 1 ? 1 : 0.4, cursor: "pointer" }}
          >
            <Typography
              color={"#000"}
              fontSize={{ xs: "20px", md: "20px" }}
              fontWeight={{ xs: 700, lg: 800 }}
              lineHeight={{ xs: "24px", md: "24px" }}
              mb={"15px"}
              maxWidth={{ xs: "100%", lg: "617px" }}
              sx={{ textAlign: { xs: "center", md: "start" } }}
            >
              {t("understandSituationAtWork")}
            </Typography>
            <Typography
              color={"#333"}
              fontSize={{ xs: "16px", md: "16px" }}
              fontWeight={500}
              lineHeight={{ xs: "24px", md: "24px" }}
              maxWidth={{ xs: "100%", lg: "611px" }}
              sx={{ textAlign: { xs: "center", md: "start" } }}
            >
              {t("knowYouUseEquation")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6} data-aos="fade-right" mb={{ xs: 3, md: "0" }}>
          <Box position={"relative"}>
            <Box
              width={{ xs: "100%", lg: "100%" }}
              height={{ xs: "100%", lg: "100%" }}
              mt={{ xs: 3, md: "" }}
              zIndex={2}
              position="relative"
              // maxWidth={500}
              textAlign={"center"}
            >
              <img
                src={selected === 1 ? addCogsImg : addCogsImg}
                alt=""
                style={{ objectFit: "contain" }}
                width={"80%"}
                height={"80%"}
              />
            </Box>
            <Box
              position={"absolute"}
              bottom={-22}
              right={{ xs: 23, lg: 40 }}
              display={{ xs: "block", md: "block" }}
              zIndex={1}
              sx={{ transform: "scaleX(-1)" }}
            >
              <img
                src={followerImg}
                alt=""
                style={{
                  transform: i18n.language === "en" ? "scaleX(-1)" : "none",
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default JazarSystemPros;
