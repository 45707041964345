import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Stack,
  Switch,
  TablePagination,
  Typography,
} from "@mui/material";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import TableActions from "./table-actions";
import TableHeadText from "./table-head-text";
import { useGetPaymentMethods } from "src/api/generic";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import AddEditPayment from "../add-edit-payment";
import { useUpdatePaymentMutation } from "src/api/settings/payments";
import { notificationsState } from "src/store/notifications";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/utils";



const PaymentListTable = () => {
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [open, setOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const { t  } = useTranslation("settings");
  const { t : generalT  } = useTranslation("general");

  const HeadText = [
    generalT("branch"),
    t("paymentName"),
    t("startBalance"),
    t("relatedToAgg"),
    t("paymentBalance"),
    generalT("createdBy"),
    generalT("createdAt"),
    generalT("activeInactive"),
    generalT("actions"),
  ];

  const { data, isLoading, refetch } = useGetPaymentMethods({
    page: pageNumber + 1,
  });
  const { mutate, isPending, status, error } = useUpdatePaymentMutation();
  const emptyDataArr = data?.data.length === 0;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta.total);
    }
  }, [data]);

  //Update payment status success and error handling
  useEffect(() => {
    if (status === "success") {
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Box overflow={"auto"}>
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={3}
      >
        <Typography fontWeight={600} lineHeight={"19.2px"} color={"#475467"}>
          {t("paymentList")}
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          size="small"
          sx={{ height: "40px" }}
          onClick={handleOpen}
        >
          {t("addNewPayment")}
        </Button>
      </Stack>
      <TableContainer component={Paper} variant="outlined">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {HeadText.map((item) => (
                <TableCell align="center" sx={{ bgcolor: "#F9FAFB" }}>
                  <TableHeadText text={item} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoadingSkeleton rowsLength={5} cellsLength={8} />
          ) : (
            <TableBody>
              {data?.data.map((row: any) => (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="inherit">{row.branch.name}</TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{formatNumber(row.balance)}</TableCell>
                  <TableCell align="center">
                    {row.is_aggregator ? "yes" : "no"}
                  </TableCell>
                  <TableCell align="center">{formatNumber(row.current_balance)}</TableCell>
                  <TableCell align="center"> {row.created_by?.name}</TableCell>
                  <TableCell align="center" dir="ltr"> {row.created_at}</TableCell>
                  <TableCell align="center">
                    <Switch
                      defaultChecked={row.status}
                      onChange={(e) => {
                        mutate({ id: row.id, status: e.target.checked });
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TableActions row={row} refetch={refetch} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>

      <TablePagination
        sx={{ mt: 1 }}
        rowsPerPageOptions={[15]}
        component="div"
        count={total}
        rowsPerPage={15}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />

      <AddEditPayment open={open} handleClose={handleClose} refetch={refetch} />
    </Box>
  );
};
export default PaymentListTable;
