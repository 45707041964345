import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import youtubeIcon from "src/assets/svg-icons/youtube.svg";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import { useGetBranches } from "src/api/generic";
import DateRangeInput from "src/shared/components/date-range";
import moment from "moment";
import { useTranslation } from "react-i18next";

const Filters = ({
  branches,
  setBranches,
  dateRange,
  setDateRange,
}: {
  branches: number[];
  setBranches: Function;
  dateRange: {
    startDate: string;
    endDate: string;
  };
  setDateRange: Function;
}) => {
  const [group, setGroup] = useState("day");
  const [endDateFocused, setEndDateFocused] = useState(false);
  const { t } = useTranslation("dashboard");

  const { data } = useGetBranches();

  const handleChangeGroup = (event: SelectChangeEvent) => {
    setGroup(event.target.value as string);
  };

  const handleChangeBranch = (event: any) => {
    const {
      target: { value },
    } = event;
    setBranches(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const isOutsideRange = (day: moment.Moment) => {
    // Calculate the difference in days between the start and end dates
    const startDate = dateRange.startDate ? moment(dateRange.startDate) : null;
    const endDate = day ? moment(day) : null;
    const range = startDate && endDate ? endDate.diff(startDate, "days") : null;

    // Return true if the range exceeds 30 days, false otherwise
    return endDateFocused && range !== null && (range < 0 || range > 30);
  };

  // useEffect(() => {
  //   if (data) {
  //     const allBranchesIds = data?.data.branches.map((branch) => branch.id);
  //     setBranches(allBranchesIds);
  //   }
  // }, [data]);

  return (
    <Stack spacing={"24px"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack direction={"row"} spacing={"12px"}>
          <Typography fontSize={"32px"} lineHeight={"38.4px"}>
            👋🏻
          </Typography>
          <Box>
            <Typography
              fontSize={"32px"}
              lineHeight={"38.4px"}
              color={"#101828"}
            >
              {t("hi")} Ahmed
            </Typography>
            <Typography fontSize={"20px"} lineHeight={"24px"} color={"#98A2B3"}>
              {t("yourThirdEye")}
            </Typography>
          </Box>
        </Stack>
        {/* <Button
          variant="outlined"
          color="error"
          sx={{ height: "48px", border: "1px solid  #B32318" }}
          startIcon={<img src={youtubeIcon} alt="" width={"34px"} />}
          href="https://www.youtube.com/watch?v=5EYICxt_oHs"
          target="_blank"
        >
          <Typography color={"#B32318"} lineHeight={"19.2px"}>
          {t("viewHelp")}
          </Typography>
        </Button> */}
      </Stack>
      <Box bgcolor={"#D0D5DD"} height={"1px"} width={"100%"} />

      <Stack
        sx={{ flexDirection: { xs: "column", md: "row" } }}
        justifyContent={"space-between"}
        gap={2}
        alignItems={"center"}
      >
        <Typography fontWeight={600} lineHeight={"19.2px"}>
        {t("dashboardSummary")}
        </Typography>
        <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
          {/*  Branch Multi Select */}
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-multiple-checkbox-label">{t("branch")}</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={branches}
                onChange={handleChangeBranch}
                input={<OutlinedInput label={t("branch")} />}
                renderValue={(selected) =>
                  selected
                    .map(
                      (selectedId: number) =>
                        data?.data.find(
                          (branch: any) => branch.id === +selectedId
                        )?.name
                    )
                    .join(", ")
                }
                // MenuProps={MenuProps}
              >
                {data?.data.map((branch) => (
                  <MenuItem
                    key={branch.id}
                    value={branch.id}
                    sx={{ padding: "0" }}
                  >
                    <Checkbox
                      size="small"
                      checked={branches.indexOf(branch.id) > -1}
                    />
                    <ListItemText primary={branch.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {/*  Data Select  */}
          <Box sx={{ minWidth: 183 }}>
            <DateRangeInput
              startDate={
                dateRange.startDate ? moment(dateRange.startDate) : null
              } // moment.Moment | null;
              startDateId={`your_unique_start_date_id`} // moment.Moment | null;
              endDate={dateRange.endDate ? moment(dateRange.endDate) : null} // moment.Moment | null;
              endDateId={`your_unique_end_date_id`} // string;
              onDatesChange={(arg: {
                startDate: moment.Moment | null;
                endDate: moment.Moment | null;
              }) => {
                setDateRange({
                  startDate: arg.startDate?.format("yyyy-MM-DD") as string,
                  endDate: arg.endDate?.format("yyyy-MM-DD") as string,
                });
              }}
              isOutsideRange={isOutsideRange}
              onFocusChange={(focused: "startDate" | "endDate" | null) => {
                setEndDateFocused(focused === "endDate");
              }}
            />
          </Box>

          {/*  Group Select  */}
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth size="small">
              <InputLabel>{t("groupBy")}</InputLabel>
              <Select
                value={group}
                label={t("groupBy")}
                onChange={handleChangeGroup}
              >
                <MenuItem value={"day"}>Day</MenuItem>
                {/* <MenuItem value={"week"}>Week</MenuItem>
                <MenuItem value={"month"}>Month</MenuItem> */}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120 }}>
            <Button
              sx={{
                color: "#344054",
                border: "1px solid #98A2B3",
                height: "40px",
              }}
              variant="outlined"
              endIcon={<img src={DownloadIcon} alt="" />}
            >
              {t("download")}
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default Filters;
