import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";

interface IParams {
  page?: number;
  invoice_number?: number;
  account_name?: string;
  payment_method_id?: number;
}

export const useGetOCTransactionsList = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-operation-cost-trans-list", params.page],
    queryFn: async () => {
      return apiClient.get(`/api/invoices`, { params });
    },
  });

interface IInvoiceBody {
  id?: string;
  amount: number;
  tax: number;
  include_tax: 1 | 0;
  date: string;
  payment_method_id: number;
  note: string;
  branch_id: number;
  account_id: number;
  invoice_number: number;
  files?: any;
  repeat_type:
    | "no_repeat"
    | "daily"
    | "weekly"
    | "monthly"
    | "yearly"
    | "custom";
  repeat_months: number;
}

export const useAddOCTransactionMutation = () =>
  useMutation<void, APIErrorResponse, any>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/invoices`, data);
    },
  });

export const useUpdateOCTransactionMutation = () =>
  useMutation<void, APIErrorResponse, IInvoiceBody>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/api/invoices/${id}`, rest);
    },
  });

export const useDeleteOCTransactionMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/invoices/${id}`);
    },
  });

// trans details page
export const useGetOCTransactionById = (id: string) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-operation-cost-trans-by-id", id],
    queryFn: async () => {
      return apiClient.get(`/api/invoices/${id}`);
    },
  });

export const useDeleteOCTransPaymentMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/invoicePayments/${id}`);
    },
  });

export const useAddOCTransactionPaymentMutation = () =>
  useMutation<void, APIErrorResponse, FormData>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/invoicePayments`, data);
    },
  });

// Transaction files
export const useGetOCTransactionFiles = (id: number) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-operation-cost-trans-files", id],
    queryFn: async () => {
      return apiClient.get(`/api/invoiceFiles?invoice_id=${id}`);
    },
    gcTime: 0,
  });

export const useDeleteOCTransactionFileMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/invoiceFiles/${id}`);
    },
  });

export const useAddOCTransactionFileMutation = () =>
  useMutation<void, APIErrorResponse, FormData>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/invoiceFiles`, data);
    },
  });
