import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Collapse,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { questions } from "src/pages/work-with-us/constants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const QuestionsWorks = () => {
  const { t, i18n } = useTranslation("prices");
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <Grid
        container
        spacing={{ xs: 1, lg: 0 }}
        p={{ xs: "16px 16px 60px 16px", lg: "24px 24px 24px 24px" }}
        justifyContent={"center"}
      >
        <Typography
          data-aos="fade-up"
          data-aos-delay="50"
          fontSize={{ xs: "24px", lg: "28px" }}
          fontWeight={700}
          lineHeight={{ xs: "150%", lg: "150%" }}
          marginBottom={"15px"}
          color={"#000000"}
          maxWidth={{ xs: "100%" }}
          textAlign={{ xs: "center" }}
        >
          {t("frequentlyQuestions")}
          <Typography
            component={"span"}
            color={"#FF9954"}
            fontSize={{ xs: "30px", lg: "50px" }}
            fontWeight={{ xs: 400, lg: 700 }}
            textAlign={{ xs: "center" }}
          >
            {i18n.language === "en" ? "?" : "؟"}
          </Typography>
        </Typography>

        {/* frequently Questions */}
        <Grid
          container
          spacing={{ xs: 3, lg: 3 }}
          marginBottom={{ sx: "80px", lg: "80px" }}
          marginTop={{ sx: "10px", lg: "10px" }}
        >
          <Grid item xs={12} lg={6} display={"flex"} justifyContent={"end"}>
            <Stack spacing={"20px"}>
              {questions.map((item) => {
                return (
                  <div data-aos="fade-up" data-aos-offset="200">
                    <Accordion
                      elevation={2}
                      sx={{
                        p: { xs: 1.5, lg: "8px 16px" },
                        borderRadius: "20px !important",
                        border: "1px solid rgba(0, 0, 0, 0.06) !important",
                        boxShadow: "none",
                        maxWidth: { xs: "100%", lg: "682px" },
                        "&:before": {
                          display: "none",
                        },
                        "&.MuiAccordion-root": {
                          borderTop: "none",
                        },
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                          fontSize={{ xs: "16px", lg: "18px" }}
                          fontWeight={{ xs: 800, lg: 800 }}
                          lineHeight={{ xs: "150%", lg: "150%" }}
                          color={"#333333"}
                        >
                          {t(item.question)}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          color={"#585C61"}
                          fontSize={{ xs: "14px", lg: "15px" }}
                          lineHeight={{ xs: "150%", lg: "150%" }}
                          fontWeight={{ xs: 500, lg: 500 }}
                        >
                          {t(item.answer)}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })}
            </Stack>
          </Grid>
          <Grid item xs={12} lg={6} display={"flex"} justifyContent={"end"}>
            <Stack spacing={"20px"}>
              {questions.map((item) => {
                return (
                  <div data-aos="fade-up" data-aos-offset="200">
                    <Accordion
                      elevation={2}
                      sx={{
                        p: { xs: 1.5, lg: "8px 16px" },
                        borderRadius: "20px !important",
                        border: "1px solid rgba(0, 0, 0, 0.06) !important",
                        boxShadow: "none",
                        maxWidth: { xs: "100%", lg: "682px" },
                        "&:before": {
                          display: "none",
                        },
                        "&.MuiAccordion-root": {
                          borderTop: "none",
                        },
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                          fontSize={{ xs: "16px", lg: "18px" }}
                          fontWeight={{ xs: 800, lg: 800 }}
                          lineHeight={{ xs: "150%", lg: "150%" }}
                          color={"#333333"}
                        >
                          {t(item.question)}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          color={"#585C61"}
                          fontSize={{ xs: "14px", lg: "15px" }}
                          lineHeight={{ xs: "150%", lg: "150%" }}
                          fontWeight={{ xs: 500, lg: 500 }}
                        >
                          {t(item.answer)}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default QuestionsWorks;
