import { Box } from "@mui/material";
import Header from "./header";
import Footer from "src/shared/components/footer";
import PublicAppBar from "src/shared/components/public-appbar";
import PublicMobilAppBar from "src/shared/components/public-mobile-appbar";
import greenCercle from "src/assets/img/home/green-cercle.webp";
import orangeCercle from "src/assets/img/home/orange-cercle.webp";
import { useEffect } from "react";
import AOS from 'aos';

const PricesPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 500,
      offset:100,
     

    });
  }, []);
  return (
    <>
        <div style={{position:"relative",width:"100%",overflowX:"hidden"}}>
          <div style={{position:"relative",width:"100%",overflowX:"hidden"}}>
          <img src={orangeCercle} className="responsive-circle dd" style={{opacity:"0.75",position: "absolute",top:"320px",insetInlineStart:"-650px" , width: "1400px", height: "1400px",objectFit:"contain"}}/>
      <img src={greenCercle} className="responsive-circle"  style={{opacity:"0.75",position: "absolute",top:"-620px",insetInlineEnd:"-650px" , width: "1400px", height: "1400px",objectFit:"contain"}}/>
  
      <Box display={{ xs: "none", md: "block" }}>
        <PublicAppBar />
      </Box>
      <Box display={{ xs: "block", md: "none" }}>
        <PublicMobilAppBar />
      </Box>
      <Header />
      <Footer />
      </div>
      </div>
    </>
  );
};

export default PricesPage;
