import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useGetBranches, useGetPaymentMethods } from "src/api/generic";
import { useGetOCAccountsList } from "src/api/operation-cost/accounts";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import {
  useAddOCTransactionMutation,
  useUpdateOCTransactionMutation,
} from "src/api/operation-cost/transactions";
import { LoadingButton } from "@mui/lab";
import FilePondUploader from "src/shared/components/file-pond-uploader";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { FormModeTypes } from "src/types/generic";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "./style.css";
import InfoBox from "src/shared/components/info-box";

const schema = yup.object().shape({
  branch_id: yup.number().required("Required"),
  account_id: yup.number().required("Required"),
  accountType: yup
    .string()
    .required("required")
    .oneOf(["fixed", "variable"] as const),
  amount: yup.string().required("Required"),
  include_tax: yup
    .number()
    .required("Required")
    .oneOf([1, 0] as const),
  tax: yup.string().required("Required"),
  invoice_number: yup.string(),
  payment_method_id: yup.string(),
  note: yup.string(),
  date: yup.string().when("accountType", {
    is: "variable",
    then: (value) => value.required("Required"),
  }),
  cost_type: yup.string().when("accountType", {
    is: "fixed",
    then: (value) => value.required("Required"),
  }),
  dateRange: yup
    .object()
    .shape({
      start_date: yup.date(),
      end_date: yup.date(),
    })
    .when("accountType", {
      is: "fixed",
      then: (value) =>
        value.shape({
          start_date: yup.date().required("Required"),
          end_date: yup.date().required("Required"),
        }),
    }),
});

export interface FormInputs extends yup.InferType<typeof schema> {}

interface IProps {
  open: boolean;
  handleClose: () => void;
  row?: any;
  refetch: Function;
}

const AddEditTransactionPopup = (props: IProps) => {
  const { open, handleClose, row, refetch } = props;
  const mode: FormModeTypes = !!row ? "edit" : "add";
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [files, setFiles] = useState<any>([]);
  const { t } = useTranslation("operationCost");
  const { t: generalT } = useTranslation("general");

  //APIS
  const { data: branchesData } = useGetBranches();
  const { data, isLoading, status: getAccountsStatus } = useGetOCAccountsList();
  const { data: paymentsData } = useGetPaymentMethods();
  const { mutate, status, isPending, error } = useAddOCTransactionMutation();
  const {
    mutate: mutateUpdate,
    status: statusUpdate,
    error: errorUpdate,
    isPending: isPendingUpdate,
  } = useUpdateOCTransactionMutation();

  //RHF
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
    reset,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      tax: "0",
    },
  });

  const onClose = () => {
    handleClose();
    reset();
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    console.log({ data });
    const {
      tax,
      amount,
      note,
      invoice_number,
      payment_method_id,
      date,
      cost_type,
      dateRange,
      include_tax,
      branch_id,
      account_id,
    } = data;

    const dataToSend: any = {
      branch_id: branch_id,
      account_id: account_id,
      amount: include_tax ? +(+amount - +tax).toFixed(5) : +amount,
      tax: tax,
      ...(invoice_number && {
        invoice_number: +invoice_number,
      }),
      include_tax: include_tax,
      ...(note && { note: note }),
      ...(mode === "add" &&
        payment_method_id && { payment_method_id: payment_method_id }),
      date:
        watch("accountType") === "variable"
          ? date
          : moment(dateRange.start_date).format("YYYY-MM-DD"),
      repeat_type: watch("accountType") === "fixed" ? "custom" : "no_repeat",
      ...(watch("accountType") === "fixed" && {
        repeat_months:
          moment(dateRange.end_date).diff(
            moment(dateRange.start_date),
            "months"
          ) + 1,
      }),
      ...(watch("accountType") === "fixed" && {
        end_date: moment(dateRange.end_date)
          .endOf("month")
          .format("YYYY-MM-DD"),
      }),
      ...(watch("accountType") === "fixed" && {
        is_advanced: Number(cost_type),
      }),
    };
    console.log({ dataToSend });
    const formData = new FormData();
    // append data
    for (const key in dataToSend) {
      formData.append(key, dataToSend[key]);
    }
    // append files
    if (files.length > 0) {
      files.forEach((file: any, index: number) => {
        formData.append(`files[${index}]`, file);
      });
    }
    if (mode === "add") {
      mutate(formData);
    } else {
      mutateUpdate({ ...dataToSend, id: row.id });
    }
  };

  useEffect(() => {
    if (!!row) {
      console.log({ row });
      reset({
        branch_id: row.branch.id,
        account_id: row.account.id,
        amount: row.include_tax ? row.total : row.amount,
        tax: row.tax,
        include_tax: row.include_tax ? 1 : 0,
        invoice_number: row.invoice_number || "",
        payment_method_id: row.payments[0]?.id,
        note: row.note || "",
        date: row.date,
        ...(row.account?.type === "fixed" && {
          dateRange: {
            start_date: new Date(row.date),
            end_date: new Date(
              moment(row.end_date).startOf("month").format("YYYY-MM-DD")
            ),
          },
        }),
        cost_type: row.is_advanced ? "1" : "0",
      });
      const selectedAccount = data?.data?.find(
        (account: any) => account.id === row.account.id
      );
      setValue(
        "accountType",
        selectedAccount?.type === "fixed" ? "fixed" : "variable"
      );
    }
  }, [row, open]);

  useEffect(() => {
    console.log(watch("include_tax"));
    if (+watch("include_tax") === 1) {
      setValue(
        "tax",
        `${(((+getValues("amount") || 0) / 1.15) * 0.15).toFixed(2)}`
      );
    } else if (+watch("include_tax") === 0) {
      setValue("tax", `${((+getValues("amount") || 0) * 0.15).toFixed(2)}`);
    }
  }, [watch("include_tax"), watch("amount")]);

  useEffect(() => {
    if (!!watch("account_id")) {
      const selectedAccount = data?.data?.find(
        (account: any) => account.id === watch("account_id")
      );
      console.log({ selectedAccount });
      setValue(
        "accountType",
        selectedAccount?.type === "fixed" ? "fixed" : "variable"
      );
    }
  }, [watch("account_id"), data]);

  //Add OC trans success and error handling
  useEffect(() => {
    if (status === "success") {
      refetch();
      onClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  //Update OC trans success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      refetch();
      onClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors?.[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  console.log({ errors }, getValues());
  console.log({ row });

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100%", sm: 547 },
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"}>
            {mode === "add" ? t("addNewCost") : t("editCost")}
          </Typography>
          <IconButton onClick={onClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            {/* branch */}
            <FormControl fullWidth size="small" error={!!errors.branch_id}>
              <InputLabel id="demo-simple-select-label">
                {generalT("branch")}
              </InputLabel>
              <Controller
                name="branch_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    key={field.value}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={generalT("branch")}
                    disabled={mode === "edit"}
                  >
                    {branchesData?.data?.map(({ id, name }) => {
                      return (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
              <FormHelperText id="my-helper-text">
                {errors.branch_id?.message}
              </FormHelperText>
            </FormControl>

            {/* Account */}
            <FormControl fullWidth size="small" error={!!errors.account_id}>
              <InputLabel id="demo-simple-select-label">
                {t("selectAccount")}
              </InputLabel>
              <Controller
                name="account_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    key={field.value}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={t("selectAccount")}
                    disabled={mode === "edit"}
                  >
                    {data?.data
                      ?.filter(
                        (item: any) =>
                          (!item.has_invoices ||
                            item.type === "variable" ||
                            +watch("account_id") === item.id) &&
                          item.status
                      )
                      .map((account: any) => {
                        return (
                          <MenuItem key={account.id} value={account.id}>
                            {account.name} ({account.type})
                          </MenuItem>
                        );
                      })}
                    {data?.data?.filter(
                      (item: any) =>
                        (!item.has_invoices ||
                          item.type === "variable" ||
                          +watch("account_id") === item.id) &&
                        item.status
                    ).length === 0 ? (
                      <Typography align="center" color={"textSecondary"}>
                        {generalT("noData")}
                      </Typography>
                    ) : null}
                  </Select>
                )}
              />
              <FormHelperText id="my-helper-text">
                {errors.account_id?.message}
              </FormHelperText>
            </FormControl>

            {/* Amount */}
            <Stack direction={"row"} alignItems={"flex-start"} gap={1}>
              <Box sx={{ width: 220 }}>
                <Controller
                  name="amount"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      fullWidth
                      label={t("amount")}
                      size="small"
                      {...field}
                      error={!!errors.amount}
                      helperText={errors.amount?.message}
                    />
                  )}
                />
              </Box>
              <FormControl
                fullWidth
                size="small"
                error={!!errors.include_tax}
                sx={{ width: "fit-content", ml: { xs: "auto", sm: "unset" } }}
              >
                <Controller
                  name="include_tax"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      key={field.value}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="1"
                        control={
                          <Radio
                            size="small"
                            icon={<CircleOutlinedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                          />
                        }
                        label={t("includeTax")}
                        sx={{ color: "#98A2B3" }}
                      />
                      <FormControlLabel
                        value="0"
                        control={
                          <Radio
                            size="small"
                            icon={<CircleOutlinedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                          />
                        }
                        label={t("excludeTax")}
                        sx={{ color: "#98A2B3" }}
                      />
                    </RadioGroup>
                  )}
                />
                <FormHelperText id="my-helper-text">
                  {errors.include_tax?.message}
                </FormHelperText>
              </FormControl>
            </Stack>
            {/* tax */}
            <Controller
              name="tax"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t("taxPutZero")}
                  fullWidth
                  size="small"
                  {...field}
                  error={!!errors.tax}
                  helperText={errors.tax?.message}
                />
              )}
            />
            {/* invoice number */}
            <Controller
              name="invoice_number"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t("invoiceNumberOptional")}
                  fullWidth
                  size="small"
                  {...field}
                  error={!!errors.invoice_number}
                  helperText={errors.invoice_number?.message}
                />
              )}
            />
            {/* payment method */}
            {mode === "add" && (
              <FormControl
                fullWidth
                size="small"
                error={!!errors.payment_method_id}
              >
                <InputLabel id="demo-simple-select-label">
                  {t("paymentMethodOptional")}
                </InputLabel>
                <Controller
                  name="payment_method_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      key={field.value}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label={t("paymentMethodOptional")}
                    >
                      {paymentsData?.data
                        ?.filter((pay) => pay.status)
                        .map(({ id, name }) => {
                          return (
                            <MenuItem key={id} value={`${id}`}>
                              {name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  )}
                />
                <FormHelperText id="my-helper-text">
                  {errors.payment_method_id?.message}
                </FormHelperText>
              </FormControl>
            )}

            {/* note */}
            <Controller
              name="note"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t("notesOptional")}
                  fullWidth
                  size="small"
                  multiline
                  rows={2}
                  maxRows={3}
                  {...field}
                  error={!!errors.note}
                  helperText={errors.note?.message}
                />
              )}
            />

            <Stack gap={1}>
              {watch("accountType") === "fixed" ? (
                <>
                  {/* Cost Distribution Period */}
                  <Typography
                    fontSize={"14px"}
                    fontWeight={600}
                    mb={"5px"}
                    align="center"
                  >
                    {t("selectCostDistributionPeriod")}
                  </Typography>
                  <Controller
                    name="dateRange"
                    control={control}
                    // defaultValue={{
                    //   start_date: "",
                    //   end_date: "",
                    // }}
                    render={({ field }) => (
                      <DatePicker
                        // selected={startDate}
                        selectsRange
                        startDate={
                          field.value?.start_date
                            ? field.value.start_date
                            : undefined
                        }
                        endDate={
                          field.value?.end_date
                            ? field.value.end_date
                            : undefined
                        }
                        onChange={(val) => {
                          console.log({ val });
                          setValue("dateRange", {
                            start_date: val[0] as Date,
                            end_date: val[1] as Date,
                          });
                        }}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        placeholderText={t("transactionDateRepeat")}
                      />
                    )}
                  />
                  <FormHelperText
                    error
                    id="my-helper-text"
                    sx={{ mt: -1, px: 2 }}
                  >
                    {errors.dateRange?.start_date?.message ||
                      errors.dateRange?.end_date?.message}
                  </FormHelperText>
                  <Box mb={1}>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      gap={{ xs: 1, sm: 1, md: 2 }}
                      flexWrap={"wrap"}
                      width={"100%"}
                    >
                      <Typography
                        color={"textSecondary"}
                        fontSize={"14px"}
                        fontWeight={600}
                      >
                        {t("advanceOrLateTransaction")}
                      </Typography>
                      <FormControl
                        fullWidth
                        size="small"
                        error={!!errors.cost_type}
                        sx={{
                          width: "fit-content",
                          ml: { xs: "auto", sm: "unset" },
                        }}
                      >
                        <Controller
                          name="cost_type"
                          control={control}
                          render={({ field }) => (
                            <RadioGroup
                              {...field}
                              key={field.value}
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                            >
                              <FormControlLabel
                                value="1"
                                control={
                                  <Radio
                                    size="small"
                                    icon={<CircleOutlinedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                  />
                                }
                                label={
                                  <Typography fontSize={"14px"}>
                                    {t("advanceTrans")}
                                  </Typography>
                                }
                                sx={{ color: "#98A2B3" }}
                              />
                              <FormControlLabel
                                value="0"
                                control={
                                  <Radio
                                    size="small"
                                    icon={<CircleOutlinedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                  />
                                }
                                label={
                                  <Typography fontSize={"14px"}>
                                    {t("lateTrans")}
                                  </Typography>
                                }
                                sx={{ color: "#98A2B3" }}
                              />
                            </RadioGroup>
                          )}
                        />
                      </FormControl>
                    </Stack>
                    <FormHelperText
                      error
                      id="my-helper-text"
                      sx={{ mt: -1, px: 2 }}
                    >
                      {errors.cost_type?.message}
                    </FormHelperText>
                  </Box>
                  {watch("dateRange")?.start_date &&
                    watch("dateRange")?.end_date && (
                      <InfoBox
                        text={`${t("invoiceReTransactionEvery")} ${
                          moment(watch("dateRange").end_date).diff(
                            moment(watch("dateRange").start_date),
                            "months"
                          ) + 1
                        } ${t("months")}`}
                      />
                    )}
                </>
              ) : (
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="date"
                      label={`${t("dueDate")} (${t("whenBillDue")})`}
                      fullWidth
                      size="small"
                      {...field}
                      error={!!errors.date}
                      helperText={errors.date?.message}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              )}
            </Stack>
          </Stack>
          {/* files */}
          {mode === "add" && (
            <Box width={"100%"} mt={2}>
              <FilePondUploader
                onUpload={(e) => setFiles(e)}
                maxFiles={3}
                maxFileSize={3}
                acceptedFileTypes={["image/*", "application/pdf"]}
              />
            </Box>
          )}
          <Stack spacing={2} direction={"row"} mt={3}>
            <Button
              variant="outlined"
              color="tertiary"
              fullWidth
              onClick={onClose}
            >
              {generalT("cancel")}
            </Button>
            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              loading={isPending || isPendingUpdate}
            >
              {mode === "add" ? t("addNewCost") : generalT("save")}
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
};

export default AddEditTransactionPopup;
