import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Collapse,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import ItemInfo from "../../item-info";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import {
  photographInvoicesAndRelaxFeatures,
  professionalAccountantFeatures,
  professionalAndBenefitsAccountantFeatures,
  questions,
} from "src/pages/prices-page/constants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

const Months = () => {
  const { t, i18n } = useTranslation("prices");
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <Grid container spacing={{ xs: 1, lg: 3 }}>
        {/* professional accountant */}
        <Grid item xs={12} lg={4} data-aos="fade-up" data-aos-delay="200">
          <Paper
            elevation={2}
            sx={{
              p: { xs: 2, lg: 4 },
              width: "100%",
              borderRadius: "20px",
              boxShadow: "none",
              mb: "29px",
              border: "1px solid #EAEFF4",
            }}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              mb={"16px"}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <Typography
                fontSize={"36px"}
                color={"#2B3674"}
                lineHeight={"44px"}
                fontWeight={700}
                mb={{ xs: 2, md: 0 }}
              >
                50 {t("sar")}.{" "}
                <Typography
                  component={"span"}
                  color={"#474747"}
                  fontSize={"20px"}
                  fontWeight={400}
                  lineHeight={"28px"}
                >
                  {t("monthly")}
                </Typography>
              </Typography>
            </Box>
            <Typography
              color={"#2B3674"}
              fontSize={"16px"}
              fontWeight={700}
              lineHeight={"28px"}
              mb={1}
            >
              {t("professionalAccountant")}
            </Typography>
            <Typography color={"#474747"} lineHeight={"20px"}>
              {t("professionalAccountantDescription")}
            </Typography>
            {/* divider */}
            <Box
              width={"100%"}
              height={"1px"}
              bgcolor={"#E2E2E2"}
              my={"18px"}
            />
            <Stack spacing={1}>
              {professionalAccountantFeatures.map((item) => (
                <ItemInfo title={t(item)} />
              ))}
            </Stack>
            <Box display={"flex"} justifyContent={"flex-end"} mt={4}>
              <Button sx={{ width: "100%" }} variant="contained">
                {t("subscribe")}
              </Button>
            </Box>
          </Paper>
        </Grid>
        {/*Professional Accountant + Cost Accountant */}
        <Grid item xs={12} lg={4} data-aos="fade-up" data-aos-delay="300">
          <Paper
            elevation={2}
            sx={{
              p: { xs: 2, lg: 4 },
              width: "100%",
              borderRadius: "20px",
              boxShadow: "none",
              mb: "29px",
              border: "1px solid #EAEFF4",
            }}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              mb={"16px"}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <Typography
                fontSize={"36px"}
                color={"#2B3674"}
                lineHeight={"44px"}
                fontWeight={700}
                mb={{ xs: 2, md: 0 }}
              >
                50 {t("sar")}.{" "}
                <Typography
                  component={"span"}
                  color={"#474747"}
                  fontSize={"20px"}
                  fontWeight={400}
                  lineHeight={"28px"}
                >
                  {t("monthly")}
                </Typography>
              </Typography>
            </Box>
            <Typography
              color={"#2B3674"}
              fontSize={"16px"}
              fontWeight={700}
              lineHeight={"28px"}
              mb={1}
            >
              {t("professionalAndCostAccountant")}
            </Typography>
            <Typography color={"#474747"} lineHeight={"20px"}>
              {t("professionalAndCostAccountantDescription")}
            </Typography>
            {/* divider */}
            <Box
              width={"100%"}
              height={"1px"}
              bgcolor={"#E2E2E2"}
              my={"18px"}
            />
            <Stack spacing={1}>
              {professionalAndBenefitsAccountantFeatures.map((item) => (
                <ItemInfo title={t(item)} />
              ))}
            </Stack>
            <Box display={"flex"} justifyContent={"flex-end"} mt={4}>
              <Button sx={{ width: "100%" }} variant="contained">
                {t("subscribe")}
              </Button>
            </Box>
          </Paper>
        </Grid>
        {/* Photograph only */}
        <Grid item xs={12} lg={4} data-aos="fade-up" data-aos-delay="400">
          <Paper
            elevation={2}
            sx={{
              p: { xs: 2, lg: 4 },
              width: "100%",
              borderRadius: "20px",
              boxShadow: "none",
              mb: "29px",
              border: "1px solid #EAEFF4",
            }}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              mb={"16px"}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <Typography
                fontSize={"36px"}
                color={"#2B3674"}
                lineHeight={"44px"}
                fontWeight={700}
                mb={{ xs: 2, md: 0 }}
              >
                1199 {t("sar")}.{" "}
                <Typography
                  component={"span"}
                  color={"#474747"}
                  fontSize={"20px"}
                  fontWeight={400}
                  lineHeight={"28px"}
                >
                  {t("monthly")}
                </Typography>
              </Typography>
            </Box>
            <Typography
              color={"#2B3674"}
              fontSize={"20px"}
              fontWeight={700}
              lineHeight={"28px"}
              mb={1}
            >
              {t("photographInvoicesAndRelax")}
            </Typography>
            <Typography color={"#474747"} lineHeight={"20px"}>
              {t("noManualInvoiceInput")}
            </Typography>
            {/* divider */}
            <Box
              width={"100%"}
              height={"1px"}
              bgcolor={"#E2E2E2"}
              my={"18px"}
            />
            <Stack spacing={1}>
              {/* <Collapse in={open} timeout="auto" unmountOnExit>
                {professionalAndBenefitsAccountantFeatures.map((item) => (
                  <ItemInfo title={t(item)} />
                ))}
              </Collapse> */}
              {photographInvoicesAndRelaxFeatures.map((item) => (
                <ItemInfo title={t(item)} />
              ))}
            </Stack>
            <Box display={"flex"} justifyContent={"flex-end"} mt={4}>
              <Button sx={{ width: "100%" }} variant="contained">
                {t("subscribe")}
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* frequently Questions */}
      <Grid container marginTop={{ sx: "60px", lg: "80px" }}>
        <Grid data-aos="fade-up" data-aos-delay="50" item xs={12} lg={5}>
          <Typography
            fontSize={{ xs: "28px", lg: "40px" }}
            fontWeight={700}
            lineHeight={{ xs: "150%", lg: "150%" }}
            marginBottom={"15px"}
            color={"#000000"}
            maxWidth={{ xs: "100%", lg: "332px" }}
          >
            {t("frequentlyQuestions")}
            <Typography
              component={"span"}
              color={"#FF9954"}
              fontSize={{ xs: "30px", lg: "50px" }}
              fontWeight={{ xs: 400, lg: 700 }}
            >
              {i18n.language === "en" ? "?" : "؟"}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} lg={7} display={"flex"} justifyContent={"end"}>
          <Stack spacing={"20px"}>
            {questions.map((item) => {
              return (
                <div data-aos="fade-up" data-aos-offset="200">
                  <Accordion
                    elevation={2}
                    sx={{
                      p: { xs: 1.5, lg: "8px 16px" },
                      borderRadius: "20px !important",
                      border: "1px solid rgba(0, 0, 0, 0.06) !important",
                      boxShadow: "none",
                      maxWidth: { xs: "100%", lg: "682px" },
                      "&:before": {
                        display: "none",
                      },
                      "&.MuiAccordion-root": {
                        borderTop: "none",
                      },
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography
                        fontSize={{ xs: "16px", lg: "18px" }}
                        fontWeight={{ xs: 800, lg: 800 }}
                        lineHeight={{ xs: "150%", lg: "150%" }}
                        color={"#333333"}
                      >
                        {t(item.question)}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        color={"#585C61"}
                        fontSize={{ xs: "14px", lg: "15px" }}
                        lineHeight={{ xs: "150%", lg: "150%" }}
                        fontWeight={{ xs: 500, lg: 500 }}
                      >
                        {t(item.answer)}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              );
            })}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
export default Months;
