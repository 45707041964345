import { Box, Container, Fab } from "@mui/material";
import Header from "./header";
import PublicMobilAppBar from "src/shared/components/public-mobile-appbar";

import Footer from "src/shared/components/footer";
import PublicAppBar from "src/shared/components/public-appbar";
import greenCercle from "src/assets/img/home/green-cercle.webp";
import { useEffect } from "react";
import AOS from "aos";
const ContactUs = () => {
  useEffect(() => {
    AOS.init({
      duration: 500,
      offset: 100,
    });
  }, []);
  return (
    <div style={{ position: "relative", width: "100%", overflowX: "hidden" }}>
      <div style={{ position: "relative", width: "100%", overflowX: "hidden" }}>
        <Box
          position={"relative"}
          zIndex={2}
          display={{ xs: "none", md: "block" }}
        >
          <PublicAppBar />
        </Box>
        <Box display={{ xs: "block", md: "none" }}>
          <PublicMobilAppBar />
        </Box>
        <img
          src={greenCercle}
          className="responsive-circle dd"
          style={{
            position: "absolute",
            top: "-620px",
            insetInlineEnd: "-650px",
            width: "1400px",
            height: "1400px",
            objectFit: "contain",
          }}
        />

        <Container
          sx={{
            maxWidth: "100%",
            minWidth: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "1320px",
            },
            position: "relative",
            zIndex: "20",
            marginX: "auto",
            padding: "0px",
          }}
        >
          {<Header />}
        </Container>

        <Footer />
      </div>
    </div>
  );
};

export default ContactUs;
