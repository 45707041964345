import { Box, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import PricesTabs from "./tabs";


const Header = () => {
  const { t } = useTranslation("prices");

  return (
  
    <Container
    
    sx={{
      maxWidth: '100%', 
      minWidth: {
        xs: '100%',  
        sm: '100%',  
        md: '100%', 
        lg: '1320px',
       
      },
      position:"relative",
      zIndex:"20",
      marginX: 'auto', 
       padding:"0px !important",
    }}>
    <Box
    //  bgcolor={"#FFF7F7"}
      p={{ xs: "16px 16px 60px 16px", lg: "24px 24px 24px 24px" }}
     
    >
      <Typography

        align="center"
        color={"#383838"}
        fontSize={{xs:"24px",lg:"32px"}}
        fontWeight={800}
        lineHeight={"150%"}
        mb={"27px"}
      >
        {t("pricesPackages")}
      </Typography>
      
      <PricesTabs />
    </Box>
    
      
        </Container>
       
  );
};

export default Header;
