import {
  Typography,
  Box,
  Stack,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import EndIcon from "src/assets/svg-icons/end.svg";
import moment from "moment";
import { Controller, UseFormWatch } from "react-hook-form";
import { FormData } from "../../constants";
import { COGSTypes, FormModeTypes } from "src/types/generic";
import YoutubeViewerBox from "../../youtube-viewer-box";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/utils";

const Summary = ({
  journeyData,
  watch,
  COGSType,
  control,
  mode,
  journeyTaxPercentage,
}: {
  journeyData: any;
  watch: UseFormWatch<FormData>;
  COGSType: COGSTypes;
  control: any;
  mode: FormModeTypes;
  journeyTaxPercentage: number;
}) => {
  const journeyTaxDivider = 1 + journeyTaxPercentage;
  const { t, i18n } = useTranslation("addSales");
  const { t: generalT } = useTranslation("general");

  const notMatchFoodics =
    mode === "add" &&
    watch("journey_type") === "foodics" &&
    !journeyData?.sale?.match_foodics;

  const netSales =
    watch("journey_type") === "foodics"
      ? watch("has_jazr_calculations") === "no"
        ? journeyData?.sale?.foodics_net_sales
        : journeyData?.sale.gross_sales / journeyTaxDivider
      : +watch("net_sales_with_tax") / journeyTaxDivider;

  const netSalesWithTax =
    watch("journey_type") === "foodics"
      ? journeyData?.sale.gross_sales
      : +watch("net_sales_with_tax");

  const orderQuantity =
    watch("journey_type") === "foodics"
      ? journeyData?.sale?.quantity
      : watch("total_order_quantity");

  // const tax = journeyData.sale.foodics_net_sales_tax;

  const allTax =
    watch("journey_type") === "foodics"
      ? watch("has_jazr_calculations") === "no"
        ? journeyData.sale.foodics_net_sales_tax
        : (journeyData?.sale.gross_sales / journeyTaxDivider) *
          journeyTaxPercentage
      : (+watch("net_sales_with_tax") / journeyTaxDivider) *
        journeyTaxPercentage;

  const cogs =
    COGSType === "manual"
      ? (watch("COGS_type") === "amount"
          ? +watch("COGS_value")
          : (watch("COGS_value") / 100) * netSales
        ).toFixed(2) ?? "0"
      : watch("has_jazr_calculations") === "no"
      ? journeyData?.cogs?.foodics_amount
      : journeyData?.cogs?.amount;

  return (
    <div>
      <Box display={"flex"} justifyContent={"center"}>
        <Stack maxWidth={"736px"} width={"100%"} px={{ xs: 3, md: 0 }}>
          <Typography fontSize={"26px"} fontWeight={600}>
            {generalT("summary")}
          </Typography>
          <Typography color={"textSecondary"}>{t("hereWeGetEnd")}</Typography>
          {notMatchFoodics ? (
            // <YoutubeViewerBox videoId="MWy22WA1AsE" />
            <Box my={3} display={"flex"} justifyContent={"center"}>
              <img src={EndIcon} alt="" />
            </Box>
          ) : (
            <Box my={3} display={"flex"} justifyContent={"center"}>
              <img src={EndIcon} alt="" />
            </Box>
          )}
          {notMatchFoodics && (
            <Stack
              mb={2}
              width={"100%"}
              bgcolor={"#FFF3EA"}
              borderRadius={"4px"}
              paddingX={2}
              paddingY={1}
              gap={"10px"}
              border={"1px solid #FF8734"}
              direction={"row"}
            >
              <InfoOutlinedIcon color="primary" />
              <Stack>
                <Typography fontSize={"14px"}>
                  {t("taxAtFoodics")} {journeyData.sale.foodics_net_sales_tax} ({" "}
                  {journeyData.sale.foodics_tax_percentage}% ){" "}
                  {t("AndShouldBe")}
                </Typography>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={{ xs: 1, sm: 1, md: 3 }}
                  flexWrap={"wrap"}
                  width={"100%"}
                >
                  <Typography fontSize={"14px"}>
                    {t("doWantCalculation")}
                    {i18n.language === "en" ? "?" : "؟"}
                  </Typography>
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      width: "fit-content",
                      ml: { xs: "auto", sm: "unset" },
                    }}
                  >
                    <Controller
                      name="has_jazr_calculations"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          {...field}
                          key={field.value}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="yes"
                            control={
                              <Radio
                                icon={<CircleOutlinedIcon />}
                                checkedIcon={<CheckCircleIcon />}
                              />
                            }
                            label={generalT("yes")}
                            sx={{ color: "#98A2B3" }}
                          />
                          <FormControlLabel
                            value="no"
                            control={
                              <Radio
                                icon={<CircleOutlinedIcon />}
                                checkedIcon={<CheckCircleIcon />}
                              />
                            }
                            label={generalT("no")}
                            sx={{ color: "#98A2B3" }}
                          />
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Stack>
              </Stack>
            </Stack>
          )}
          <Stack gap={1.5} alignItems={"flex-start"}>
            <Box width={"100%"}>
              <Box height={"7px"} bgcolor={"#D0D5DD"} />
              <Stack p={2} gap={1.5} bgcolor={"#F9FAFB"}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={"14px"} color={"#344054"}>
                    {t("timePeriod")}
                  </Typography>
                  <Typography fontSize={"14px"} color={"#344054"}>
                    {watch("journey_type") === "foodics"
                      ? journeyData?.start_date
                      : watch("dateRange.start_date")}{" "}
                    {generalT("to")}{" "}
                    {watch("journey_type") === "foodics"
                      ? journeyData?.end_date
                      : watch("dateRange.end_date")}
                  </Typography>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={"14px"} color={"#344054"}>
                    {generalT("netSalesWithTax")}:
                  </Typography>
                  <Typography fontSize={"14px"} color={"#344054"}>
                    {formatNumber(netSalesWithTax) ?? "--"} {generalT("sar")}
                  </Typography>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={"14px"} color={"#344054"}>
                    {generalT("netSales")}:
                  </Typography>
                  <Typography fontSize={"14px"} color={"#344054"}>
                    {formatNumber(netSales.toFixed(2)) ?? "--"}{" "}
                    {generalT("sar")}
                  </Typography>
                </Stack>

                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={"14px"} color={"#344054"}>
                    {generalT("tax")}:
                  </Typography>
                  <Typography fontSize={"14px"} color={"#344054"}>
                    {formatNumber(allTax.toFixed(2)) ?? "--"} {generalT("sar")}
                  </Typography>
                </Stack>

                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={"14px"} color={"#344054"}>
                    {generalT("orderQuantity")}:
                  </Typography>
                  <Typography fontSize={"14px"} color={"#344054"}>
                    {formatNumber(orderQuantity) ?? "--"}
                  </Typography>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={"14px"} color={"#344054"}>
                    {generalT("cogs")}:
                  </Typography>
                  <Typography fontSize={"14px"} color={"#344054"}>
                    {formatNumber(cogs)} {generalT("sar")}
                  </Typography>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={"14px"} color={"#344054"}>
                    {generalT("cogs")} {t("percentage")}:
                  </Typography>
                  <Typography fontSize={"14px"} color={"#344054"}>
                    {COGSType === "manual"
                      ? (watch("COGS_type") === "percentage"
                          ? watch("COGS_value")
                          : ((watch("COGS_value") / netSales) * 100).toFixed(
                              2
                            )) ?? "--"
                      : watch("has_jazr_calculations") === "no"
                      ? journeyData?.cogs?.foodics_percentage
                      : journeyData?.cogs?.percentage}{" "}
                    %
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </div>
  );
};

export default Summary;
