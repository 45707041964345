import { Stack, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useTranslation } from "react-i18next";
interface IProps {
  type: string;
}

const AmountType = (props: IProps) => {
  const { type } = props;
  const { t } = useTranslation("purchasing");
  return (
    <Stack spacing={"6px"} direction={"row"} alignItems={"center"} justifyContent={"center"}>
      {type === "plus" ? (
        <>
          <AddCircleIcon style={{ color: "#41AA5A" }} />
          <Typography
            fontSize={"14px"}
            fontWeight={500}
            lineHeight={"20px"}
            color={"#41AA5A"}
          >
            {t("plus")}
          </Typography>
        </>
      ) : (
        <>
          <RemoveCircleIcon sx={{ color: "#FF6B71" }} />
          <Typography
            fontSize={"14px"}
            fontWeight={500}
            lineHeight={"20px"}
            color={"#FF6B71"}
          >
            {t("minus")}
          </Typography>
        </>
      )}
    </Stack>
  );
};
export default AmountType;
