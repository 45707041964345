import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import {
  useAddSupplierMutation,
  useUpdateSupplierMutation,
} from "src/api/purchasing/suppliers";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";

const schema = yup.object().shape({
  mode: yup
    .string()
    .required("Required")
    .oneOf(["add", "edit"] as const),
  name: yup.string().required("Required"),
  bank_account: yup.string(),
  phone: yup.string(),
  email: yup.string(),
  delivery_days: yup.string(),
  start_balance: yup.string(),
});

interface FormInputs extends yup.InferType<typeof schema> {}

interface IProps {
  open: boolean;
  handleClose: () => void;
  supplierToUpdate?: any;
  refetch: Function;
}

const AddEditSuppliesPopup = (props: IProps) => {
  const { open, handleClose, supplierToUpdate, refetch } = props;
  const { t } = useTranslation("purchasing");
  const { t: generalT } = useTranslation("general");
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  // APIS
  const { mutate, status, error, isPending } = useAddSupplierMutation();
  const {
    mutate: mutateUpdate,
    status: statusUpdate,
    error: errorUpdate,
    isPending: isPendingUpdate,
  } = useUpdateSupplierMutation();

  //React hook form
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: !!supplierToUpdate ? "edit" : "add",
    },
  });
  const mode = getValues("mode");

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    console.log({ data });
    const { mode, delivery_days, start_balance, ...rest } = data;
    const dataToSend = {
      ...rest,
      ...(!!delivery_days && {delivery_days: Number(delivery_days)}),
      ...(!!start_balance && {start_balance: Number(start_balance)}),
    };
    if (mode === "add") {
      mutate(dataToSend);
    } else {
      mutateUpdate({
        id: supplierToUpdate.id,
        ...dataToSend,
      });
    }
  };

  useEffect(() => {
    if (!!supplierToUpdate) {
      reset({
        mode: "edit",
        name: supplierToUpdate.name,
        bank_account: supplierToUpdate.bank_account,
        phone: supplierToUpdate.phone,
        email: supplierToUpdate.email,
        delivery_days: supplierToUpdate.delivery_days,
        start_balance: supplierToUpdate.start_balance,
      });
    }
  }, [supplierToUpdate]);

  //Add success and error handling
  useEffect(() => {
    if (status === "success") {
      refetch();
      handleClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("deletedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  //Update success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      refetch();
      handleClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { sx: "100%", lg: "537px" },
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"} lineHeight={"24px"}>
            {mode === "add" ? t("addSupplier") : t("editSupplier")}
          </Typography>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            {/* name */}
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  type="string"
                  fullWidth
                  label={t("name")}
                  size="small"
                  {...field}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
            {/* account_bank */}
            <Controller
              name="bank_account"
              control={control}
              render={({ field }) => (
                <TextField
                  type="string"
                  fullWidth
                  label={t("accountBank")}
                  size="small"
                  {...field}
                  error={!!errors.bank_account}
                  helperText={errors.bank_account?.message}
                />
              )}
            />
            {/* Contact number */}
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <TextField
                  type="number"
                  fullWidth
                  label={t("contactNumber")}
                  size="small"
                  {...field}
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                />
              )}
            />
            {/*  Email */}
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  type="string"
                  fullWidth
                  label={t("email")}
                  size="small"
                  {...field}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />
            {/* days delivery */}
            <Controller
              name="delivery_days"
              control={control}
              render={({ field }) => (
                <TextField
                  type="string"
                  fullWidth
                  label={t("expectedDaysDeliver")}
                  size="small"
                  {...field}
                  error={!!errors.delivery_days}
                  helperText={errors.delivery_days?.message}
                />
              )}
            />
            {/* startBalance */}
            <Controller
              name="start_balance"
              control={control}
              render={({ field }) => (
                <TextField
                  type="string"
                  fullWidth
                  label={t("startBalance")}
                  size="small"
                  {...field}
                  error={!!errors.start_balance}
                  helperText={errors.start_balance?.message}
                />
              )}
            />
          </Stack>
          <Stack spacing={2} direction={"row"} mt={3}>
            <Button
              variant="outlined"
              color="tertiary"
              fullWidth
              onClick={handleClose}
            >
              {t("cancel")}
            </Button>
            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              loading={isPending || isPendingUpdate}
            >
              {mode === "add" ? t("next") : t("next")}
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
};

export default AddEditSuppliesPopup;
