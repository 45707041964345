import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useGetBranches, useGetPaymentMethods } from "src/api/generic";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { LoadingButton } from "@mui/lab";
import FilePondUploader from "src/shared/components/file-pond-uploader";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useTranslation } from "react-i18next";
import { useGetSuppliersList } from "src/api/purchasing/suppliers";
import { useAddPurchaseMutation, useUpdatePurchaseMutation } from "src/api/purchasing/purchasing";

const schema = yup.object().shape({
  branch_id: yup.number().required("Required"),
  supplier_id: yup.number().required("Required"),
  amount: yup.string().required("Required"),
  include_tax: yup
    .number()
    .required("Required")
    .oneOf([1, 0] as const),
  tax: yup.string().required("Required"),
  invoice_number: yup.string(),
  payment_method_id: yup.string(),
  note: yup.string(),
  date: yup.string().required("Required"),
});

export interface FormInputs extends yup.InferType<typeof schema> {}

interface IProps {
  open: boolean;
  handleClose: () => void;
  purchaseToEdit?: any;
  refetch: Function;
}

const AddEditTransactionPopup = (props: IProps) => {
  const { open, handleClose, purchaseToEdit, refetch } = props;
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [files, setFiles] = useState<any>([]);
  const { t } = useTranslation("purchasing");
  const { t: generalT } = useTranslation("general");
  const mode = !!purchaseToEdit ? "edit" : "add";

  //APIS
  const { data: branchesData } = useGetBranches();
  const { data: suppliersData } = useGetSuppliersList({});
  const { data: paymentsData } = useGetPaymentMethods();
  const { mutate, status, isPending, error } = useAddPurchaseMutation();
  const {
    mutate: mutateUpdate,
    status: statusUpdate,
    error: errorUpdate,
    isPending: isPendingUpdate,
  } = useUpdatePurchaseMutation();

  //RHF
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    reset,
    setValue
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      tax: "0",
    },
  });

  const onClose = () => {
    handleClose();
    reset();
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    console.log({ data });
    const dataCopy = { ...data };
    if (dataCopy.note === "") {
      delete dataCopy.note;
    }
    if (dataCopy.invoice_number === "") {
      delete dataCopy.invoice_number;
    }
    if (mode === "edit") {
      delete dataCopy.payment_method_id;
    }
    const dataToSend: any = {
      ...dataCopy,
      amount: dataCopy.include_tax
        ? +(+dataCopy.amount - +dataCopy.tax).toFixed(5)
        : +dataCopy.amount,
      tax: +dataCopy.tax,
      ...(dataCopy.invoice_number && {
        invoice_number: +dataCopy.invoice_number,
      }),
      ...(dataCopy.note && { note: dataCopy.note }),
    };
    const formData = new FormData();
    // append data
    for (const key in dataToSend) {
      formData.append(key, dataToSend[key]);
    }
    // append files
    if (files.length > 0) {
      files.forEach((file: any, index: number) => {
        formData.append(`files[${index}]`, file);
      });
    }
    if (mode === "add") {
      mutate(formData);
    } else {
      mutateUpdate({ ...dataToSend, id: purchaseToEdit.id });
    }
  };

  useEffect(() => {
    if (+watch("include_tax") === 1) {
      setValue(
        "tax",
        `${(((+getValues("amount") || 0) / 1.15) * 0.15).toFixed(2)}`
      );
    } else if (+watch("include_tax") === 0) {
      setValue("tax", `${((+getValues("amount") || 0) * 0.15).toFixed(2)}`);
    }
  }, [watch("include_tax"), watch("amount")]);

  // reset form with purchase to edit
  useEffect(() => {
    if (!!purchaseToEdit) {
      console.log({ purchaseToEdit });
      reset({
        branch_id: purchaseToEdit.branch.id,
        amount: purchaseToEdit.include_tax
          ? purchaseToEdit.total
          : purchaseToEdit.amount,
        tax: purchaseToEdit.tax,
        include_tax: purchaseToEdit.include_tax ? 1 : 0,
        invoice_number: purchaseToEdit.invoice_number || "",
        payment_method_id: purchaseToEdit.payments[0]?.id,
        note: purchaseToEdit.note || "",
        date: purchaseToEdit.date,
        supplier_id: purchaseToEdit.supplier?.id,
      });
    }
  }, [purchaseToEdit, open]);

  //Add success and error handling
  useEffect(() => {
    if (status === "success") {
      refetch();
      onClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  //Update success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      refetch();
      onClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors?.[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  console.log({ errors }, getValues());

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100%", sm: 547 },
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"}>
            {mode === "add" ? t("addNewPurchasing") : t("editPurchasing")}
          </Typography>
          <IconButton onClick={onClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            {/* select Branches */}
            <FormControl fullWidth size="small" error={!!errors.branch_id}>
              <InputLabel id="demo-simple-select-label">
                {t("selectBranches")}
              </InputLabel>
              <Controller
                name="branch_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    key={field.value}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={t("selectBranches")}
                    disabled={mode === "edit"}
                  >
                    {branchesData?.data?.map(({ id, name }) => {
                      return (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
              <FormHelperText id="my-helper-text">
                {errors.branch_id?.message}
              </FormHelperText>
            </FormControl>

            {/* select Suppler */}
            <FormControl fullWidth size="small" error={!!errors.supplier_id}>
              <InputLabel id="demo-simple-select-label">
                {t("selectSuppler")}
              </InputLabel>
              <Controller
                name="supplier_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    key={field.value}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={t("selectSuppler")}
                    disabled={mode === "edit"}
                  >
                    {suppliersData?.data.map((supplier: any) => {
                      return (
                        <MenuItem key={supplier.id} value={supplier.id}>
                          {supplier.name}
                        </MenuItem>
                      );
                    })}
                    {suppliersData?.data?.length === 0 ? (
                      <Typography align="center" color={"textSecondary"}>
                        {generalT("noData")}
                      </Typography>
                    ) : null}
                  </Select>
                )}
              />
              <FormHelperText id="my-helper-text">
                {errors.supplier_id?.message}
              </FormHelperText>
            </FormControl>

            {/* Amount */}
            <Stack direction={"row"} alignItems={"flex-start"} gap={1}>
              <Box sx={{ width: 220 }}>
                <Controller
                  name="amount"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      fullWidth
                      label={t("amount")}
                      size="small"
                      {...field}
                      error={!!errors.amount}
                      helperText={errors.amount?.message}
                    />
                  )}
                />
              </Box>
              <FormControl
                fullWidth
                size="small"
                error={!!errors.include_tax}
                sx={{ width: "fit-content", ml: { xs: "auto", sm: "unset" } }}
              >
                <Controller
                  name="include_tax"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      key={field.value}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="1"
                        control={
                          <Radio
                            size="small"
                            icon={<CircleOutlinedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                          />
                        }
                        label={t("includeTax")}
                        sx={{ color: "#98A2B3" }}
                      />
                      <FormControlLabel
                        value="0"
                        control={
                          <Radio
                            size="small"
                            icon={<CircleOutlinedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                          />
                        }
                        label={t("excludeTax")}
                        sx={{ color: "#98A2B3" }}
                      />
                    </RadioGroup>
                  )}
                />
                <FormHelperText id="my-helper-text">
                  {errors.include_tax?.message}
                </FormHelperText>
              </FormControl>
            </Stack>
            {/* tax Optional */}
            <Controller
              name="tax"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t("taxOptional")}
                  fullWidth
                  size="small"
                  {...field}
                  error={!!errors.tax}
                  helperText={errors.tax?.message}
                />
              )}
            />
            {/* invoice number */}
            <Controller
              name="invoice_number"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t("invoiceNumberOptional")}
                  fullWidth
                  size="small"
                  {...field}
                  error={!!errors.invoice_number}
                  helperText={errors.invoice_number?.message}
                />
              )}
            />
            {/* payment method */}
            {mode === "add" && (
              <FormControl
                fullWidth
                size="small"
                error={!!errors.payment_method_id}
              >
                <InputLabel id="demo-simple-select-label">
                  {t("paymentMethodOptional")}
                </InputLabel>
                <Controller
                  name="payment_method_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      key={field.value}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label={t("paymentMethodOptional")}
                    >
                      {paymentsData?.data
                        ?.filter((pay) => pay.status)
                        .map(({ id, name }) => {
                          return (
                            <MenuItem key={id} value={`${id}`}>
                              {name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  )}
                />
                <FormHelperText id="my-helper-text">
                  {errors.payment_method_id?.message}
                </FormHelperText>
              </FormControl>
            )}

            {/* note */}
            <Controller
              name="note"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t("notesOptional")}
                  fullWidth
                  size="small"
                  multiline
                  rows={2}
                  maxRows={3}
                  {...field}
                  error={!!errors.note}
                  helperText={errors.note?.message}
                />
              )}
            />

            {/* date */}
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <TextField
                  type="date"
                  label={t("transactionDateRepeat")}
                  fullWidth
                  size="small"
                  {...field}
                  error={!!errors.date}
                  helperText={errors.date?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Stack>
          {/* files */}
          {true && (
            <Box width={"100%"} mt={2}>
              <FilePondUploader
                onUpload={(e) => setFiles(e)}
                maxFiles={3}
                maxFileSize={3}
                acceptedFileTypes={["image/*", "application/pdf"]}
              />
            </Box>
          )}
          <Stack spacing={2} direction={"row"} mt={3}>
            <Button
              variant="outlined"
              color="tertiary"
              fullWidth
              onClick={onClose}
            >
              {generalT("cancel")}
            </Button>
            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              loading={isPending || isPendingUpdate}
            >
              {mode === "add" ? t("addPurchasing") : generalT("save")}
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
};

export default AddEditTransactionPopup;
