import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Chip, TablePagination, Typography, Stack } from "@mui/material";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import TableActions from "./table-actions";
import TableHeadText from "./table-head-text";
import { useGetOCTransactionsList } from "src/api/operation-cost/transactions";
import Header from "../header";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/utils";

const TransactionListTable = () => {
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const { t } = useTranslation("operationCost");
  const { t: generalT } = useTranslation("general");

  const HeadText = [
    generalT("reference"),
    generalT("branch"),
    generalT("accountName"),
    t("type"),
    t("subTotal"),
    generalT("tax"),
    t("paymentMethod"),
    t("paymentDate"),
    t("dueDate"),
    t("repeat"),
    generalT("createdBy"),
    generalT("createdAt"),
    t("status"),
    generalT("actions"),
  ];
  // APIS
  const { data, isLoading, refetch } = useGetOCTransactionsList({
    page: pageNumber + 1,
  });

  const emptyDataArr = data?.data.length === 0;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta?.total as number);
    }
  }, [data]);

  return (
    <Box overflow={"auto"} maxWidth={{ xs: "100%", md: `calc(100vw - 316px)` }}>
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={2}
      >
        <Typography
          fontWeight={600}
          lineHeight={"19.2px"}
          color={"#475467"}
          textTransform={"uppercase"}
        >
          {t("costList")}
        </Typography>
        <Header refetch={refetch} />
      </Stack>
      <TableContainer component={Paper} variant="outlined">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {HeadText.map((item) => {
                return (
                  <TableCell sx={{ bgcolor: "#F9FAFB" }}>
                    <TableHeadText text={item} />
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoadingSkeleton rowsLength={8} cellsLength={15} />
          ) : (
            <TableBody>
              {data?.data.map((row: any) => (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#F9FAFB",
                    },
                  }}
                  // onClick={navigateToOperationCost}
                >
                  <TableCell>{row.reference}</TableCell>
                  <TableCell>{row.branch.name}</TableCell>
                  <TableCell>{row.account?.name}</TableCell>
                  <TableCell>
                    {row.account?.type === "variable"
                      ? t("variable")
                      : t("fixedCost")}
                  </TableCell>
                  <TableCell>{formatNumber(row.amount)}</TableCell>
                  <TableCell>{formatNumber(row.tax)}</TableCell>
                  <TableCell>
                    {row.payments[0]?.payment_method.name || "--"}
                  </TableCell>
                  <TableCell>{row.payment_date || "--"}</TableCell>
                  <TableCell>{row.due_date}</TableCell>
                  <TableCell>{row.repeat_name}</TableCell>
                  <TableCell>
                    {row.created_by.name}{" "}
                    {row.added_by === "SYS" ? "(SYS)" : ""}
                  </TableCell>
                  <TableCell dir="ltr">{row.created_at}</TableCell>
                  <TableCell align="center">
                    <Chip
                      label={
                        row.status === "Paid"
                          ? t("paid")
                          : row.status === "Partly Paid"
                          ? t("partiallyPaid")
                          : row.status === "UnPaid"
                          ? t("unpaid")
                          : t("overpaid")
                      }
                      color={
                        row.status === "Paid"
                          ? "success"
                          : row.status === "Partly Paid"
                          ? "warning"
                          : "error"
                      }
                      variant="outlined"
                      sx={{ height: "22px" }}
                    />
                  </TableCell>

                  <TableCell>
                    <TableActions row={row} refetch={refetch} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
      <TablePagination
        sx={{ mt: 2 }}
        rowsPerPageOptions={[15]}
        component="div"
        count={total}
        rowsPerPage={15}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
    </Box>
  );
};
export default TransactionListTable;
