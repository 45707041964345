import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import followerImg from "src/assets/svg-icons/home/follower.svg";
import ReportsEn from "src/assets/img/home/reports.png";
import ReportsAr from "src/assets/img/home/reports-ar.png";

const WhatUserNeed = () => {
  const { t, i18n } = useTranslation("home");

  return (
    <Grid
      container
      // bgcolor={"#FFF7F7"}
      p={{ xs: "140px 16px 140px 16px", lg: "140px 16px 80px 16px" }}
      spacing={{ xs: 3, lg: 2 }}
    >
      <Grid
        item
        xs={12}
        lg={4}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        order={{ xs: 2, lg: 1 }}
        data-aos="fade-left"
      >
        <Typography
          color={"#000"}
          fontSize={{ xs: "24px", md: "32px" }}
          fontWeight={{ xs: 800, md: 800 }}
          lineHeight={{ xs: "24px", md: "36px" }}
          mb={"15px"}
          sx={{ textAlign: { xs: "start", lg: "start" } }}
        >
          {t("noNeedAccountant")}
        </Typography>
        <Typography
          color={"#333"}
          fontSize={{ xs: "16px", md: "20px" }}
          fontWeight={500}
          lineHeight={{ xs: "20px", md: "24px" }}
          sx={{ textAlign: { xs: "start", lg: "start" } }}
        >
          {t("noNeedAccountantNeedAnyone")}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={8} order={{ xs: 1, lg: 2 }} data-aos="fade-right">
        <Box position={"relative"} width={{ xs: "100%", lg: "fit-content" }}>
          <Box
            width={{ xs: "100%", lg: "100%" }}
            height={{ xs: "100%", lg: "100%" }}
            position={"relative"}
            zIndex={2}
          >
            <img
              src={i18n.language === "en" ? ReportsEn : ReportsAr}
              alt=""
              width={"100%"}
              height={"100%"}
            />
          </Box>
          <Box
            position={"absolute"}
            bottom={-15}
            right={-52}
            display={{ xs: "none", md: "block" }}
            zIndex={1}
          >
            <img
              src={followerImg}
              alt=""
              style={{
                transform: i18n.language === "en" ? "scaleX(-1)" : "none",
              }}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default WhatUserNeed;
