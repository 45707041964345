import { Box, IconButton, Stack, Typography } from "@mui/material";
import FileIcon from "src/assets/svg-icons/file.svg";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

interface IFileItem {
  name: string;
  info: string;
  onDelete?: () => void;
  isDeletable?: boolean;
  onDownload?: () => void;
  isDownloadable?: boolean;
}
const FileItem = (props: IFileItem) => {
  const {
    name,
    info,
    onDelete = () => {},
    isDeletable = true,
    onDownload = () => {},
    isDownloadable = false
  } = props;

  return (
    <div>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Stack spacing={1.5} direction={"row"}>
          <img src={FileIcon} alt="" width={"30px"} />
          <Box>
            <Typography
              color={"#101828"}
              fontSize={"14px"}
              fontWeight={600}
              lineHeight={"20px"}
            >
              {name}
            </Typography>
            <Typography color={"#667085"} fontSize={"10px"} lineHeight={"20px"}>
              {info}
            </Typography>
          </Box>
        </Stack>
        <Stack direction={"row"} alignItems={"center"}>
          {isDownloadable && (
            <IconButton onClick={onDownload}>
              <FileDownloadOutlinedIcon
                sx={{ color: "#344054", fontSize: "18px" }}
              />
            </IconButton>
          )}

          {isDeletable && (
            <IconButton onClick={onDelete}>
              <img src={DeleteIcon} alt="" />
            </IconButton>
          )}
        </Stack>
      </Box>
      <Box width="100%" bgcolor="#EAECF0" height="1px" />
    </div>
  );
};

export default FileItem;
