import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "./apiClient";
import {
  IAggAPIBody,
  IAggCostAndFeeAPIBody,
  IAggCostChartDataResponse,
  IAvgOrderDataResponse,
  IProductStatisticsResponse,
  ITotalSalesDataResponse,
} from "src/types/aggregators";

interface IParams {
  start_date: string;
  end_date: string;
  payment_methods?: number[];
}
// Summary tab
export const useGetAggregatorsTotalSales = (params: IParams) =>
  useQuery<any, APIErrorResponse, ITotalSalesDataResponse>({
    queryKey: ["get-aggregators-total-sales"],
    queryFn: async () => {
      return apiClient.get(`/api/aggregators/sales-report`, { params: params });
    },
  });

export const useGetAverageOrderData = (params: IParams) =>
  useQuery<any, APIErrorResponse, IAvgOrderDataResponse>({
    queryKey: ["get-agg-avg-order-data"],
    queryFn: async () => {
      return apiClient.get(`/api/aggregators/avg-sales-report`, {
        params: params,
      });
    },
  });

export const useGetHighProfitProducts = (params: IParams) =>
  useQuery<any, APIErrorResponse, IProductStatisticsResponse>({
    queryKey: ["get-high-profit-products"],
    queryFn: async () => {
      return apiClient.get(`/api/aggregators/high-profit-report`, {
        params: params,
      });
    },
  });

export const useGetLowProfitProducts = (params: IParams) =>
  useQuery<any, APIErrorResponse, IProductStatisticsResponse>({
    queryKey: ["get-low-profit-products"],
    queryFn: async () => {
      return apiClient.get(`/api/aggregators/low-profit-report`, {
        params: params,
      });
    },
  });

export const useGetPopularProfitProducts = (params: IParams) =>
  useQuery<any, APIErrorResponse, IProductStatisticsResponse>({
    queryKey: ["get-most-popular-products"],
    queryFn: async () => {
      return apiClient.get(`/api/aggregators/popular-products-report`, {
        params: params,
      });
    },
  });

export const useGetAggCostChartData = (params: IParams) =>
  useQuery<any, APIErrorResponse, IAggCostChartDataResponse>({
    queryKey: ["get-agg-cost-chart-data"],
    queryFn: async () => {
      return apiClient.get(`/api/aggregators/aggregators-summary-report`, {
        params: params,
      });
    },
  });

interface IAggsParams {
  enabled?: boolean;
}

// Aggregators List tab
export const useGetAggregatorsList = (params?: IAggsParams) => {
  const { enabled = true } = params || {};
  return useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-aggregators-list"],
    queryFn: async () => {
      return apiClient.get(`/api/aggregators`);
    },
    enabled: enabled,
  });
};

export const useGetAggregatorById = ({
  id,
  enabled,
}: {
  id: string;
  enabled: boolean;
}) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-agg-by-id"],
    queryFn: async () => {
      return apiClient.get(`/api/aggregators/${id}`);
    },
    // staleTime: 0,
    gcTime: 0,
    enabled,
  });

export const useAddAggMutation = () =>
  useMutation<any, APIErrorResponse, IAggAPIBody>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/aggregators`, data);
    },
  });

export const useUpdateAggMutation = () =>
  useMutation<any, APIErrorResponse, IAggAPIBody>({
    mutationFn: async (data) => {
      const { id, ...restData } = data;
      return apiClient.put(`/api/aggregators/${id}`, restData);
    },
  });

export const useDeleteAggMutation = () =>
  useMutation<any, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/aggregators/${id}`);
    },
  });

// Transactions tab
interface ITransParams {
  start_date?: string;
  end_date?: string;
  page?: number;
}

export const useGetAggTransactionsList = (params: ITransParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-agg-transactions-list", params.page],
    queryFn: async () => {
      return apiClient.get(`/api/aggregatorSales`, { params });
    },
  });

// Aggregators cost / fees tab
export const useGetAggregatorsCostAndFeesList = (params: {
  start_date?: string;
  end_date?: string;
  branches?: number[];
  aggregators?: number[];
  page?: number;
  reference_number?: string;
  created_at?: string;
  created_by?: string;
  has_bank_fee?: number;
  has_compensation?: number;
  has_other_fee?: number;
}) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-aggregators-cost-and-fees-list"],
    queryFn: async () => {
      return apiClient.get(`/api/costAndFees`, { params });
    },
  });

export const useGetAggregatorCostAndFeeById = ({
  id,
  enabled,
}: {
  id: string;
  enabled: boolean;
}) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-agg-by-id"],
    queryFn: async () => {
      return apiClient.get(`/api/costAndFees/${id}`);
    },
    gcTime: 0,
    enabled,
  });

export const useAddAggCostAndFeesMutation = () =>
  useMutation<any, APIErrorResponse, IAggCostAndFeeAPIBody>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/costAndFees`, data);
    },
  });

export const useUpdateAggCostAndFeesMutation = () =>
  useMutation<any, APIErrorResponse, IAggCostAndFeeAPIBody>({
    mutationFn: async (data) => {
      const { id, ...restData } = data;
      return apiClient.put(`/api/costAndFees/${id}`, restData);
    },
  });

export const useDeleteAggCostAndFeesMutation = () =>
  useMutation<any, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/costAndFees/${id}`);
    },
  });
