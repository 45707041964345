import { Stack, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface InfoProps {
  title: string;
}
const ItemInfo = (props: InfoProps) => {
  const { title } = props;
  return (
    <Stack direction={"row"} spacing={1.5}>
      <CheckCircleIcon color="primary" sx={{fontSize: "22px"}} />
      <Typography fontSize={"14px"} color={"#2B3674"} lineHeight={"18px"}>
        {title}
      </Typography>
    </Stack>
  );
};

export default ItemInfo;
